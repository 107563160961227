import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Skeleton } from "@chakra-ui/react";
import { getAllAnimals, getRecordByCode } from "../../api";
import PatientInfoComponent from "./PatientInfo.component";

function PatientInfoContainer() {
  /* initial state */
  const [record, setRecord] = useState({});
  const [animal, setAnimal] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation().pathname;

  const code = location.split("/")[2];
  const version = location.split("/")[3];


  useEffect(() => {
    const fetchData = async () => {
      const [respRecord] = await Promise.all([getRecordByCode(code)]);
      setRecord(...respRecord.data.versions.filter(value => {
        return value.id === version;
      }) || {});
      const [resp] = await Promise.all([getAllAnimals()]);

      setAnimal(resp.data);

      setIsLoaded(true);
    };
    fetchData();
  }, []);

  return (

    <Skeleton isLoaded={isLoaded}>
      {(record && record.patientData !== undefined && isLoaded) && (
        <PatientInfoComponent animal={animal} record={record}
                              code={code} version={version} />
      )}
    </Skeleton>

  );
}

PatientInfoContainer.propTypes = {};

PatientInfoContainer.defaultProps = {};

export default PatientInfoContainer;
