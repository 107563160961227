import React from "react";
import { Box, Flex, Text, VStack, useColorMode } from "@chakra-ui/react";
import FilterForm from "./FilterForm/FilterForm";
import FilterTable from "./FilterTable";
import { prepareOptions } from "../../helpers";

const FiltersPresetsComponent = ({
  data,
  addFilter,
  deleteFilterPreset,
  selectedItem,
  setSelectedItem,
  agentCategories,
  tagsArray,
}) => {
  const isLight = useColorMode().colorMode === "light";

  return (
    <Flex w="100%" boxShadow={'base'} mt={8} px={4} pb={16} bg={isLight ? "white" : "gray.900"}>
      <VStack spacing={6}>
        <Box w="full" align="left" mt={4}>
          <Text fontWeight="bold" fontSize="2xl">
            Pannello gestione filtri
          </Text>
        </Box>
        <Box w="full" align="left">
          <Text fontWeight="bold" fontSize="xl">
            Crea Filtro
          </Text>
          <Text>
            Aggiungi un filtro che tutti gli utenti possono utilizzare.
          </Text>
          <FilterForm
            addFilter={addFilter}
            selectedItem={selectedItem}
            agentCategories={prepareOptions(agentCategories)}
            tagsArray={prepareOptions(tagsArray)}
          />
        </Box>
        <Box w="full" align="left" mt={8}>
          <Text fontWeight="bold" fontSize="xl">
            Filtri Creati
          </Text>
          {data && (
            <FilterTable
              data={data.data}
              deleteFilterPreset={deleteFilterPreset}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          )}
        </Box>
      </VStack>
    </Flex>
  );
};

FiltersPresetsComponent.propTypes = {};

FiltersPresetsComponent.defaultProps = {};

export default FiltersPresetsComponent;
