import { Formik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Box, Text, useTheme, useColorMode } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ApplicantDataComponent from "./ApplicantData.component";
import {
  addDefaultOption,
  changeObject,
  getDataFromRecord,
  hospitalFilter,
  newHospital,
  prepareOptions,
  prepareOptionsWithoutId,
} from "../../helpers";
import {
  createNewHospital,
  createNewVersion,
  createRecord,
  getAllApplicants,
  getAllHospitals,
  getAllHospitalUnits,
  getAllRegions,
  getAllReportingMethods,
  getAllSchools,
  getCitiesByProvinces,
  getProvincesByRegion,
  getRecordByCode,
  updateRecord,
} from "../../api";
import * as constants from "../../constants";
import { HOSPITAL_ID, SCHOOL_ID } from "../../constants";
import { errors } from "../Form";
import { AuthContext } from "../../context/AuthContextProvider";
import Loader from "../Loader";

const { EMAIL } = errors;

function ApplicantDataContainer() {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isLight = useColorMode().colorMode === "light";

  /* initial state */
  const [record, setRecord] = useState({});
  const [applicantData, setApplicantData] = useState({});

  /* form statuses */
  const [reportingMethods, setReportingMethods] = useState([]);
  const [applicantTypes, setApplicantTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [hospitalUnits, setHospitalUnits] = useState([]);
  const [schools, setSchools] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [newHospitalFlag, setNewHospitalFlag] = useState(false);
  const [error, setError] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isCreation, setIsCreation] = useState(true);

  /* button statuses */
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isNewVersion, setIsNewVersion] = useState(false);

  const authContext = useContext(AuthContext);
  const { code, version } = useParams();

  const initialValues = {
    idReportingMethods:
      applicantData.idReportingMethods === undefined
        ? "0"
        : applicantData.idReportingMethods,
    idApplicantType:
      applicantData.idApplicantType === undefined
        ? "0"
        : applicantData.idApplicantType,
    city: applicantData.city === undefined ? "" : applicantData.city,
    province:
      applicantData.province === undefined ? "" : applicantData.province,
    region: applicantData.region === undefined ? "" : applicantData.region,
    email: applicantData.email === undefined ? "" : applicantData.email,
    fax: applicantData.fax === undefined ? "" : applicantData.fax,
    phone: applicantData.phone === undefined ? "" : applicantData.phone,
    hospital:
      applicantData.hospital === undefined ? "" : applicantData.hospital,
    hospitalUnit:
      applicantData.hospitalUnit === undefined
        ? ""
        : applicantData.hospitalUnit,
    doctor: applicantData.doctor === undefined ? "" : applicantData.doctor,
    idSchoolType:
      applicantData.idSchoolType === undefined
        ? "0"
        : applicantData.idSchoolType,
    schoolName:
      applicantData.schoolName === undefined ? "" : applicantData.schoolName,
    rating: applicantData.rating === undefined ? "" : applicantData.rating,
    intossicazioneIR:
      applicantData.intossicazioneIR === undefined
        ? false
        : applicantData.intossicazioneIR,
    effettiAvversiIR:
      applicantData.effettiAvversiIR === undefined
        ? false
        : applicantData.effettiAvversiIR,
    effettiGravidanzaIR:
      applicantData.effettiGravidanzaIR === undefined
        ? false
        : applicantData.effettiGravidanzaIR,
    infoPazienteIR:
      applicantData.infoPazienteIR === undefined
        ? false
        : applicantData.infoPazienteIR,
    richiestaInvioAntidotiIR:
      applicantData.richiestaInvioAntidotiIR === undefined
        ? false
        : applicantData.richiestaInvioAntidotiIR,
    richiestaEsamiIR:
      applicantData.richiestaEsamiIR === undefined
        ? false
        : applicantData.richiestaEsamiIR,
    richiestaPreventivaIR:
      applicantData.richiestaPreventivaIR === undefined
        ? false
        : applicantData.richiestaPreventivaIR,
    altroIR:
      applicantData.altroIR === undefined ? false : applicantData.altroIR,
  };

  // Schema Validazione
  const validationSchema = Yup.object().shape({
    idReportingMethods: Yup.string().test(
      "section",
      "Seleziona un tipo",
      (val) => {
        return val !== "0";
      }
    ),
    idApplicantType: Yup.string().test(
      "section",
      "Seleziona un tipo",
      (val) => {
        return val !== "0";
      }
    ),
    city: Yup.string(),
    province: Yup.string(),
    region: Yup.string(),
    email: Yup.string().email(EMAIL),
    fax: Yup.string(),
    phone: Yup.string(),
    hospital: Yup.string().when("idApplicantType", {
      is: HOSPITAL_ID,
      then: Yup.string(),
      otherwise: Yup.string(),
    }),
    hospitalUnit: Yup.string().when("idApplicantType", {
      is: HOSPITAL_ID,
      then: Yup.string(),
      otherwise: Yup.string(),
    }),
    doctor: Yup.string().when("idApplicantType", {
      is: HOSPITAL_ID,
      then: Yup.string(),
      otherwise: Yup.string(),
    }),
    idSchoolType: Yup.string().when("idApplicantType", {
      is: SCHOOL_ID,
      then: Yup.string(),
      otherwise: Yup.string(),
    }),
    schoolName: Yup.string().when("idApplicantType", {
      is: SCHOOL_ID,
      then: Yup.string(),
      otherwise: Yup.string(),
    }),
    rating: Yup.string().when("idApplicantType", {
      is: SCHOOL_ID,
      then: Yup.string(),
      otherwise: Yup.string(),
    }),
  });

  // trovo le province filtrando per regione
  const findProvinces = async (region) => {
    if (region) {
      const [respProvinces] = await Promise.all([getProvincesByRegion(region)]);
      setProvinces(respProvinces.data);
    }
  };
  const findCities = async (province) => {
    if (province) {
      const [respCities] = await Promise.all([getCitiesByProvinces(province)]);
      setCities(respCities.data);
    }
  };

  // Carico tutto quello che serve nella pagina
  useEffect(() => {
    const fetchData = async () => {
      const [
        respRecord,
        respReportingMethods,
        respApplicant,
        respRegions,
        respHospitalUnits,
        respSchools,
        respHospitals,
      ] = await Promise.all([
        getRecordByCode(code),
        getAllReportingMethods(),
        getAllApplicants(),
        getAllRegions(),
        getAllHospitalUnits(),
        getAllSchools(),
        getAllHospitals(),
      ]);
      const data = getDataFromRecord(respRecord.data, version, "applicantData");
      setRecord(respRecord.data || {});
      setReportingMethods(respReportingMethods.data);
      setApplicantTypes(respApplicant.data);
      setRegions(respRegions.data);
      setHospitalUnits(respHospitalUnits.data);
      setSchools(respSchools.data);
      setHospitals(respHospitals.data);
      setApplicantData(data);
      setIsCreation(respRecord.data.code === undefined);
      if (!(respRecord.data.code === undefined)) {
        findProvinces(data.region);
        findCities(data.province);
      }
      setIsLoaded(true);
    };
    fetchData();
  }, []);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Box boxShadow="base" borderRadius="8px" pt="8" pb="8" px="8"
      w="full" bg={isLight ? "white" : "gray.900"}>
      <Box>
        <Text fontSize='2xl' as="b" >Dati Richiedente</Text>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          try {
            const formValues = { applicantData: { ...changeObject(values) } };
            if (newHospitalFlag) {
              const hospitalData = newHospital(formValues);
              await createNewHospital(hospitalData);
            }
            const author = `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`;
            if (isCreating) {
              const resp = await createRecord(record, code, version, author, {
                applicantData: { ...changeObject(values) },
              });
              setError(resp.data.error);
            } else if (isUpdating) {
              const resp = await updateRecord(
                record,
                code,
                version,
                author,
                { ...changeObject(values) },
                constants.APPLICANT_DATA
              );
              setError(resp.data.error);
            } else if (isNewVersion) {
              const { newVersionId } = await createNewVersion(
                record,
                code,
                version,
                author
              );
              const resp = await updateRecord(
                record,
                code,
                newVersionId,
                author,
                { ...changeObject(values) },
                constants.APPLICANT_DATA
              );
              setError(resp.data.error);
              navigate(
                `/${location.pathname.split("/")[1]}/${code}/${newVersionId}`
              );
            }

            toast({
              title: "Record Salvato",
              description: "L'operazione è andata a buon fine",
              status: "success",
              duration: 9000,
              isClosable: true,
            });

            actions.setSubmitting(false);
            actions.setStatus({
              created: true,
            });

            setIsNewVersion(false);
            setIsCreating(false);
            setIsUpdating(false);
          } catch (er) {
            setError({
              ...er,
            });
            toast({
              title: "Errore",
              description: er,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            actions.setSubmitting(false);
          }
          setIsCreation(false);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          isSubmitting,
          isValid,
        }) => {
          return (
            <ApplicantDataComponent
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              values={values}
              isSubmitting={isSubmitting}
              isValid={isValid}
              error={error}
              theme={theme}
              reportingMethods={prepareOptions(
                addDefaultOption(reportingMethods)
              )}
              applicantTypes={prepareOptions(addDefaultOption(applicantTypes))}
              hospitalUnits={prepareOptions(addDefaultOption(hospitalUnits))}
              hospitalsOptions={hospitalFilter(
                hospitals,
                values,
                setNewHospitalFlag
              )}
              schools={prepareOptions(addDefaultOption(schools))}
              regions={prepareOptionsWithoutId(regions)}
              provinces={prepareOptionsWithoutId(provinces)}
              cities={prepareOptionsWithoutId(cities)}
              findProvinces={findProvinces}
              findCities={findCities}
              newHospitalFlag={newHospitalFlag}
              setNewHospitalFlag={setNewHospitalFlag}
              isCreation={isCreation}
              setIsNewVersion={setIsNewVersion}
              setIsCreating={setIsCreating}
              setIsUpdating={setIsUpdating}
              userProfile={authContext.userProfile}
            />
          );
        }}
      </Formik>
    </Box>
  );
}

export default ApplicantDataContainer;
