import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  FormLabel,
  GridItem,
  Radio,
  Center,
  Box,
  Text,
  Button,
  Heading,
} from "@chakra-ui/react";
import {
  CheckboxControl,
  InputControl,
  SelectControl,
  RadioGroupControl,
  CheckboxContainer,
  TextareaControl,
  CheckboxSingleControl,
} from "formik-chakra-ui";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { DEFAULT_ID, HOSPITAL_ID, SCHOOL_ID } from "../constants";
import { Select } from "chakra-react-select";

// Modifica Array di oggetti per le selezioni
export const prepareOptions = (objArr) => {
  return objArr.flatMap((obj) => {
    return obj.isPublished ? [{ id: obj.id, desc: obj.description }] : [];
  });
};

export const prepareOptionsWithoutId = (arr) => {
  return ["Seleziona", ...arr];
};

// Aggiungi un tipo vuoto alla lista
export const addDefaultOption = (arrOptions) => {
  return [
    { id: 0, description: "Seleziona", isPublished: true },
    ...arrOptions,
  ];
};

// crea lista di opzioni per la select
export const showList = (el) => {
  if (el.desc)
    return (
      <option value={el.id} key={el.id}>
        {el.desc}
      </option>
    );
  return (
    <option value={el} key={el}>
      {el}
    </option>
  );
};

// crea lista di opzioni per la checkbox
export const showCheckbox = (el, idx) => {
  if (el.desc)
    return (
      <CheckboxControl name={el.formFieldTag} value={el.value} key={idx}>
        {el.desc}
      </CheckboxControl>
    );
  return (
    <CheckboxControl name={el.formFieldTag} value={el.value} key={idx}>
      {el.value}
    </CheckboxControl>
  );
};

// Filtra la lista delle modalità di assunzioni in base all'agente
export const filterAssumingModeList = (assumingModeList, agentCategory) => {
  return assumingModeList.flatMap((obj) => {
    return obj.filter.includes(agentCategory) && obj.isPublished
      ? [obj.description]
      : [];
  });
};

// crea lista di opzioni per la select e salva la descrizione testuale come valore (non l'id)
export const showListSaveDesc = (el, idx) => {
  if (el.desc)
    return (
      <option value={el.desc} key={el.id}>
        {el.desc}
      </option>
    );
  if (el.label)
    return (
      <option value={el.label} key={idx}>
        {el.label}
      </option>
    );
  return (
    <option value={el} key={idx}>
      {el}
    </option>
  );
};

// crea lista di opzioni per la select e with subgroup
export const showListSaveDescSubgroups = (el, agents) => {
  if (el) {
    const renderables = agents.filter(x => { return x.menuName == el }).map(showListSaveDesc)
    if (renderables && renderables.length > 0) {
      return (
        <optgroup label={el}>
          {renderables}
        </optgroup>);
    }
  }
};


// Preparazione dell'array per la select contenente gli ospedali
export const hospitalFilter = (
  hospitalList,
  formValues,
  setNewHospitalFlag
) => {
  if (formValues.idApplicantType == "1") {
    if (formValues.city === "") {
      const list = hospitalList.map((obj) => {
        return { id: obj.id, desc: obj.name };
      });
      return [{ id: 0, desc: "Seleziona" }, ...list];
    }

    const filteredList = hospitalList.flatMap((obj) => {
      return obj.city === formValues.city ? [{ id: obj.id, desc: obj.name }] : [];
    });
    if (filteredList.length === 0) {
      setNewHospitalFlag(true);
    } else {
      setNewHospitalFlag(false);
    }
    return [{ id: 0, desc: "Seleziona" }, ...filteredList];
  }

};

// Modifica l'oggetto per il form richiedente
export const changeObject = (formValues) => {
  if (
    formValues.idApplicantType !== HOSPITAL_ID &&
    formValues.idApplicantType !== SCHOOL_ID
  ) {
    [
      "hospital",
      "hospitalUnit",
      "doctor",
      "idSchoolType",
      "schoolName",
      "rating",
    ].forEach((e) => {
      return delete formValues[e];
    });
    return formValues;
  }

  if (formValues.idApplicantType === HOSPITAL_ID) {
    ["idSchoolType", "schoolName", "rating"].forEach((e) => {
      return delete formValues[e];
    });

    return formValues;
  }

  if (formValues.idApplicantType === SCHOOL_ID) {
    ["hospital", "hospitalUnit", "doctor"].forEach((e) => {
      return delete formValues[e];
    });

    return formValues;
  }

  return formValues;
};

// Si prepara l'oggetto per salvare un nuovo ospedale
export const newHospital = (values) => {
  return {
    name: values.applicantData.hospital,
    city: values.applicantData.city,
    province: values.applicantData.province,
    region: values.applicantData.region,
    phone: values.applicantData.phone,
    fax: values.applicantData.fax,
    mail: values.applicantData.email,
  };
};

// Controllo Errore
export const showErrorMsg = (answer) => {
  if (answer.response.status) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle mr={2}>Error!</AlertTitle>
        <AlertDescription>
          {answer.message + answer.response.data.error}
        </AlertDescription>
        <CloseButton position="absolute" right="8px" top="8px" />
      </Alert>
    );
  }
  return null;
};

// Calcolo dell'età
export const calculateAge = (values, setFieldValue) => {
  // milliseconds in a year 1000*24*60*60*365.24 = 31556736000;

  if (values.birthDateDesc === "Completa") {
    const today = new Date();
    const dob = new Date(values.birthDate);
    const diff = today.getTime() - dob.getTime();
    const years = Math.floor(diff / 31556736000);
    const daysDiff = Math.floor((diff % 31556736000) / 86400000);
    const months = Math.floor(daysDiff / 30.4167);
    const days = Math.floor(daysDiff % 30.4167);

    const age = { years, months, days };

    setFieldValue("age", age);
    setFieldValue("ageDesc", "Nota");
  }

  if (values.birthDateDesc === "Solo anno") {
    const today = new Date();
    const years = today.getFullYear() - values.yearOfBirth;

    const age = { years, months: "", days: "" };
    setFieldValue("age", age);
  }
};

// Calcolo ID
export const getCode = () => {
  return `CAV-${dayjs().year()}-${uuidv4().split("-")[4]}`.toUpperCase();
};

export const getArrayElementID = () => {
  return `ELEMENT-${Date.now()}-${uuidv4().split("-")[4]}`.toUpperCase();
};

// Estrai key indicata dal record selezionando la versione corretta
export const getDataFromRecord = (record, version, key) => {
  if (record.versions === undefined) {
    return {};
  }

  const obj = record.versions.find((ver) => {
    return ver.id === version;
  });
  return obj[key] === undefined ? {} : obj[key];
};

// Estrai dal record un array indicato dalla key inserita, selezionando la versione corretta
export const getArrayFromRecord = (record, version, key) => {
  if (record.versions === undefined) {
    return [];
  }

  const obj = record.versions.find((ver) => {
    return ver.id === version;
  });
  return obj[key] === undefined ? [] : obj[key];
};

export function enrichDataset(r, latestVersion) {
  if (!latestVersion) {
    console.log("No data found");
  }

  if (latestVersion.patientData) {
    r.patient =
      latestVersion.patientData.idType === "1"
        ? `${latestVersion.patientData.name} ${latestVersion.patientData.surname}`
        : (r.patient = "Animale");

    r.birthDate =
      latestVersion.patientData.birthDate !== ""
        ? dayjs(latestVersion.patientData.birthDate).format("DD/MM/YYYY")
        : latestVersion.patientData.yearOfBirth;
  }
  if (latestVersion.applicantData) {
    r.applicant =
      latestVersion.applicantData.email || latestVersion.applicantData.phone;
    r.city = latestVersion.applicantData.city;
  }
  r.version = latestVersion.id;
  r.status =
    latestVersion.recordDetails !== undefined && latestVersion.recordDetails
      ? latestVersion.recordDetails.status
      : "Aperta";
}

// Check if record last version has errands
export function hasErrands(record) {
  const recordLastVersion = record.versions.at(-1);
  if (recordLastVersion.hasOwnProperty("errandsData")) {
    if (recordLastVersion.errandsData.errands !== undefined) {
      return true;
    }
  }
  return false;
}

// Check if record last version has attachments
export function hasAttachments(record) {
  const recordLastVersion = record.versions.at(-1);
  if (recordLastVersion.hasOwnProperty("attachmentsList")) {
    if (recordLastVersion.attachmentsList.length) {
      return true;
    }
  }
  return false;
}

/* ripristino select */
/*export function resetSelect() {
  const dropDown = document.getElementById("selectedPreset");
  dropDown.selectedIndex = 0;
}*/

/* Gestione delle date per poterle visualizzare nel datapicker quando carico un preset dei filtri */
export function manageDate(filter) {
  if (filter.startCreationDate !== "") {
    filter.startCreationDate = new Date(filter.startCreationDate);
  }

  if (filter.endCreationDate !== "") {
    filter.endCreationDate = new Date(filter.endCreationDate);
  }

  if (filter.startLastUpdateDate !== "") {
    filter.startLastUpdateDate = new Date(filter.startLastUpdateDate);
  }

  if (filter.endLastUpdateDate !== "") {
    filter.endLastUpdateDate = new Date(filter.endLastUpdateDate);
  }

  return filter;
}

export function isAdmin(userProfile) {
  return (
    userProfile.attributes.role !== undefined &&
    userProfile.attributes.role[0] === "admin"
  );
}

export function isBase(userProfile) {
  return (
    userProfile.attributes.role !== undefined &&
    userProfile.attributes.role[0] === "base"
  );
}

// crea lista di opzioni per la select
export const changeArrayForMultiselect = (array, valueName, labelName) => {
  const options = [];

  array.forEach((el) => {
    options.push({
      value: el[valueName],
      label: el[labelName],
      originalElement: el,
    });
  });

  return options;
};

// crea lista di opzioni per il componente select-multiplo partendo da un array di stringhe con le opzioni
export const changeStringArrayForMultiselect = (array) => {
  const options = [];

  array.forEach((el) => {
    options.push({ value: el, label: el });
  });

  return options;
};

// crea l'array di stringhe che viene salvato e passato nella get
export const setMultiOptions = (options) => {
  const selectedString = [];

  options.forEach((el) => {
    selectedString.push(el.value);
  });

  return selectedString;
};

/* GESTIONE FORM DINAMICO */
export function switchInput(props, formValues, setFieldValue) {
  if (props.length > 0) {
    return props.map((input) => {
      switch (input.type) {
        case "Select":
          return (
            <GridItem colSpan={input.colSpan} key={input.name}>
              <FormLabel htmlFor={input.name}>{input.label}</FormLabel>
              <SelectControl name={input.name}>
                {prepareOptionsWithoutId(input.options).map(showList)}
              </SelectControl>
            </GridItem>
          );

        case "FilteredSelect":
          return (
            <GridItem colSpan={input.colSpan} key={input.name}>
              <FormLabel htmlFor={input.name}>{input.label}</FormLabel>
              <SelectControl name={input.name}>
                {prepareOptionsWithoutId(
                  filterListOptions(input, formValues)
                ).map(showList)}
              </SelectControl>
            </GridItem>
          );

        case "MultiSelect":
          return (
            <GridItem colSpan={input.colSpan} key={input.name}>
              <Heading as="h5" size="sm" pb="2">
                {input.label}
              </Heading>
              <Select
                name={input.name}
                isMulti
                className="chakra-multiselect"
                classNamePrefix="react-multiselect"
                closeMenuOnSelect={false}
                placeholder="Seleziona"
                options={changeStringArrayForMultiselect(
                  input.options.map((a) => a)
                )}
                value={changeStringArrayForMultiselect(
                  formValues[input.name] ? formValues[input.name] : []
                )}
                onChange={(options) => {
                  setFieldValue(input.name, setMultiOptions(options));
                }}
              />
            </GridItem>
          );

        case "Text":
          return (
            <GridItem colSpan={input.colSpan} key={input.name}>
              <InputControl name={input.name} label={input.label} />
            </GridItem>
          );

        case "Radio":
          return (
            <GridItem colSpan={input.colSpan} key={input.name}>
              <RadioGroupControl
                name={input.name}
                label={input.label}
                stackProps={input.stackProps}
              >
                {input.options.map((o) => {
                  return <Radio value={o}>{o}</Radio>;
                })}
              </RadioGroupControl>
            </GridItem>
          );

        case "CheckboxGroup":
          return (
            <GridItem colSpan={input.colSpan} key={input.name}>
              <CheckboxContainer
                name={input.name}
                label={input.label}
                stackProps={input.stackProps}
              >
                {input.options.map((o) => {
                  return (
                    <CheckboxControl name={input.name} value={o} key={o}>
                      {o}
                    </CheckboxControl>
                  );
                })}
              </CheckboxContainer>
            </GridItem>
          );

        case "TextArea":
          return (
            <GridItem colSpan={input.colSpan} key={input.name}>
              <TextareaControl name={input.name} label={input.label} />
            </GridItem>
          );

        case "Checkbox":
          return (
            <GridItem colSpan={input.colSpan} key={input.name}>
              <CheckboxSingleControl name={input.name} label={input.label} />
            </GridItem>
          );

        default:
          return <></>;
      }
    });
  } else {
    return <></>;
  }
}

export const filterListOptions = (input, formValues) => {
  return input.options.flatMap((obj) => {
    return obj.filterOptionString === formValues[input.filterInputName]
      ? [obj.value]
      : [];
  });
};

export const renderForm = (formEntity, values, setFieldValue, commonData) => {
  if (commonData) {
    return (
      <>
        {formEntity.subCategory &&
          formEntity.subCategory.map((obj) => {
            if (obj.label === commonData.category)
              return switchInput(obj.props, values, setFieldValue);
          })}
      </>
    );
  } else {
    return (
      <>
        {formEntity.subCategory &&
          formEntity.subCategory.map((obj) => {
            return switchInput(obj.props, values, setFieldValue);
          })}
      </>
    );
  }
};

export const createInitialValues = (
  formEntity,
  formEntityName,
  data,
  category
) => {
  if (formEntity) {
    if (formEntity.subCategory) {
      const subCategory = formEntity.subCategory.find(
        (x) => x.label === category
      );
      if (subCategory && subCategory.props.length > 0) {
        return Object.assign(
          {},
          ...subCategory.props.map((x) => {
            switch (x.type) {
              case "Select":
              case "FilteredSelect":
                return {
                  [x.name]:
                    data[x.name] === undefined ? DEFAULT_ID : data[x.name],
                };

              case "Text":
              case "TextArea":
              case "Radio":
                return {
                  [x.name]: data[x.name] === undefined ? "" : data[x.name],
                };

              case "CheckboxGroup":
              case "MultiSelect":
                return {
                  [x.name]: data[x.name] === undefined ? [] : data[x.name],
                };

              case "Checkbox":
                return {
                  [x.name]: data[x.name] === undefined ? false : data[x.name],
                };
            }
          })
        );
      }
    }
  }
};

export const createInitialValuesSTE = (formEntity, formEntityName, data) => {
  if (formEntity) {
    if (formEntity.subCategory) {
      return Object.assign(
        {},
        ...formEntity.subCategory.map((subC) => {
          if (subC && subC.props.length > 0) {
            return Object.assign(
              {},
              ...subC.props.map((x) => {
                switch (x.type) {
                  case "Select":
                  case "FilteredSelect":
                    return {
                      [x.name]:
                        data[x.name] === undefined ? DEFAULT_ID : data[x.name],
                    };

                  case "Text":
                  case "TextArea":
                  case "Radio":
                    return {
                      [x.name]: data[x.name] === undefined ? "" : data[x.name],
                    };

                  case "CheckboxGroup":
                  case "MultiSelect":
                    return {
                      [x.name]: data[x.name] === undefined ? [] : data[x.name],
                    };

                  case "Checkbox":
                    return {
                      [x.name]:
                        data[x.name] === undefined ? false : data[x.name],
                    };
                }
              })
            );
          }
        })
      );
    }
  }
};

export const getSubCategoryLabel = (formEntity) => {
  if (formEntity) {
    if (formEntity.subCategory) {
      return formEntity.subCategory.filter(x => { return x.isPublished }).map((x) => {
        return x.label
      });
    }
  }
};
export const getOptGroupsMenuNames = (formEntity) => {
  if (formEntity) {
    if (formEntity.subCategory) {
      return [... new Set(formEntity.subCategory.map(x => x.menuName))]
    }
  }
};

export const displayDiagnosis = (diagnosisArray, onClose, setFieldValue) => {
  return diagnosisArray.map((d) => {
    return (
      <>
        <GridItem colSpan={5} key={d.code}>
          <Box key={d.code}>
            <Text isTruncated>
              {d.code} - {d.description}
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={1}>
          <Box key={d.Code}>
            <Center>
              <Button
                size="sm"
                onClick={() => {
                  setFieldValue("diagnosisInfo", d);
                  onClose();
                }}
              >
                Seleziona
              </Button>
            </Center>
          </Box>
        </GridItem>
      </>
    );
  });
};
