import { StrictMode } from 'react';
import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'filepond-polyfill';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AuthContextProvider from './context/AuthContextProvider';
import './index.css';
import App from './components/App';
import store from './store';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie9';

ReactDOM.render(
  <StrictMode>
    <AuthContextProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthContextProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
