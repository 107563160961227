import {
  Button,
  ButtonGroup,
  useDisclosure,
  Box,
  Flex,
  FormLabel,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FiTrash, FiDownload } from "react-icons/fi";
import { useParams } from "react-router-dom";
import {
  deleteAttachmentsFromRecord,
  deleteFileFromStorage,
  fileDownload,
} from "../../../api";
import { AlertDialog } from "../../Dialog";
import AttachmentsTableComponent from "./AttachmentsTable.component";
import * as constants from "../../../constants";
import { AuthContext } from "../../../context/AuthContextProvider";

function AttachmentsTableContainer({ record, dataArray, setArray }) {
  const [selectedData, setSelectedData] = useState({});
  const { code, version } = useParams();
  const authContext = useContext(AuthContext);

  const { onOpen, isOpen, onClose } = useDisclosure();

  const columns = [
    {
      Header: "Data Caricamento",
      accessor: "uploadDate",
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ cell }) => {
        return <span>{cell.value}</span>;
      },
    },
    {
      Header: "Nome File",
      accessor: "originalname",
    },
    {
      id: "actions",
      Header: "Azioni",
      accessor: "actions",
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ cell }) => {
        return (
          <ButtonGroup isAttached>
            <Button
              size="sm"
              onClick={() => {
                fileDownload(cell.row.original);
              }}
            >
              <FiDownload />
            </Button>
            <Button
              key={cell.row.original.filename}
              size="sm"
              onClick={() => {
                setSelectedData(cell.row.original);
                onOpen();
              }}
            >
              <FiTrash />
            </Button>
          </ButtonGroup>
        );
      },
    },
  ];

  return (
    <Box margin="2" justify="center">
      <>
        <Flex pb="2">
          <FormLabel>
            Lista degli allegati
          </FormLabel>
        </Flex>
        <AttachmentsTableComponent columns={columns} data={dataArray} />
        <AlertDialog
          title="Attenzione"
          isOpen={isOpen}
          onClose={onClose}
          body={`Sei sicuro di voler eliminare il file selezionato?`}
          onConfirm={() => {
            const author = `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`;
            deleteFileFromStorage(selectedData);
            deleteAttachmentsFromRecord(
              record,
              code,
              version,
              author,
              selectedData,
              constants.ATTACHMENTS
            );
            setArray(
              dataArray.filter((item) => {
                return item.filename !== selectedData.filename;
              })
            );
            onClose();
          }}
          confirmButtonText="Elimina"
          confirmButtonColor="red"
        />
      </>
    </Box>
  );
}

export default AttachmentsTableContainer;
