import { Formik } from "formik";
import * as Yup from "yup";
import { forwardRef, useContext, useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";
import * as constants from "../../constants";
import SectionSTEComponent from "./SectionSTE.component";
import {
  createNewVersion,
  createRecord,
  getRecordByCode,
  updateRecord,
} from "../../api";
import { AuthContext } from "../../context/AuthContextProvider";
import Loader from "../Loader";
import { getDataFromRecord } from "../../helpers";
import { Button, useTheme } from "@chakra-ui/react";
import * as routes from "../../navigation/routes";
import { Icon } from "@chakra-ui/icons";
import { ImPencil2 } from "react-icons/im";

function SectionSTEContainer() {
  const toast = useToast();

  /* initial state */
  const [record, setRecord] = useState({});
  const [data, setData] = useState({});

  /* form statuses */
  const [isCreation, setIsCreation] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const authContext = useContext(AuthContext);
  const [error, setError] = useState();
  const { code, version } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  /* button statuses */
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isNewVersion, setIsNewVersion] = useState(false);

  /* Gestione link alle singole sezioni */
  const theme = useTheme();

  const LinkItems = [
    { name: "Sintomi", icon: ImPencil2, route: routes.SYMPTOMS_SIMPLE },
    { name: "Trattamenti", icon: ImPencil2, route: routes.TREATMENTS_SIMPLE },
    { name: "Esami", icon: ImPencil2, route: routes.EXAMS_SIMPLE },
  ];

  const LinkButton = forwardRef((props, ref) => {
    return <Button ref={ref} as={Link} {...props} />;
  });

  function NavItem({ location, icon, children, theme, route }) {
    return (
      <LinkButton
        size="lg"
        _hover={{
          bgGradient: `linear(to-r, ${theme.colors.primary.darker}, ${theme.colors.primary.main})`,
          color: "white",
        }}
        variant="ghost"
        style={{ backgroundColor: theme.colors.primary.main, color: "white" }}
        leftIcon={<Icon mr="4" fontSize="16" as={icon} />}
        to={`${route}/${location.pathname.split("/").slice(2).join("/")}`}
      >
        {children}
      </LinkButton>
    );
  }

  const initialValues = {
    firstCallSymptoms:
      data.firstCallSymptoms === undefined ? "" : data.firstCallSymptoms,
    firstCallTreatments:
      data.firstCallTreatments === undefined ? "" : data.firstCallTreatments,
    firstCallExams:
      data.firstCallExams === undefined ? "" : data.firstCallExams,
  };

  const validationSchema = Yup.object().shape({
    firstCallSymptoms: Yup.string(),
    firstCallTreatments: Yup.string(),
    firstCallExams: Yup.string(),
  });

  useEffect(() => {
    const fetchData = async () => {
      const [respRecord] = await Promise.all([getRecordByCode(code)]);
      setRecord(respRecord.data || {});
      const extractedData = getDataFromRecord(
        respRecord.data,
        version,
        constants.STE
      );
      extractedData.record_id = respRecord.data.record_id;
      setData(extractedData);
      setIsCreation(respRecord.data.code === undefined);
      setIsLoaded(true);
    };
    fetchData();
  }, []);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Box margin="2" justify="center">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          try {
            const author = `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`;
            if (isCreating) {
              const resp = await createRecord(record, code, version, author, {
                recordDetails: values,
              });
              setError(resp.data.error);
            } else if (isUpdating) {
              const resp = await updateRecord(
                record,
                code,
                version,
                author,
                values,
                constants.STE
              );
              setError(resp.data.error);
            } else if (isNewVersion) {
              const { newVersionId } = await createNewVersion(
                record,
                code,
                version,
                author
              );
              const resp = await updateRecord(
                record,
                code,
                newVersionId,
                author,
                values,
                constants.STE
              );
              setError(resp.data.error);
              navigate(
                `/${location.pathname.split("/")[1]}/${code}/${newVersionId}`
              );
            }

            toast({
              title: "Record Salvato",
              description: "L'operazione è andata a buon fine",
              status: "success",
              duration: 9000,
              isClosable: true,
            });

            actions.setSubmitting(false);
            actions.setStatus({
              created: true,
            });

            setIsNewVersion(false);
            setIsCreating(false);
            setIsUpdating(false);
          } catch (er) {
            setError({
              ...er,
            });
            toast({
              title: "Errore",
              description: er,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            actions.setSubmitting(false);
          }
          setIsCreation(false);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          isSubmitting,
          isValid,
        }) => {
          return (
            <SectionSTEComponent
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              values={values}
              isSubmitting={isSubmitting}
              isValid={isValid}
              error={error}
              isCreation={isCreation}
              setIsNewVersion={setIsNewVersion}
              setIsCreating={setIsCreating}
              setIsUpdating={setIsUpdating}
              userProfile={authContext.userProfile}
              LinkItems={LinkItems}
              NavItem={NavItem}
              location={location}
              theme={theme}
            />
          );
        }}
      </Formik>
    </Box>
  );
}

export default SectionSTEContainer;
