import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useTheme,
  FormControl,
  FormLabel,
  HStack,
  Button,
  ButtonGroup,
  VStack,
  Heading,
  Input,
  useColorMode
} from "@chakra-ui/react";
import { AiFillSave, AiFillStar } from "react-icons/ai";
import { FiSearch, FiTrash } from "react-icons/fi";
import { FaEraser } from "react-icons/fa";
import {
  InputControl,
  NumberInputControl,
  CheckboxSingleControl,
} from "formik-chakra-ui";
import {
  changeArrayForMultiselect,
  changeStringArrayForMultiselect,
  isBase,
  setMultiOptions,
} from "../../helpers";
import { CSVLink } from "react-csv";
import React, { useRef } from "react";
import DatePickerRange from "../Form/DatePickerRange";
import { Select } from "chakra-react-select";

function AdvancedFiltersComponent({
  userProfile,
  handleSubmit,
  isSubmitting,
  isValid,
  setIsSearching,
  resetForm,
  values,
  setFieldValue,
  clinicalRecords,
  filtersArray,
  changeSelectedPreset,
  changePresetName,
  setSelectedFilter,
  setSelectedPresetName,
  selectedPresetName,
  mutationFilters,
  deleteFilters,
  agentCategories,
  tagsArray,
  setInputName,
  inputName,
}) {
  const theme = useTheme();
  const csvLink = useRef();
  const isLight = useColorMode().colorMode === "light";

  return (
    <form onSubmit={handleSubmit}>
      <Accordion allowToggle boxShadow="base">
        <AccordionItem>
          <h2>
            <AccordionButton
              borderTopRadius={"8px"}
              borderBottomRadius={"8px"}
              color="white"
              bg={theme.colors.primary.main}
              _hover={{ bg: theme.colors.primary.dark }}
            >
              <Box flex="1" textAlign="left">
                <b>Filtri Avanzati </b>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel bg={isLight ? "white" : "gray.900"} borderBottom={"1px solid #356d80"}>
            {/* Gestione filtri salvati dall'utente */}
            <Heading as="h4" size="md" pb="4" pt="2">
              Gestione Query
            </Heading>
            <HStack spacing={8}>
              {filtersArray.length && (
                <VStack spacing={2} w="50%" align="left">
                  <Select
                    className="chakra-multiselect"
                    classNamePrefix="react-multiselect"
                    id="selectedPreset"
                    name="selectedPreset"
                    placeholder="Seleziona query"
                    onChange={changeSelectedPreset}
                    options={changeArrayForMultiselect(
                      filtersArray,
                      "presetName",
                      "presetName"
                    )}
                    value={
                      selectedPresetName !== "" &&
                      changeStringArrayForMultiselect[selectedPresetName]
                    }
                  />
                </VStack>
              )}

              {/* Buttons to menage presets */}

              {selectedPresetName === "" ? (
                <>
                  <VStack spacing={2} w="40%" align="left">
                    <Input
                      onChange={changePresetName}
                      placeholder="Inserisci qui il nome per salvare una nuova query"
                    />
                  </VStack>
                  <VStack spacing={2} w="10%" align="left">
                    <Button
                      type="submit"
                      rightIcon={<AiFillStar />}
                      colorScheme="blue"
                      variant="outline"
                      disabled={inputName === ""}
                      onClick={() => {
                        values.presetName = inputName;
                        setSelectedPresetName(inputName);
                        setInputName("");
                        mutationFilters.mutate(values);
                      }}
                    >
                      Salva Query
                    </Button>
                  </VStack>
                </>
              ) : (
                <>
                  {!values.isPublic && (
                    <>
                      <Button
                        type="submit"
                        rightIcon={<AiFillStar />}
                        colorScheme="blue"
                        variant="outline"
                        disabled={isSubmitting || !isValid}
                        onClick={() => {
                          values.presetName = selectedPresetName;
                          mutationFilters.mutate(values);
                        }}
                      >
                        Modifica Query
                      </Button>
                      <Button
                        type="submit"
                        rightIcon={<FiTrash />}
                        colorScheme="blue"
                        variant="outline"
                        disabled={isSubmitting || !isValid}
                        onClick={() => {
                          resetForm();
                          setSelectedPresetName("");
                          setSelectedFilter({});
                          values.presetName = selectedPresetName;
                          deleteFilters.mutate(values);
                        }}
                      >
                        Elimina Query
                      </Button>
                    </>
                  )}
                </>
              )}
            </HStack>

            <Heading as="h4" size="md" pt="6">
              Filtro
            </Heading>
            <HStack spacing={8} mt={4}>
              <NumberInputControl
                name="record_id"
                label="ID"
                numberInputProps={{ min: 3000000 }}
              />

              <InputControl name="user" label="Utente" />

              <InputControl name="patient" label="Paziente" />
            </HStack>
            <HStack spacing={8} mt={4}>
              <VStack spacing={2} w="33%" align="left">
                <FormLabel>Data Creazione (Da - A)</FormLabel>
                <DatePickerRange
                  name="creationDateRange"
                  startName="startCreationDate" //data inizio del range
                  endName="endCreationDate" //data fine del range
                  selectsRange={true}
                  values={values}
                />
              </VStack>

              <VStack spacing={2} w="33%" align="left">
                <FormLabel>Ultimo Aggiornamento (Da - A)</FormLabel>
                <DatePickerRange
                  name="LastUpdateDateRange"
                  startName="startLastUpdateDate"
                  endName="endLastUpdateDate"
                  selectsRange={true}
                  values={values}
                />
              </VStack>

              <VStack spacing={2} w="33%" align="left">
                <FormLabel htmlFor="status">Status</FormLabel>
                <Select
                  name="status"
                  isMulti
                  closeMenuOnSelect={false}
                  className="chakra-multiselect"
                  classNamePrefix="react-multiselect"
                  placeholder="Seleziona gli stati"
                  options={changeStringArrayForMultiselect([
                    "Tutte",
                    "Aperta",
                    "Chiusa",
                    "Archiviata",
                    "Follow-Up",
                  ])}
                  value={changeStringArrayForMultiselect(values.status)}
                  onChange={(options) => {
                    setFieldValue("status", setMultiOptions(options));
                  }}
                />
              </VStack>
            </HStack>
            <HStack spacing={8} mt={4}>
              <VStack spacing={2} w="50%" align="left">
                <FormLabel htmlFor="status">Lista agenti</FormLabel>
                <Select
                  name="agent"
                  isMulti
                  closeMenuOnSelect={false}
                  className="chakra-multiselect"
                  classNamePrefix="react-multiselect"
                  placeholder="Seleziona gli agenti"
                  options={changeStringArrayForMultiselect(
                    agentCategories.map((a) => a.desc)
                  )}
                  value={changeStringArrayForMultiselect(values.agent)}
                  onChange={(options) => {
                    setFieldValue("agent", setMultiOptions(options));
                  }}
                />
              </VStack>
              <VStack spacing={2} w="50%" align="left">
                <FormLabel htmlFor="status">Tag</FormLabel>
                <Select
                  name="tags"
                  isMulti
                  closeMenuOnSelect={false}
                  className="chakra-multiselect"
                  classNamePrefix="react-multiselect"
                  placeholder="Seleziona i tag"
                  options={changeStringArrayForMultiselect(
                    tagsArray.map((a) => a.desc)
                  )}
                  value={changeStringArrayForMultiselect(values.tags)}
                  onChange={(options) => {
                    setFieldValue("tags", setMultiOptions(options));
                  }}
                />
              </VStack>
            </HStack>
            <HStack spacing={8} mt={4}>
              <VStack spacing={2} w="25%" align="left">
                <InputControl name="city" label="Città" />
              </VStack>
              <VStack spacing={2} w="25%" align="left">
                <InputControl name="province" label="Provincia" />
              </VStack>
              <VStack spacing={2} w="25%" align="left">
                <InputControl name="region" label="Regione" />
              </VStack>
              <VStack spacing={2} w="25%" align="left" mt={4}>
                <CheckboxSingleControl
                  name="errands"
                  label="Solo Record con Consegne"
                />
              </VStack>
            </HStack>
            <HStack spacing={8} mt={4} >
              <FormControl w="15%" align="right">
                <ButtonGroup spacing={4} mt={5}>
                  <Button
                    type="submit"
                    rightIcon={<FiSearch />}
                    colorScheme="blue"
                    variant="outline"
                    disabled={isSubmitting || !isValid}
                    onClick={() => {
                      return setIsSearching(true);
                    }}
                  >
                    Effettua Ricerca
                  </Button>
                  <Button
                    type="submit"
                    rightIcon={<FaEraser />}
                    colorScheme="blue"
                    variant="outline"
                    disabled={isSubmitting || !isValid}
                    onClick={() => {
                      resetForm();
                      setSelectedPresetName("");
                      setInputName("");
                      setSelectedFilter({});
                    }}
                  >
                    Ripristina Filtri
                  </Button>
                  {!isBase(userProfile) && (
                    <>
                      <Button
                        type="submit"
                        rightIcon={<AiFillSave />}
                        colorScheme="blue"
                        variant="outline"
                        disabled={isSubmitting || !isValid}
                        onClick={() => {
                          csvLink.current.link.click();
                        }}
                      >
                        Esporta Risultati
                      </Button>
                      <CSVLink
                        data={clinicalRecords.map(
                          ({ versions, version, code, applicant, ...rest }) => {
                            return rest;
                          }
                        )}
                        filename="cav_ricerca_corrente.csv"
                        target="_blank"
                        className="hidden"
                        ref={csvLink}
                      />
                    </>
                  )}
                </ButtonGroup>
              </FormControl>
            </HStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </form>
  );
}

export default AdvancedFiltersComponent;
