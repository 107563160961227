import { Button, Grid, GridItem, FormLabel } from "@chakra-ui/react";
import { Field } from "formik";
import FileUpload from "../Form/FileUpload";
import { fileupload } from "../../api";
import { FaSave } from "react-icons/fa";


function AttachmentsComponent({
  handleSubmit,
  isSubmitting,
  isValid,
  isCreation,
  setIsCreating,
  setIsUpdating,
  setFieldValue,
  array,
  setArray,
  theme,
  isLight
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid w="100%" templateColumns="repeat(6, 1fr)" columnGap={6} rowGap={6} mt={4}>
        {/* Modalità segnalazione */}
        <GridItem colSpan={6}>
          <FormLabel>
            Inserisci File
          </FormLabel>
          <Field
            label={"Inserisci File"}
            component={FileUpload}
            api={fileupload}
            onload={(value) => {
              array.push(JSON.parse(value)[0]);
              setArray(array);
              setFieldValue("attachmentInfo", array);
            }}
          />
        </GridItem>
      </Grid>

      <Grid w="100%" templateColumns="repeat(6, 1fr)" mt={12} >
        <GridItem colStart={6}>
          {isCreation ? (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsCreating(true);
              }}
            >
              Salva Scheda
            </Button>
          ) : (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsUpdating(true);
              }}
            >
              Modifica Scheda
            </Button>
          )
          }
        </GridItem >
      </Grid >
    </form>
  );
}

export default AttachmentsComponent;
