import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  GridItem,
  Radio,
  Stack,
} from "@chakra-ui/react";
import {
  CheckboxSingleControl,
  InputControl,
  NumberInputControl,
  RadioGroupControl,
  TextareaControl,
} from "formik-chakra-ui";
import { DatePickerInput } from "../../Form";
import { calculateAge, isAdmin } from "../../../helpers";
import { FaSave } from "react-icons/fa";

function HumanDataComponent({
  handleSubmit,
  handleChange,
  setFieldValue,
  isSubmitting,
  isValid,
  values,
  isCreation,
  setIsCreating,
  setIsUpdating,
  setIsNewVersion,
  userProfile,
  theme
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid templateColumns="repeat(6, 1fr)" columnGap={6} rowGap={6} mt={6}>
        {/* Nome e cognome */}
        <GridItem colSpan={3}>
          <InputControl name="name" label="Nome" />
        </GridItem>
        <GridItem colSpan={3}>
          <InputControl name="surname" label="Cognome" />
        </GridItem>

        {/* Data di nascita o anno di nascita in base a ciò che si conosce */}
        <GridItem colSpan={3}>
          <RadioGroupControl
            name="birthDateDesc"
            label="Informazioni sulla data di nascita"
            radioGroupProps={{ defaultValue: "Completa" }}
          >
            <Radio
              value="Completa"
              onClick={(e) => {
                handleChange(e);
                setFieldValue("yearOfBirth", "");
                setFieldValue("ageDesc", "");
                setFieldValue("age", { years: "", months: "", days: "" });
              }}
            >
              Completa
            </Radio>
            <Radio
              value="Solo anno"
              onClick={(e) => {
                handleChange(e);
                setFieldValue("birthDate", "");
                setFieldValue("ageDesc", "");
                setFieldValue("age", { years: "", months: "", days: "" });
              }}
            >
              Solo anno
            </Radio>
          </RadioGroupControl>
        </GridItem>



        {/* Età */}
        <GridItem colSpan={3}>
          <RadioGroupControl name="ageDesc" label="Informazioni sull'età">
            <Radio value="Nota">Nota</Radio>
            <Radio value="Non nota adulto">Non nota adulto</Radio>
            <Radio value="Non nota bambino">Non nota bambino</Radio>
            <Radio value="Stimata">Stimata</Radio>
          </RadioGroupControl>
        </GridItem>

        <GridItem colSpan={3} >
          {values.birthDateDesc === "Completa" && (
            <>
              <FormLabel>Data di Nascita</FormLabel>
              <Stack direction="row" alignItems="end" spacing={6}>
                <DatePickerInput name="birthDate" />
                <Button
                  disabled={values.birthDate === ""}
                  onClick={() => {
                    calculateAge(values, setFieldValue);
                  }}
                >
                  Calcola età
                </Button>
              </Stack>
            </>
          )}

          {values.birthDateDesc === "Solo anno" && (
            <Stack direction="row" alignItems="end" spacing={10}>
              <NumberInputControl name="yearOfBirth" label="Anno di nascita"
                numberInputProps={{}}
              />
              <Button
                disabled={values.yearOfBirth === ""}
                onClick={() => {
                  calculateAge(values, setFieldValue);
                }}
              >
                Calcola età
              </Button>
            </Stack>
          )}
        </GridItem>
        <GridItem colSpan={1}>
          <NumberInputControl
            showStepper={false}
            name="age.years"
            label="Anni"
            numberInputProps={{ value: values.age.years, }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <NumberInputControl
            showStepper={false}
            name="age.months"
            label="Mesi"
            numberInputProps={{ value: values.age.months, }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <NumberInputControl
            showStepper={false}
            name="age.days"
            label="Giorni"
            numberInputProps={{ value: values.age.days, }}
          />
        </GridItem>


        {/* Input per il sesso con gravidanza e allattamento solo se femmina */}
        <GridItem colSpan={3}>
          <RadioGroupControl name="sex" label="Sesso">
            <Radio
              value="Maschio"
              onClick={(e) => {
                handleChange(e);
                setFieldValue("pregnancy", false);
                setFieldValue("breastfeeding", false);
              }}
            >
              Maschio
            </Radio>
            <Radio value="Femmina">Femmina</Radio>
            <Radio
              value="Non noto"
              onClick={(e) => {
                handleChange(e);
                setFieldValue("pregnancy", false);
                setFieldValue("breastfeeding", false);
              }}
            >
              Non noto
            </Radio>
          </RadioGroupControl>
        </GridItem>
        <GridItem colSpan={3}>
          {values.sex === "Femmina" && (
            <Stack direction="row" align="start" justify="space-between" mt={8}>
              <CheckboxSingleControl name="pregnancy">
                Gravidanza
              </CheckboxSingleControl>
              <CheckboxSingleControl name="breastfeeding">
                Allattamento
              </CheckboxSingleControl>
            </Stack>
          )}
        </GridItem>

        {/* Input per il peso: viene mostrato solo quando si conosce il valore da inserire */}
        <GridItem colSpan={3}>
          <RadioGroupControl name="weightDesc" label="Informazioni sul peso">
            <Radio value="Noto">Noto</Radio>
            <Radio value="Stimato">Stimato</Radio>
            <Radio
              value="Non noto"
              onClick={(e) => {
                handleChange(e);
                setFieldValue("weight", "");
              }}
            >
              Non noto
            </Radio>
          </RadioGroupControl>
        </GridItem>
        {/* Altezza */}
        <GridItem colSpan={3} colStart={1}>
          <NumberInputControl name="height" label="Altezza (cm)" numberInputProps={{}}
          />
        </GridItem>

        <GridItem colSpan={3}>
          {values.weightDesc !== "" && values.weightDesc !== "Non noto" && (
            <NumberInputControl name="weight" label="Peso (Kg)" numberInputProps={{}}
            />
          )}
        </GridItem>

        {/* Text area per anamnesi con radio button */}
        <GridItem colSpan={5}>
          <TextareaControl name="medicalHistory" label="Anamnesi" textareaProps={{}}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <RadioGroupControl
            name="medicalHistoryDesc"
            stackProps={{ direction: "column", mt: 6, ml: 10 }}
          >
            <Radio value="Certa">Certa</Radio>
            <Radio value="Stimata">Stimata</Radio>
            <Radio value="Sospetta">Sospetta</Radio>
          </RadioGroupControl>
        </GridItem>

        {/* Descrizione Evento */}
        <GridItem colSpan={5}>
          <TextareaControl name="eventDescription" label="Descrizione Evento" textareaProps={{}} />
        </GridItem>
      </Grid>
      {/* Bottoni */}
      <Grid w="100%" templateColumns="repeat(6, 1fr)" mt={12} align="right">
        <GridItem colStart={6} >
          {isCreation ? (
            <Button
              type="submit"
              disabled={isSubmitting || !isValid}
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              onClick={() => {
                return setIsCreating(true);
              }}
            >
              Salva Scheda
            </Button>
          ) : (
            <ButtonGroup>
              <Button
                type="submit"
                disabled={isSubmitting || !isValid}
                leftIcon={<FaSave />}
                background={theme.colors.primary.main}
                color={'white'}
                onClick={() => {
                  return setIsUpdating(true);
                }}
              >
                Modifica Scheda
              </Button>
            </ButtonGroup>
          )}
        </GridItem>
      </Grid>
    </form>
  );
}

export default HumanDataComponent;
