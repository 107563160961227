import {
  Button,
  ButtonGroup,
  Tooltip,
  useDisclosure,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useEffect, useState, useContext } from "react";
import { FiEdit, FiEye, FiTrash, FiList } from "react-icons/fi";
import { ImNewTab } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import HomeComponent from "./Home.component";
import {
  deleteClinicalRecord,
  getAllClinicalRecordsPaginated,
  deleteClinicalRecordVersion,
  getUserPreferences,
  updateUserPreference,
  deleteUserPreference,
  getAllAgentCategories,
  getAllTags,
} from "../../api";
import { AlertDialog } from "../Dialog";
import { useToast } from "@chakra-ui/toast";
import * as routes from "../../navigation/routes";
import { SelectColumnFilter } from "./HomeGlobalSearch";
import { AuthContext } from "../../context/AuthContextProvider";
import { hasAttachments, hasErrands, isAdmin } from "../../helpers";
import { VscFiles } from "react-icons/vsc";

function HomeContainer() {
  const [selectedFilter, setSelectedFilter] = useState({});
  const [filtersArray, setFiltersArray] = useState([]);
  const [agentCategories, setAgentCategories] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageElements, setPageElements] = useState(10);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [selectedClinicalRecord, setSelectedClinicalRecord] = useState(null);
  const [toggleFilter, setToggleFilter] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { userProfile } = useContext(AuthContext);

  const email = userProfile.email;
  const { data: userPreferences } = useQuery(
    ["userPreferences", email],
    () => {
      return getUserPreferences(email);
    },
    {
      onSuccess: ({ data: userPreferences }) => {
        setFiltersArray(userPreferences === undefined ? [] : userPreferences);
      },
    }
  );

  const { data: agentCategoryArray } = useQuery(
    ["getAgentCategory"],
    () => {
      return getAllAgentCategories();
    },
    {
      onSuccess: ({ data: agentCategoryArray }) => {
        setAgentCategories(
          agentCategoryArray === undefined ? [] : agentCategoryArray
        );
      },
    }
  );

  const { data: tagArray } = useQuery(
    ["getTagArray"],
    () => {
      return getAllTags();
    },
    {
      onSuccess: ({ data: tagArray }) => {
        setTagsArray(tagArray === undefined ? [] : tagArray);
      },
    }
  );

  const { isLoading, isError, error, data } = useQuery(
    ["pageFetch", pageNumber, pageElements, selectedFilter, userPreferences],
    () => {
      return getAllClinicalRecordsPaginated(
        pageNumber,
        pageElements,
        userPreferences?.selectedFilter || selectedFilter
      );
    },
    {
      keepPreviousData: true,
    }
  );

  const mutationRecord = useMutation(
    (deletedItem) => {
      return deleteClinicalRecord(deletedItem);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("pageFetch");
      },
    }
  );

  const mutationVersion = useMutation(
    (code, version) => {
      return deleteClinicalRecordVersion(code, version);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("pageFetch");
        toast({
          title: "Versione eliminata",
          description: "Versione eliminata con successo",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  const mutationFilters = useMutation(
    (values) => {
      return updateUserPreference(values, email);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userPreferences");
        queryClient.invalidateQueries("pageFetch");
        toast({
          title: "Query Salvata",
          description:
            "Al prossimo login potrai selezionarla dall'apposito elenco",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  const deleteFilters = useMutation(
    (values) => {
      return deleteUserPreference(values, email);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userPreferences");
        queryClient.invalidateQueries("pageFetch");
        toast({
          title: "Query Eliminata",
          description: "La query è stata eliminata con successo",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  useEffect(() => {
    queryClient.prefetchQuery(["pageFetch", pageNumber, pageElements], () => {
      return getAllClinicalRecordsPaginated(
        pageNumber,
        pageElements,
        selectedFilter
      );
    });
  }, [pageNumber, pageElements, selectedFilter]);

  const columns = [
    {
      Header: "ID",
      accessor: "record_id",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["record_id"] }),
      filterAll: true,
    },
    {
      Header: "Utente",
      accessor: "author",
    },
    {
      Header: "Paziente",
      accessor: "patient",
    },
    {
      Header: "Data Creazione",
      accessor: "creationDate",
    },
    {
      Header: "Ultimo Aggiornamento",
      accessor: "lastUpdateDate",
    },
    {
      Header: "Località",
      accessor: "city",
    },
    {
      Header: "Agente",
      accessor: "agent",
    },
    {
      Header: "Stato",
      accessor: "status",
      Filter: SelectColumnFilter,
      filter: "includes",
    },

    {
      id: "actions",
      Header: "Azioni",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ cell, row }) => {
        return (
          <ButtonGroup isAttached>
            {/* {isAdmin(userProfile) && (
              <Tooltip hasArrow mt="3" label="Visualizza Versioni">
                <Button
                  key={cell.row.original.id}
                  size="sm"
                  colorScheme={row.isExpanded ? "teal" : "gray"}
                  onClick={() => {
                    row.toggleRowExpanded();
                  }}
                >
                  <FiEye />
                </Button>
              </Tooltip>
            )} */}
            <Tooltip hasArrow mt="3" label="Visualizza Dettagli Cartella">
              <Button
                key={cell.row.original.id}
                size="sm"
                onClick={() => {
                  window.open(
                    `.${routes.RECORD_INFO_SIMPLE}/${cell.row.original.code}/${cell.row.original.version}`,
                    "_blank",
                    "height=1024,width=1024"
                  );
                }}
              >
                <ImNewTab />
              </Button>
            </Tooltip>
            <Tooltip hasArrow mt="3" label="Modifica Versione Corrente">
              <Button
                size="sm"
                onClick={() => {
                  return navigate(
                    `${routes.RECORD_DETAILS_SIMPLE}/${cell.row.original.code}/${cell.row.original.version}`
                  );
                }}
              >
                <FiEdit />
              </Button>
            </Tooltip>
            {isAdmin(userProfile) && (
              <Tooltip hasArrow mt="3" label="Elimina Record">
                <Button
                  key={cell.row.original.id}
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setSelectedClinicalRecord(cell.row.original.code);
                  }}
                >
                  <FiTrash />
                </Button>
              </Tooltip>
            )}
            {hasErrands(cell.row.original) && (
              <Tooltip hasArrow mt="3" label="Consegne">
                <Button
                  size="sm"
                  onClick={() => {
                    return navigate(
                      `${routes.ERRANDS_SIMPLE}/${cell.row.original.code}/${cell.row.original.version}`
                    );
                  }}
                >
                  <FiList />
                </Button>
              </Tooltip>
            )}
            {hasAttachments(cell.row.original) && (
              <Tooltip hasArrow mt="3" label="Allegati">
                <Button
                  size="sm"
                  onClick={() => {
                    return navigate(
                      `${routes.ATTACHMENTS_SIMPLE}/${cell.row.original.code}/${cell.row.original.version}`
                    );
                  }}
                >
                  <VscFiles />
                </Button>
              </Tooltip>
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  if (isLoading) {
    return <Skeleton height="300px" />;
  }

  if (isError) {
    return <Text fontSize="2xl">{error.message}</Text>;
  }

  return (
    <>
      <HomeComponent
        columns={columns}
        clinicalRecords={data.data.clinicalRecords}
        pageNumber={pageNumber}
        pageElements={pageElements}
        totalItems={data.data.totalItems}
        prevPage={data.data.previousPage}
        nextPage={data.data.nextPage}
        lastPage={Math.ceil(data.data.totalItems / pageElements)}
        setPageNumber={setPageNumber}
        setPageElements={setPageElements}
        setSelectedClinicalRecord={setSelectedClinicalRecord}
        mutationVersion={mutationVersion}
        toggleFilter={toggleFilter}
        setToggleFilter={setToggleFilter}
        mutationFilters={mutationFilters}
        deleteFilters={deleteFilters}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        filtersArray={filtersArray}
        agentCategories={agentCategories}
        tagsArray={tagsArray}
      />

      <AlertDialog
        title="Attenzione"
        isOpen={isOpen}
        onClose={onClose}
        body={`
                        Sei sicuro di voler eliminare
                           il record clinico: ${selectedClinicalRecord}?`}
        onConfirm={() => {
          mutationRecord.mutate(selectedClinicalRecord);
          onClose();
        }}
        confirmButtonText="Elimina"
        confirmButtonColor="red"
      />
    </>
  );
}

export default HomeContainer;
