import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Box } from "@chakra-ui/react";
import DiagnosisSearchComponent from "./DiagnosisSearch.component";
import { searchDiagnosis } from '../../../api'

function DiagnosisSearchContainer({
  modalIsOpen,
  modalOnClose,
  setFieldValue,
}) {
  const [diagnosisArray, setDiagnosisArray] = useState([]);

  const [error, setError] = useState();

  const initialValues = {
    description: "",
    code: "",
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string(),
    code: Yup.string(),
  });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          try {
            const resp = await searchDiagnosis(values.code, values.description);
            console.log(resp)
            setDiagnosisArray(resp.data);
            actions.setSubmitting(false);
          } catch (er) {
            setError({
              ...er,
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          isSubmitting,
          isValid,
          dirty,
          resetForm,
        }) => {
          return (
            <DiagnosisSearchComponent
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              values={values}
              isSubmitting={isSubmitting}
              isValid={isValid}
              dirty={dirty}
              modalIsOpen={modalIsOpen}
              modalOnClose={modalOnClose}
              diagnosisArray={diagnosisArray}
              resetForm={resetForm}
              setDiagnosisArray={setDiagnosisArray}
              setFieldValue={setFieldValue}
            />
          );
        }}
      </Formik>
    </Box>
  );
}

export default DiagnosisSearchContainer;
