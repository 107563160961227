import React, { useState } from "react";
import FiltersPresetsComponent from "./FormsManagement.component";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/toast";

import { getFormsEntity } from "../../api";
import { SkeletonText } from "@chakra-ui/react";

const FormsManagementContainer = () => {
  const toast = useToast();
  const queryClient = useQueryClient({});

  const { isLoading, data } = useQuery(["formsEntityManagement"], () => {
    return getFormsEntity();
  });

  return (
    <SkeletonText isLoaded={!isLoading}>
      <FiltersPresetsComponent
        data={data && data.data}
      />
    </SkeletonText>
  );
};

FormsManagementContainer.propTypes = {};

FormsManagementContainer.defaultProps = {};

export default FormsManagementContainer;
