import React from 'react';
import ForbiddenComponent from './Forbidden.component';

const ForbiddenContainer = () =>

    <ForbiddenComponent />


ForbiddenContainer.propTypes = {};

ForbiddenContainer.defaultProps = {};

export default ForbiddenContainer;