import {
  Button,
  FormLabel,
  Grid,
  GridItem
} from "@chakra-ui/react";
import {
  InputControl,
  NumberInputControl,
  SelectControl,
} from "formik-chakra-ui";
import { isAdmin, showList } from "../../helpers";
import { FaSave } from "react-icons/fa";

function RecordDetailsComponent({
  handleSubmit,
  isSubmitting,
  isValid,
  isCreation,
  setIsCreating,
  setIsUpdating,
  theme
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid w="100%" templateColumns="repeat(6, 1fr)" columnGap={6} rowGap={6} mt={4}>
        <GridItem colSpan={2}>
          {/* Modalità segnalazione */}
          <InputControl
            name="code"
            label="Codice"
            inputProps={{ isDisabled: true, variant: "filled" }} />
        </GridItem>
        <GridItem colSpan={2}>
          <NumberInputControl
            name="version"
            label="Versione"
            numberInputProps={{ isDisabled: true, variant: "filled" }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <InputControl
            name="creationDate"
            label="Data"
            inputProps={{ isDisabled: true, variant: "filled" }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <InputControl
            name="openedBy"
            label="Operatore"
            inputProps={{ isDisabled: true, variant: "filled" }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <FormLabel htmlFor="status">Status</FormLabel>
          <SelectControl name="status"  >
            {["Aperta", "Chiusa", "Archiviata", "Follow-Up"].map(showList)}
          </SelectControl>
        </GridItem>
        <GridItem colSpan={2}>
          <NumberInputControl
            name="contactNumber"
            label="Numero Contatti"
          />
        </GridItem>
      </Grid>
      <Grid w="100%" templateColumns="repeat(6, 1fr)" mt={12} >
        <GridItem colStart={6}>
          {isCreation ? (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsCreating(true);
              }}
            >
              Salva Scheda
            </Button>
          ) : (
            <Button
              leftIcon={<FaSave />}
              type="submit"
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsUpdating(true);
              }}
            >
              Modifica Scheda
            </Button>
          )}
        </GridItem>
      </Grid>

    </form >
  );
}

export default RecordDetailsComponent;
