import { Formik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { Box, useTheme, useColorMode, Text } from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";
import EvaluateToxicityComponent from "./EvaluateToxicity.component";
import {
  createNewVersion,
  createRecord,
  getRecordByCode,
  updateRecord,
} from "../../api";
import { AuthContext } from "../../context/AuthContextProvider";
import Loader from "../Loader";
import { getDataFromRecord } from "../../helpers";
import * as constants from "../../constants";

function EvaluateToxicityContainer() {
  const [record, setRecord] = useState({});
  const [toxicityData, setToxicityData] = useState({});
  const [isCreation, setIsCreation] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const authContext = useContext(AuthContext);
  const [error, setError] = useState();
  const theme = useTheme();
  const isLight = useColorMode().colorMode === "light";

  /* button statuses */
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isNewVersion, setIsNewVersion] = useState(false);

  const { code, version } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = {
    // Radio: firstCallSuggestedPlace
    firstCallSuggestedPlace:
      toxicityData.firstCallSuggestedPlace === undefined
        ? ""
        : toxicityData.firstCallSuggestedPlace,
    // Radio: firstCallLocation
    firstCallLocation:
      toxicityData.firstCallLocation === undefined
        ? ""
        : toxicityData.firstCallLocation,

    // Radio: firstCallEvaluateToxicity
    firstCallEvaluateToxicity:
      toxicityData.firstCallEvaluateToxicity === undefined
        ? ""
        : toxicityData.firstCallEvaluateToxicity,
    // Radio: firstCallPSS
    firstCallPSS:
      toxicityData.firstCallPSS === undefined ? "" : toxicityData.firstCallPSS,

    // Radio: closureEvaluateToxicity
    closureEvaluateToxicity:
      toxicityData.closureEvaluateToxicity === undefined
        ? ""
        : toxicityData.closureEvaluateToxicity,
    // Radio: finalPSS
    finalPSS: toxicityData.finalPSS === undefined ? "" : toxicityData.finalPSS,

    // CheckBox + text area: interesting
    interesting:
      toxicityData.interesting === undefined ? false : toxicityData.interesting,
    interestingDesc:
      toxicityData.interestingDesc === undefined
        ? ""
        : toxicityData.interestingDesc,
    interestingDescEditing:
      toxicityData.interestingDescEditing === undefined
        ? false
        : toxicityData.interestingDescEditing,

    // CheckBox1 Denuncia ADR
    adrComplaintDone:
      toxicityData.adrComplaintDone === undefined
        ? false
        : toxicityData.adrComplaintDone,
    adrComplaintSuggested:
      toxicityData.adrComplaintSuggested === undefined
        ? false
        : toxicityData.adrComplaintSuggested,

    // modale: Sondaggio FUP
    modalValuesList:
      toxicityData.modalValuesList === undefined
        ? [
          // { name: 'modal 1', compiled: true},
          // { name: 'modal 2', compiled: true}
        ]
        : toxicityData.modalValuesList,
  };

  const validationSchema = Yup.object().shape({
    firstCallSuggestedPlace: Yup.string(),
    firstCallLocation: Yup.string(),
    firstCallEvaluateToxicity: Yup.string(),
    firstCallPSS: Yup.string(),
    closureEvaluateToxicity: Yup.string(),
    finalPSS: Yup.string(),
    // interesting: Yup.bool(),
    interestingDesc: Yup.string(),
    compliant: Yup.string(),
  });

  useEffect(() => {
    const fetchData = async () => {
      const [respRecord] = await Promise.all([getRecordByCode(code)]);
      setRecord(respRecord.data || {});
      const data = getDataFromRecord(
        respRecord.data,
        version,
        constants.EVALUATE_TOXICITY
      );
      setToxicityData(data);
      setIsCreation(respRecord.data.code === undefined);
      setIsLoaded(true);
    };
    fetchData();
  }, []);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Box boxShadow="base" borderRadius="8px" pt="8" pb="8" px="8"
      w="full" bg={isLight ? "white" : "gray.900"}>
      <Box>
        <Text fontSize='2xl' as="b" >Valutazione Tossicità</Text>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          try {
            const author = `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`;
            if (isCreating) {
              const resp = await createRecord(record, code, version, author, {
                evaluateToxicity: values,
              });
              setError(resp.data.error);
            } else if (isUpdating) {
              const resp = await updateRecord(
                record,
                code,
                version,
                author,
                values,
                constants.EVALUATE_TOXICITY
              );
              setError(resp.data.error);
            } else if (isNewVersion) {
              const { newVersionId } = await createNewVersion(
                record,
                code,
                version,
                author
              );
              const resp = await updateRecord(
                record,
                code,
                newVersionId,
                author,
                values,
                constants.EVALUATE_TOXICITY
              );
              setError(resp.data.error);
              navigate(
                `/${location.pathname.split("/")[1]}/${code}/${newVersionId}`
              );
            }

            toast({
              title: "Record Salvato",
              description: "L'operazione è andata a buon fine",
              status: "success",
              duration: 9000,
              isClosable: true,
            });

            actions.setSubmitting(false);
            actions.setStatus({
              created: true,
            });

            setIsNewVersion(false);
            setIsCreating(false);
            setIsUpdating(false);
          } catch (er) {
            setError({
              ...er,
            });
            toast({
              title: "Errore",
              description: er,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            actions.setSubmitting(false);
          }
          setIsCreation(false);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          isSubmitting,
          isValid,
        }) => {
          return (
            <EvaluateToxicityComponent
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              values={values}
              isSubmitting={isSubmitting}
              isValid={isValid}
              error={error}
              theme={theme}
              isCreation={isCreation}
              setIsNewVersion={setIsNewVersion}
              setIsCreating={setIsCreating}
              setIsUpdating={setIsUpdating}
              userProfile={authContext.userProfile}
            />
          );
        }}
      </Formik>
    </Box>
  );
}

export default EvaluateToxicityContainer;
