import { Button, Grid, GridItem, FormLabel, Heading, useTheme } from "@chakra-ui/react";
import {
  InputControl,
  SelectControl,
  CheckboxSingleControl,
} from "formik-chakra-ui";
import {
  changeStringArrayForMultiselect,
  setMultiOptions,
  showListSaveDesc,
} from "../../../../helpers";
import { Select } from "chakra-react-select";
import { FaSave } from "react-icons/fa";

function DrugsComponent({
  handleSubmit,
  isSubmitting,
  setFieldValue,
  values,
  isValid,
  dirty,
  isValidCommonForm,
  dirtyCommonForm,
  isCreation,
  isAdding,
  setIsCreating,
  setIsUpdating,
  assumingModeListFiltered,
  agentSubcategories,
  assumingTypes,
}) {
  const theme = useTheme();

  return (
    <form onSubmit={handleSubmit}>
      <Grid templateColumns="repeat(10, 1fr)" columnGap={6} rowGap={6} mt={6}>


        {/* Nome Commerciale */}
        <GridItem colSpan={5}>
          <InputControl name="commercialName" label="Nome commerciale" />
        </GridItem>


        {/* Codifica */}
        <GridItem colSpan={3}>
          <InputControl name="coding" label="Codifica" />
        </GridItem>


        {/* Flag terapia */}
        <GridItem colSpan={2}>
          <FormLabel htmlFor="agentSubcategory">Indicazione terapia</FormLabel>
          <CheckboxSingleControl name="isTherapy">
            Soggetto in terapia
          </CheckboxSingleControl>
        </GridItem>

        {/* Produttore */}
        <GridItem colSpan={3}>
          <InputControl name="maker" label="Produttore" />
        </GridItem>
        {/* Categoria d'uso */}
        <GridItem colSpan={2}>
          <InputControl name="useCategory" label="Categoria d'uso" />
        </GridItem>


        {/* Forma di assunzione del farmaco */}
        <GridItem colSpan={5}>
          <FormLabel htmlFor="assumingType">
            Modalità assunzione farmaco
          </FormLabel>
          <SelectControl name="assumingType">
            {assumingTypes.map(showListSaveDesc)}
          </SelectControl>
        </GridItem>


        {/* Composizione */}
        <GridItem colSpan={3}>
          <InputControl name="composition" label="Composizione" />
        </GridItem>



        {/* Quantità */}
        <GridItem colSpan={2}>
          <InputControl name="quantity" label="Quantità" />
        </GridItem>

        {/* Via di assunzione
        <GridItem colSpan={5}>
          <Heading as="h5" size="sm" pb="2">
            Via di assunzione
          </Heading>
          <Select
            name="assumingMode"
            isMulti
            closeMenuOnSelect={false}
            placeholder="Seleziona"
            options={changeStringArrayForMultiselect(
              assumingModeListFiltered.map((a) => a)
            )}
            value={changeStringArrayForMultiselect(values.assumingMode)}
            onChange={(options) => {
              setFieldValue("assumingMode", setMultiOptions(options));
            }}
          />
        </GridItem> */}

      </Grid>
      {/* Bottoni */}
      <Grid w="100%" templateColumns="repeat(6, 1fr)" mt={12} >
        <GridItem colStart={6}>
          {isCreation ? (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsCreating(true);
              }}
            >
              Salva Scheda
            </Button>
          ) : (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsUpdating(true);
              }}
            >
              Modifica Scheda
            </Button>
          )
          }
        </GridItem >
      </Grid >
    </form>
  );
}

export default DrugsComponent;
