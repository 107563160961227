import {
  FormLabel,
  Grid,
  GridItem,
  Flex,
  Box,
  Heading,
  Spacer,
  Button,
} from "@chakra-ui/react";
import { SelectControl, InputControl } from "formik-chakra-ui";
import { TiArrowBack } from "react-icons/ti";
import { showListSaveDesc } from "../../../../helpers";
import DatePickerAgent from "../../../Form/DatePickerAgent";
import DynamicForm from "./DynamicForm";

function TreatmentsFormComponent({
  values,
  isSubmitting,
  isValid,
  dirty,
  data,
  isCreation,
  isAdding,
  record,
  setIsCreation,
  setIsSelected,
  setIsAdding,
  formEntity,
  dynamicInitialValues,
  queryClient
}) {
  return (
    <>
      <Flex>
        <Box pt={2}>
          <Heading fontSize='2xl' as="b" >
            Scheda Trattamento
          </Heading>
        </Box>
        <Spacer />
        <Box pt={2} pr={4}>
          <Button
            rightIcon={<TiArrowBack />}
            onClick={() => {
              setIsCreation(false);
              setIsSelected(false);
              setIsAdding(false);
            }}
          >
            Torna alla lista
          </Button>
        </Box>
      </Flex>
      <form>
        <Grid templateColumns="repeat(10, 1fr)" columnGap={6} rowGap={6} mt={6}>
          {/* Tipologia Trattamento */}
          <GridItem colSpan={4}>
            <FormLabel htmlFor="type">Tipo Trattamento</FormLabel>
            <SelectControl name="type">
              {[
                "Seleziona",
                "Trattamento Suggerito",
                "Trattamento Eseguito",
              ].map(showListSaveDesc)}
            </SelectControl>
          </GridItem>

          {/* Data creazione */}
          <GridItem colSpan={3}>
            <FormLabel htmlFor="category">Data Creazione</FormLabel>
            <DatePickerAgent name="creationDate" />
          </GridItem>

          {/* Utente che inserisce i dati */}
          <GridItem colSpan={3}>
            <InputControl
              name="user"
              label="Operatore"
              inputProps={{ isDisabled: true }}
            />
          </GridItem>
        </Grid>
      </form>

      <DynamicForm
        commonData={values}
        isSubmittingCommonForm={isSubmitting}
        isValidCommonForm={isValid}
        dirtyCommonForm={dirty}
        data={data}
        isCreation={isCreation}
        isAdding={isAdding}
        record={record}
        setIsCreation={setIsCreation}
        setIsSelected={setIsSelected}
        setIsAdding={setIsAdding}
        formEntity={formEntity}
        initialValues={dynamicInitialValues}
        queryClient={queryClient}
      />
    </>
  );
}

export default TreatmentsFormComponent;
