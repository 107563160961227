import {
  FormControl,
  FormLabel,
  HStack,
  Button,
  VStack,
  Box,
} from "@chakra-ui/react";
import { AiFillStar } from "react-icons/ai";
import {
  InputControl,
  NumberInputControl,
  CheckboxSingleControl,
} from "formik-chakra-ui";
import {
  changeStringArrayForMultiselect,
  setMultiOptions,
} from "../../../helpers";
import React from "react";
import DatePickerRange from "../../Form/DatePickerRange";
import { Formik } from "formik";
import { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContextProvider";
import * as Yup from "yup";
import { errors } from "../../Form";
const { OB } = errors;
import { Select } from "chakra-react-select";

function FilterFormComponent({
  addFilter,
  selectedItem,
  agentCategories,
  tagsArray,
}) {
  /* form statuses */
  const [error, setError] = useState();
  const authContext = useContext(AuthContext);

  const initialValues = {
    presetName: selectedItem ? selectedItem.presetName : "",
    record_id: selectedItem ? selectedItem.record_id : "",
    user: selectedItem ? selectedItem.user : "",
    patient: selectedItem ? selectedItem.patient : "",
    startCreationDate: selectedItem ? selectedItem.startCreationDate : "",
    endCreationDate: selectedItem ? selectedItem.endCreationDate : "",
    startLastUpdateDate: selectedItem ? selectedItem.startLastUpdateDate : "",
    endLastUpdateDate: selectedItem ? selectedItem.endLastUpdateDate : "",
    city: selectedItem ? selectedItem.city : "",
    province: selectedItem ? selectedItem.province : "",
    region: selectedItem ? selectedItem.region : "",
    agent: selectedItem ? selectedItem.agent : [],
    status: selectedItem ? selectedItem.status : ["Tutte"],
    tags: selectedItem ? selectedItem.tags : [],
    errands: selectedItem ? selectedItem.errands : false,
    isPublic: selectedItem ? selectedItem.isPublic : true,
    email: authContext.userProfile.email,
  };

  const validationSchema = Yup.object().shape({
    presetName: Yup.string().required(OB),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          addFilter.mutate(values);
        } catch (er) {
          setError({
            ...er,
          });

          actions.setSubmitting(false);
        }
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values,
        isSubmitting,
        isValid,
        dirty,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box p={4} shadow="md" borderWidth="1px" mt={2}>
              <HStack spacing={8}>
                <InputControl name="presetName" label="Nome Query" />
              </HStack>
              <HStack spacing={8} mt={4}>
                <NumberInputControl
                  name="record_id"
                  label="ID"
                  numberInputProps={{ min: 3000000 }}
                />

                <InputControl name="user" label="Utente" />

                <InputControl name="patient" label="Paziente" />
              </HStack>
              <HStack spacing={8} mt={4}>
                <VStack spacing={2} w="33%" align="left">
                  <FormLabel>Data Creazione (Da - a)</FormLabel>
                  <DatePickerRange
                    name="creationDateRange"
                    startName="startCreationDate" //data inizio del range
                    endName="endCreationDate" //data fine del range
                    selectsRange={true}
                    values={values}
                  />
                </VStack>

                <VStack spacing={2} w="33%" align="left">
                  <FormLabel>Ultimo Aggiornamento (Da - a)</FormLabel>
                  <DatePickerRange
                    name="LastUpdateDateRange"
                    startName="startLastUpdateDate"
                    endName="endLastUpdateDate"
                    selectsRange={true}
                    values={values}
                  />
                </VStack>

                <VStack spacing={2} w="33%" align="left">
                  <FormLabel htmlFor="status">Status</FormLabel>
                  <Select
                    name="status"
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Seleziona gli stati"
                    className="chakra-multiselect"
                    classNamePrefix="react-multiselect"
                    options={changeStringArrayForMultiselect([
                      "Tutte",
                      "Aperta",
                      "Chiusa",
                      "Archiviata",
                      "Follow-Up",
                    ])}
                    value={changeStringArrayForMultiselect(values.status)}
                    onChange={(options) => {
                      setFieldValue("status", setMultiOptions(options));
                    }}
                  />
                </VStack>
              </HStack>
              <HStack spacing={8} mt={4}>
                <VStack spacing={2} w="50%" align="left">
                  <FormLabel htmlFor="status">Lista agenti</FormLabel>
                  <Select
                    name="agent"
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Seleziona gli agenti"
                    className="chakra-multiselect"
                    classNamePrefix="react-multiselect"
                    options={changeStringArrayForMultiselect(
                      agentCategories.map((a) => a.desc)
                    )}
                    value={changeStringArrayForMultiselect(values.agent)}
                    onChange={(options) => {
                      setFieldValue("agent", setMultiOptions(options));
                    }}
                  />
                </VStack>
                <VStack spacing={2} w="50%" align="left">
                  <FormLabel htmlFor="status">Tag</FormLabel>
                  <Select
                    name="tags"
                    isMulti
                    className="chakra-multiselect"
                    classNamePrefix="react-multiselect"
                    closeMenuOnSelect={false}
                    placeholder="Seleziona i tag"
                    options={changeStringArrayForMultiselect(
                      tagsArray.map((a) => a.desc)
                    )}
                    value={changeStringArrayForMultiselect(values.tags)}
                    onChange={(options) => {
                      setFieldValue("tags", setMultiOptions(options));
                    }}
                  />
                </VStack>
              </HStack>

              <HStack spacing={8} mt={4}>
                <VStack spacing={2} w="20%" align="left">
                  <InputControl name="city" label="Città" />
                </VStack>
                <VStack spacing={2} w="20%" align="left">
                  <InputControl name="province" label="Provincia" />
                </VStack>
                <VStack spacing={2} w="20%" align="left">
                  <InputControl name="region" label="Regione" />
                </VStack>
                <HStack spacing={2} w="40%" align="left" mt={4}>
                  <CheckboxSingleControl
                    name="errands"
                    label="Solo Record con Consegne"
                  />
                  <CheckboxSingleControl
                    name="isPublic"
                    label="Pubblico"
                    checkBoxProps={{ defaultChecked: true }}
                  />
                </HStack>
              </HStack>

              <HStack spacing={8} mt={4}>
                <FormControl align="right">
                  <Button
                    type="submit"
                    rightIcon={<AiFillStar />}
                    colorScheme="blue"
                    variant="outline"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    Salva Query
                  </Button>
                </FormControl>
              </HStack>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

export default FilterFormComponent;
