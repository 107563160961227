import { FormLabel, Grid, GridItem } from "@chakra-ui/react";
import { SelectControl } from "formik-chakra-ui";
import { showList } from "../../helpers";
import AnimalData from "./AnimalData";
import HumanData from "./HumanData";

function PatientDataComponent({ values, types, patientData }) {
  return (
    <Grid w="100%" templateColumns="repeat(6, 1fr)" columnGap={6} rowGap={6}>
      <GridItem colSpan={6}>
        <form>
          <FormLabel htmlFor="idType">Tipo paziente</FormLabel>
          <SelectControl name="idType">{types.map(showList)}</SelectControl>
        </form>
        {values.idType === "1" && (
          <HumanData idType={values} patientData={patientData} />
        )}
        {values.idType === "2" && (
          <AnimalData idType={values} patientData={patientData} />
        )}
      </GridItem>
    </Grid >
  );
}

export default PatientDataComponent;
