import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  GridItem,
  Radio,
} from "@chakra-ui/react";
import {
  NumberInputControl,
  RadioGroupControl,
  SelectControl,
  TextareaControl,
} from "formik-chakra-ui";
import { isAdmin, showList } from "../../../helpers";
import { FaSave } from "react-icons/fa";

function AnimalDataComponent({
  handleSubmit,
  isSubmitting,
  isValid,
  species,
  isCreation,
  setIsCreating,
  setIsUpdating,
  setIsNewVersion,
  userProfile,
  theme
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid templateColumns="repeat(6, 1fr)" columnGap={6} rowGap={6} mt={6}>
        {/* Sesso */}
        <GridItem colSpan={3}>
          <RadioGroupControl name="sex" label="Sesso">
            <Radio value="Maschio">Maschio</Radio>
            <Radio value="Femmina">Femmina</Radio>
            <Radio value="Non noto">Non noto</Radio>
          </RadioGroupControl>
        </GridItem>

        {/* Specie */}
        <GridItem colSpan={3}>
          <FormLabel htmlFor="idSpecies">Specie</FormLabel>
          <SelectControl name="idSpecies" >
            {species.map(showList)}
          </SelectControl>
        </GridItem>


        {/* Età */}
        <GridItem colSpan={3}>
          <RadioGroupControl name="ageDesc" label="Informazioni sull'età">
            <Radio value="Adulto">Adulto</Radio>
            <Radio value="Cucciolo">Cucciolo</Radio>
          </RadioGroupControl>
        </GridItem>
        <GridItem colSpan={3}>
          <NumberInputControl name="age" label="Età" />
        </GridItem>

        {/* Descrizione animale */}
        <GridItem colSpan={6}>
          <TextareaControl
            name="animalDescription"
            label="Descrizione Animale"

          />
        </GridItem>

        {/* Descrizione evento */}
        <GridItem colSpan={6}>
          <TextareaControl name="eventDescription" label="Descrizione Evento" />
        </GridItem>
      </Grid>
      <Grid w="100%" templateColumns="repeat(6, 1fr)" mt={12} >
        <GridItem colStart={6}>
          {/* Bottoni */}
          {isCreation ? (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsCreating(true);
              }}
            >
              Salva Scheda
            </Button>
          ) : (
            <ButtonGroup>
              <Button
                type="submit"
                leftIcon={<FaSave />}
                background={theme.colors.primary.main}
                color={'white'}
                disabled={isSubmitting || !isValid}
                onClick={() => {
                  return setIsUpdating(true);
                }}
              >
                Modifica Scheda
              </Button>
            </ButtonGroup>
          )}
        </GridItem>
      </Grid>
    </form>
  );
}

export default AnimalDataComponent;
