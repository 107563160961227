import { Avatar, Box, HStack, Text } from "@chakra-ui/react";
import { MdOutlinePets } from "react-icons/md";
import { FiUser } from "react-icons/fi";

function renderIcon(idType) {
  if (idType === "2") {
    return <MdOutlinePets fontSize="2xl" />;
  }
  return <FiUser fontSize="2xl" />;
}

function renderAnimalProps(patient, animalData) {
  return (
    <>
      <Text size="xl" fontWeight="bold">Specie: {animalData.filter(a => {
        return a.id.toString() === patient.idSpecies;
      })[0].description}</Text>
      <Text size="md">{patient.sex} &middot; {patient.age} Anni</Text>
    </>
  );
}

function renderPatientProps(patient) {
  return (
    <>
      <Text size="xl" fontWeight="bold">{patient.name} {patient.surname}</Text>
      <Text size="md">{patient.sex} &middot; {patient.age.years} Anni</Text>
      <Text size="md">{patient.weight} kg &middot; {patient.height} cm</Text>
    </>
  );
}


function RecordInfoComponent({ record, animal }) {
  const { patientData } = record;

  return (
    <HStack spacing={8} justify="left" px={8} mt={4} mb={4}>
      <Box>
        <Avatar size="md" bg="green.400" icon={renderIcon(patientData.idType)} />
      </Box>
      <Box>
        {animal !== undefined && patientData.idType === "2" ?
          (renderAnimalProps(patientData, animal)) : (renderPatientProps(patientData))
        }
      </Box>
    </HStack>
  );
}

RecordInfoComponent.propTypes = {};

RecordInfoComponent.defaultProps = {};

export default RecordInfoComponent;
