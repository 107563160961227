import styles from './Loader.module.css';

function LoaderComponent() {
  return (
    <div className={`${styles.lds}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default LoaderComponent;
