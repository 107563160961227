import { useMemo, Fragment } from "react";
import {
  VStack,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  useTheme,
} from "@chakra-ui/react";
import { useTable, useGlobalFilter, useSortBy, useExpanded } from "react-table";
import { matchSorter } from "match-sorter";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";

function SymptomsComponent({ columns, data }) {
  const theme = useTheme();

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, {
      keys: [
        (row) => {
          return row.values[id];
        },
      ],
    });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => {
    return !val;
  };

  const filterTypes = useMemo(() => {
    return {
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    };
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useGlobalFilter,
      useSortBy,
      useExpanded
    );

  return (
    <VStack spacing={8}>
      <Container maxW="container.ls">
        <Table
          {...getTableProps()}
          size="md"
          variant="simple"
          borderWidth="1px"
        >
          <Thead>
            {headerGroups.map((headerGroup) => {
              const { rowKey, ...restHeaderGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <Tr
                  key={rowKey}
                  {...restHeaderGroupProps}
                  bg={theme.colors.primary.main}
                  px={4}
                >
                  {headerGroup.headers.map((column) => {
                    const { colKey, ...restColumn } = column.getHeaderProps(
                      column.getSortByToggleProps()
                    );
                    return (
                      <Th key={colKey} {...restColumn} color="white">
                        {column.render("Header")}
                        <chakra.span pl="4">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { rowKey, ...restRowProps } = row.getRowProps();
              return (
                <Fragment key={rowKey}>
                  <Tr key={rowKey} {...restRowProps}>
                    {row.cells.map((cell) => {
                      const { cellKey, ...restCellProps } = cell.getCellProps();
                      return (
                        <Td key={cellKey} {...restCellProps}>
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                </Fragment>
              );
            })}
          </Tbody>
        </Table>
      </Container>
    </VStack>
  );
}

export default SymptomsComponent;
