import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import AgentsFormComponent from "./AgentsForm.component";
import {
  addDefaultOption,
  createInitialValues,
  getArrayElementID,
  getSubCategoryLabel,
  getOptGroupsMenuNames,
  prepareOptions,
  prepareOptionsWithoutId,
} from "../../../helpers";
import {
  getAllExposureMode,
  getAllExposurePlace,
  getAllExposureType,
  getFormsEntityByCode,
} from "../../../api";
import { errors } from "../../Form";
import { DEFAULT_ID } from "../../../constants";
import Loader from "../../Loader";

const { OB, SELECT } = errors;

function AgentsFormContainer({
  isCreation,
  isAdding,
  setIsCreation,
  setIsSelected,
  setIsAdding,
  data,
  record,
  createRecord,
  updateArrayElementInRecord,
  addArrayElementToRecord,
  theme
}) {
  const [agentCategories, setAgentCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(999);
  const [menuNames, setMenuNames] = useState([]);
  const [exposureTypes, setExposureTypes] = useState([]);
  const [exposurePlaces, setExposurePlaces] = useState([]);
  const [exposureModes, setExposureModes] = useState([]);
  const [formEntity, setFormEntity] = useState();
  const isLight = useColorMode().colorMode === "light";
  const [agents, setAgents] = useState([])
  const [isLoaded, setIsLoaded] = useState(false);
  const [dynamicInitialValues, setDynamicInitialValues] = useState();

  const initialValues = {
    ArrayElementID:
      data.ArrayElementID === undefined
        ? getArrayElementID()
        : data.ArrayElementID,
    category: data.category === undefined ? DEFAULT_ID : data.category,
    categoryId: categoryId,
    creationDate:
      data.creationDate === undefined
        ? new Date()
        : new Date(data.creationDate),
    exposureType:
      data.exposureType === undefined ? DEFAULT_ID : data.exposureType,
    exposurePlace:
      data.exposurePlace === undefined ? DEFAULT_ID : data.exposurePlace,
    exposureMode:
      data.exposureMode === undefined ? DEFAULT_ID : data.exposureMode,
    timePassed: data.timePassed === undefined ? "" : data.timePassed,
    notes: data.notes === undefined ? "" : data.notes,
  };

  const validationSchema = Yup.object().shape({
    category: Yup.string().test("section", SELECT, (val) => {
      return val !== DEFAULT_ID;
    }),
    exposureType: Yup.string().test("section", SELECT, (val) => {
      return val !== DEFAULT_ID;
    }),
    exposurePlace: Yup.string().test("section", SELECT, (val) => {
      return val !== DEFAULT_ID;
    }),
    exposureMode: Yup.string().test("section", SELECT, (val) => {
      return val !== DEFAULT_ID;
    })
  });

  useEffect(() => {
    const fetchData = async () => {
      const [
        respExposureType,
        respExposureMode,
        respExposurePlace,
        respFormEntity,
      ] = await Promise.all([
        getAllExposureType(),
        getAllExposureMode(),
        getAllExposurePlace(),
        getFormsEntityByCode("agent"),
      ]);
      setAgentCategories(getSubCategoryLabel(respFormEntity.data));
      setMenuNames(getOptGroupsMenuNames(respFormEntity.data));
      setAgents(respFormEntity.data.subCategory)
      setExposureTypes(respExposureType.data);
      setExposureModes(respExposureMode.data);
      setExposurePlaces(respExposurePlace.data);
      setFormEntity(respFormEntity.data);
      if (data.category !== undefined) {
        setDynamicInitialValues(
          createInitialValues(respFormEntity.data, "agent", data, data.category)
        );
      }
      setIsLoaded(true);
    };
    fetchData();
  }, []);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Box boxShadow="base" borderRadius="8px" pt="8" pb="8" px="8"
      w="full" bg={isLight ? "white" : "gray.900"}>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ values, isSubmitting, isValid, dirty }) => {
          return (
            <AgentsFormComponent
              values={values}
              isSubmitting={isSubmitting}
              isValid={isValid}
              dirty={dirty}
              agentCategories={prepareOptionsWithoutId(agentCategories)}
              exposureTypes={prepareOptions(addDefaultOption(exposureTypes))}
              exposurePlaces={prepareOptions(addDefaultOption(exposurePlaces))}
              exposureModes={prepareOptions(addDefaultOption(exposureModes))}
              data={data}
              isCreation={isCreation}
              isAdding={isAdding}
              record={record}
              setIsCreation={setIsCreation}
              setIsSelected={setIsSelected}
              setIsAdding={setIsAdding}
              formEntity={formEntity}
              dynamicInitialValues={dynamicInitialValues}
              createRecord={createRecord}
              addArrayElementToRecord={addArrayElementToRecord}
              updateArrayElementInRecord={updateArrayElementInRecord}
              theme={theme}
              menuNames={menuNames}
              agents={agents}
              setCategoryId={setCategoryId}
              categoryId={categoryId}
            />
          );
        }}
      </Formik>
    </Box>
  );
}

export default AgentsFormContainer;
