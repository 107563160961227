import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Box, Text, useColorMode } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import PatientDataComponent from "./PatientData.component";
import { getDataFromRecord, prepareOptions } from "../../helpers";
import { getRecordByCode } from "../../api";
import Loader from "../Loader";

function PatientDataContainer() {
  const [types, setTypes] = useState([]);
  const [patientData, setPatientData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const { code, version } = useParams();
  const isLight = useColorMode().colorMode === "light";

  const initialValues = {
    idType: patientData.idType === undefined ? "1" : patientData.idType
  };

  const validationSchema = Yup.object().shape({
    idType: Yup.string().default("1")
  });

  // setto le opzioni per tipo paziente in modo fittizio (dovranno essere prese da db)
  useEffect(() => {
    const fetchData = async () => {
      const [respRecord] = await Promise.all([getRecordByCode(code)]);
      setTypes([
        { id: 1, description: "Uomo", isPublished: true },
        { id: 2, description: "Animale", isPublished: true }
      ]);
      setPatientData(getDataFromRecord(respRecord.data, version, "patientData"));
      setIsLoaded(true);
    };
    fetchData();
  }, []);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Box boxShadow="base" borderRadius="8px" pt="8" pb="8" px="8"
      w="full" bg={isLight ? "white" : "gray.900"}>
      <Box mb={4}>
        <Text fontSize='2xl' as="b" >Dati del Paziente</Text>
      </Box>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ handleSubmit, handleChange, values, isSubmitting, isValid }) => {
          return (
            <PatientDataComponent
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              values={values}
              isSubmitting={isSubmitting}
              isValid={isValid}
              types={prepareOptions(types)}
              patientData={patientData}
            />
          );
        }}
      </Formik>
    </Box>
  );
}

export default PatientDataContainer;
