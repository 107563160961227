import { Formik } from "formik";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { Box, useDisclosure } from "@chakra-ui/react";
import DiagnosisFormComponent from "./DiagnosisForm.component";
import { getArrayElementID } from "../../../helpers";
import {
  addArrayElementToRecord,
  createRecord,
  updateArrayElementInRecord,
} from "../../../api";
import { errors } from "../../Form";
import { AuthContext } from "../../../context/AuthContextProvider";
import * as constants from "../../../constants";
import { useToast } from "@chakra-ui/toast";
import { useParams } from "react-router-dom";

const { OB } = errors;

function DiagnosisFormContainer({
  isCreation,
  isAdding,
  setIsCreation,
  setIsSelected,
  setIsAdding,
  data,
  record,
  queryClient
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const authContext = useContext(AuthContext);
  const { code, version } = useParams();

  const toast = useToast();
  const [error, setError] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const initialValues = {
    ArrayElementID:
      data.ArrayElementID === undefined
        ? getArrayElementID()
        : data.ArrayElementID,
    creationDate:
      data.creationDate === undefined
        ? new Date()
        : new Date(data.creationDate),
    user:
      data.user === undefined
        ? `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`
        : data.user,
    diagnosisInfo:
      data.diagnosisInfo === undefined
        ? { description: "", code: "" }
        : data.diagnosisInfo,
    notes: data.notes === undefined ? "" : data.notes,
  };

  const validationSchema = Yup.object().shape({
    creationDate: Yup.string().required(OB),
    user: Yup.string(),
    diagnosisInfo: Yup.object(),
  });

  /*  if (!isLoaded) {
    return <Loader />;
  }*/

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          try {
            const author = `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`;
            if (isCreating) {
              const resp = await createRecord(record, code, version, author, {
                diagnosisData: [values],
              });
              queryClient.invalidateQueries(['respDiagnosisRecord'])
              setError(resp.data.error);
              toast({
                title: "Record Salvato",
                description: "L'operazione è andata a buon fine",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
            } else if (isAdding) {
              const resp = await addArrayElementToRecord(
                record,
                code,
                version,
                author,
                values,
                constants.DIAGNOSIS
              );
              queryClient.invalidateQueries(['respDiagnosisRecord'])
              setError(resp.data.error);
              toast({
                title: "L'agente è stato aggiunto con successo",
                description: "L'operazione è andata a buon fine",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
            }else if (isUpdating) {
              const resp = await updateArrayElementInRecord(
                record,
                code,
                version,
                author,
                values,
                constants.DIAGNOSIS
              );
              queryClient.invalidateQueries(['respDiagnosisRecord'])
              setError(resp.data.error);
              toast({
                title: "L'agente è stato modificato con successo",
                description: "L'operazione è andata a buon fine",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
            }

            actions.setSubmitting(false);
            actions.setStatus({
              created: true,
            });

            setIsCreating(false);
            setIsUpdating(false);
          } catch (er) {
            setError({
              ...er,
            });
            toast({
              title: "Errore",
              description: er,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            actions.setSubmitting(false);
          }
          setIsCreation(false);
          setIsSelected(false);
          setIsAdding(false);
        }}
      >
        {({ handleSubmit, setFieldValue, isSubmitting, isValid, dirty }) => {
          return (
            <DiagnosisFormComponent
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              isValid={isValid}
              dirty={dirty}
              setFieldValue={setFieldValue}
              setIsCreation={setIsCreation}
              setIsSelected={setIsSelected}
              setIsAdding={setIsAdding}
              modalIsOpen={isOpen}
              modalOnOpen={onOpen}
              modalOnClose={onClose}
              isCreation={isCreation}
              isAdding={isAdding}
              setIsUpdating={setIsUpdating}
              setIsCreating={setIsCreating}
              error={error}
            />
          );
        }}
      </Formik>
    </Box>
  );
}

export default DiagnosisFormContainer;
