import { createContext, useState, useEffect, useMemo } from 'react';
import Keycloak from 'keycloak-js';

export const AuthContext = createContext();

const keycloakInitOptions = {
  onLoad: 'login-required',
};

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL || 'http://localhost:28080/auth',
  realm: process.env.REACT_APP_KEYCLOAK_REALM || 'cav',
  clientId: 'react',
  responseType: 'code',
});

function AuthContextProvider({ ...props }) {

  const [isAuthenticated, setAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [token, setToken] = useState('')

  const logout = () => {
    keycloak.logout();
  };

  useEffect(() => {
    async function initializeKeycloak() {
      try {
        const isAuthenticatedResponse = await keycloak.init(keycloakInitOptions);

        if (!isAuthenticatedResponse) {
          keycloak.login();
        }
        setAuthenticated(isAuthenticatedResponse);
      } catch {
        setAuthenticated(false);
      }
    }
    initializeKeycloak();
  }, []);

  useEffect(() => {
    /**
     * Load the profile for of the user from Keycloak
     */
    async function loadProfile() {
      try {
        const profile = await keycloak.loadUserProfile();
        setUserProfile(profile);
        setToken(keycloak.token)
        // console.log(keycloak.token)
      } catch {
        // eslint-disable-next-line no-console
        console.log('error trying to load the user profile');
      }
    }

    // Only load the profile if a user is authenticated
    if (isAuthenticated) {
      loadProfile();

    }
  }, [isAuthenticated]);

  const values = useMemo(() => {
    return { isAuthenticated, logout, userProfile, token };
  }, [isAuthenticated, logout, userProfile, token]);

  return <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>;
}

export default AuthContextProvider;
