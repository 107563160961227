import {
  FormLabel,
  Grid,
  GridItem,
  Flex,
  Box,
  Heading,
  Spacer,
  Button,
  Radio,
} from "@chakra-ui/react";
import { InputControl, RadioGroupControl } from "formik-chakra-ui";
import { TiArrowBack } from "react-icons/ti";
import DatePickerAgent from "../../../Form/DatePickerAgent";
import DynamicForm from "./DynamicForm";

function SymptomsFormComponent({
  values,
  isSubmitting,
  isValid,
  dirty,
  data,
  isCreation,
  isAdding,
  record,
  setIsCreation,
  setIsSelected,
  setIsAdding,
  formEntity,
  dynamicInitialValues,
  queryClient
}) {
  return (
    <>
      <Flex>
        <Box pt={2}>
          <Heading fontSize='2xl' as="b" >
            Scheda sintomi
          </Heading>
        </Box>
        <Spacer />
        <Box pt={2} pr={4}>
          <Button
            rightIcon={<TiArrowBack />}
            onClick={() => {
              setIsCreation(false);
              setIsSelected(false);
              setIsAdding(false);
            }}
          >
            Torna alla lista
          </Button>
        </Box>
      </Flex>
      <form>
        <Grid templateColumns="repeat(10, 1fr)" columnGap={6} rowGap={6} mt={6}>
          {/* Data creazione */}
          <GridItem colSpan={5}>
            <FormLabel htmlFor="category">Data Creazione</FormLabel>
            <DatePickerAgent name="creationDate" />
          </GridItem>

          {/* Modalità */}
          <GridItem colSpan={5}>
            <RadioGroupControl name="modality" label="Modalità">
              <Radio value="In entrata">In entrata</Radio>
              <Radio value="In uscita">In uscita</Radio>
            </RadioGroupControl>
          </GridItem>

          {/* Interlocutore */}
          <GridItem colSpan={5}>
            <InputControl name="speaker" label="Interlocutore" />
          </GridItem>

          {/* Utente che inserisce i dati */}
          <GridItem colSpan={5}>
            <InputControl
              name="user"
              label="Operatore"
              inputProps={{ isDisabled: true }}
            />
          </GridItem>
        </Grid>
      </form>

      <DynamicForm
        commonData={values}
        isSubmittingCommonForm={isSubmitting}
        isValidCommonForm={isValid}
        dirtyCommonForm={dirty}
        data={data}
        isCreation={isCreation}
        isAdding={isAdding}
        record={record}
        setIsCreation={setIsCreation}
        setIsSelected={setIsSelected}
        setIsAdding={setIsAdding}
        formEntity={formEntity}
        initialValues={dynamicInitialValues}
        queryClient={queryClient}
      />
    </>
  );
}

export default SymptomsFormComponent;
