import { useState, useMemo } from "react";
import {
    Box,
    Input,
    InputGroup,
    InputLeftAddon,
    Select
} from "@chakra-ui/react";
import { useAsyncDebounce } from "react-table";
import { FiSearch } from "react-icons/fi";


export function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter
}) {
    const [value, setValue] = useState(globalFilter);
    // eslint-disable-next-line no-shadow
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 200);
    const count = preGlobalFilteredRows.length;

    return (
        <Box bg="white" borderRadius="md">
            <InputGroup>
                <InputLeftAddon color="gray.600" bg="gray.200">
                    <FiSearch />
                </InputLeftAddon>

                <Input
                    value={value || ""}
                    color="black"
                    onChange={(e) => {
                        setValue(e.target.value || undefined);
                        onChange(e.target.value);
                    }}
                    placeholder={`Filtra ${count} risultati`}
                />
            </InputGroup>
        </Box>

    );
}

// Component for Default Column Filter
export function DefaultFilterForColumn({
    column: {
        filterValue,
        setFilter,
    },
}) {
    return (
        <Input
            value={filterValue || ""}
            color="black"
            bg="white"
            size="sm"
            onChange={(e) => {
                // Set undefined to remove the filter entirely
                setFilter(e.target.value || undefined);
            }}
            placeholder={`Cerca`}
            style={{ marginTop: "10px" }}
        />
    );
}

// Component for Custom Select Filter
export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {

    // Use preFilteredRows to calculate the options
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // UI for Multi-Select box
    return (
        <Select
            bg="white"
            color="black"
            size="sm"
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            mt={"4px"}
        >
            <option value="">Tutto</option>
            {
                options.map((option, i) => (
                    <option key={i} value={option}>
                        {option}
                    </option>
                ))
            }
        </Select >
    );
}