import { Route, Routes } from "react-router-dom";
import * as routes from "./routes";
import * as roles from "./roles";
import Home from "../components/Home";
import PatientData from "../components/PatientData";
import ApplicantData from "../components/ApplicantData";
import RecordDetails from "../components/RecordDetails";
import EvaluateToxicity from "../components/EvaluateToxicity";
import AgentsData from "../components/Agents";
import RecordInfo from "../components/RecordInfo";
import Errands from "../components/Errands";
import Forbidden from "../components/Forbidden";
import Attachments from "../components/Attachments";
import Tag from "../components/Tag";
import AuthWrapper from "./AuthWrapper";
import FiltersPresets from "../components/FiltersPresets";
import FormsManagement from "../components/FormsManagement";
import SectionSTE from "../components/SectionSTE";
import Symptoms from "../components/SectionSTE/Symptoms";
import Treatments from "../components/SectionSTE/Treatments";
import Exams from "../components/SectionSTE/Exams";
import Diagnosis from "../components/Diagnosis";

export function AppRoutes() {
  return (
    <Routes>
      <Route path={routes.HOME} element={<Home />} />
      <Route path={routes.RECORD_DETAILS} element={<RecordDetails />} />
      <Route path={routes.PATIENT_DATA} element={<PatientData />} />
      <Route path={routes.APPLICANT_DATA} element={<ApplicantData />} />
      <Route path={routes.EVALUATE_TOXICITY} element={<EvaluateToxicity />} />
      <Route path={routes.AGENTS_DATA} element={<AgentsData />} />
      <Route path={routes.RECORD_INFO} element={<RecordInfo />} />
      <Route path={routes.ERRANDS_DATA} element={<Errands />} />
      <Route path={routes.ATTACHMENTS_DATA} element={<Attachments />} />
      <Route path={routes.TAG_DATA} element={<Tag />} />
      <Route path={routes.STE_DATA} element={<SectionSTE />} />
      <Route path={routes.SYMPTOMS_DATA} element={<Symptoms />} />
      <Route path={routes.TREATMENTS_DATA} element={<Treatments />} />
      <Route path={routes.EXAMS_DATA} element={<Exams />} />
      <Route path={routes.DIAGNOSIS_DATA} element={<Diagnosis />} />
      <Route path={routes.FORBIDDEN} element={<Forbidden />} />
      /** Admin Routes **/
      <Route element={AuthWrapper([roles.ADMIN])}>
        <Route path={routes.ADMIN_PRESETS} element={<FiltersPresets />} />
        <Route path={routes.ADMIN_FORMS} element={<FormsManagement />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
