import { useContext, useState } from "react";
import AdvancedFiltersComponent from "./AdvancedFilters.component";
import { Formik } from "formik";
import { manageDate, prepareOptions } from "../../helpers";
import { AuthContext } from "../../context/AuthContextProvider";

function AdvancedFiltersContainer({
  mutationFilters,
  deleteFilters,
  clinicalRecords,
  filtersArray,
  setSelectedFilter,
  selectedFilter,
  agentCategories,
  tagsArray,
}) {
  /* form statuses */
  const [error, setError] = useState();

  /* button statuses */
  const [IsSearching, setIsSearching] = useState(false);
  const { userProfile } = useContext(AuthContext);

  /* new filter preset name */
  const [inputName, setInputName] = useState("");
  const changePresetName = (event) => setInputName(event.target.value);

  /* cambio il filtro in base al preset selezionato */
  const [selectedPresetName, setSelectedPresetName] = useState("");
  const changeSelectedPreset = (option) => {
    setSelectedFilter(manageDate(option.originalElement.filters));
    setSelectedPresetName(option.value);
  };

  const initialValues = {
    record_id: selectedFilter.record_id ? selectedFilter.record_id : "",
    user: selectedFilter.user ? selectedFilter.user : "",
    patient: selectedFilter.patient ? selectedFilter.patient : "",
    startCreationDate: selectedFilter.startCreationDate
      ? selectedFilter.startCreationDate
      : "",
    endCreationDate: selectedFilter.endCreationDate
      ? selectedFilter.endCreationDate
      : "",
    startLastUpdateDate: selectedFilter.startLastUpdateDate
      ? selectedFilter.startLastUpdateDate
      : "",
    endLastUpdateDate: selectedFilter.endLastUpdateDate
      ? selectedFilter.endLastUpdateDate
      : "",
    city: selectedFilter.city ? selectedFilter.city : "",
    province: selectedFilter.province ? selectedFilter.province : "",
    region: selectedFilter.region ? selectedFilter.region : "",
    agent: selectedFilter.agent ? selectedFilter.agent : [],
    status: selectedFilter.status ? selectedFilter.status : ["Tutte"],
    tags: selectedFilter.tags ? selectedFilter.tags : [],
    errands: selectedFilter.errands ? selectedFilter.errands : false,
    isPublic: selectedFilter.isPublic ? selectedFilter.isPublic : false,
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        try {
          if (IsSearching) {
            setSelectedFilter(values);
          }
          setIsSearching(false);
        } catch (er) {
          setError({
            ...er,
          });
          actions.setSubmitting(false);
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        isSubmitting,
        isValid,
        resetForm,
      }) => {
        return (
          <AdvancedFiltersComponent
            userProfile={userProfile}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={values}
            isSubmitting={isSubmitting}
            isValid={isValid}
            error={error}
            setIsSearching={setIsSearching}
            clinicalRecords={clinicalRecords}
            filtersArray={filtersArray}
            changeSelectedPreset={changeSelectedPreset}
            setSelectedFilter={setSelectedFilter}
            resetForm={resetForm}
            selectedPresetName={selectedPresetName}
            setSelectedPresetName={setSelectedPresetName}
            mutationFilters={mutationFilters}
            deleteFilters={deleteFilters}
            agentCategories={prepareOptions(agentCategories)}
            tagsArray={prepareOptions(tagsArray)}
            changePresetName={changePresetName}
            setInputName={setInputName}
            inputName={inputName}
          />
        );
      }}
    </Formik>
  );
}

export default AdvancedFiltersContainer;
