import {
  Button,
  ButtonGroup,
  Radio,
  Grid,
  GridItem,
  Heading,
  Center,
  VStack,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import { RadioGroupControl } from "formik-chakra-ui";
import { isAdmin } from "../../helpers";

function SectionSTEComponent({
  handleSubmit,
  isSubmitting,
  isValid,
  isCreation,
  setIsCreating,
  setIsUpdating,
  setIsNewVersion,
  userProfile,
  LinkItems,
  NavItem,
  location,
  theme,
}) {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Heading as="h4" size="md" pb="4">
          Sintomi - Trattamenti - Esami
        </Heading>
        <Grid
          w="100%"
          templateColumns="repeat(9, 1fr)"
          columnGap={6}
          rowGap={6}
        >
          <GridItem colSpan={7}>
            <RadioGroupControl
              name="firstCallSymptoms"
              label="Sintomi alla prima chiamata"
            >
              <Radio value="NN">Non noto</Radio>
              <Radio value="NA">Non applicabile</Radio>
              <Radio value="Asintomatico">Asintomatico</Radio>
            </RadioGroupControl>
          </GridItem>

          <GridItem colSpan={2} rowSpan={3}>
            <Center h="100%">
              {isCreation ? (
                <Button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  onClick={() => {
                    return setIsCreating(true);
                  }}
                >
                  Apri Nuova scheda
                </Button>
              ) : (
                <ButtonGroup>
                  <VStack spacing={4} align="stretch">
                    <Button
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      onClick={() => {
                        return setIsUpdating(true);
                      }}
                    >
                      Modifica Scheda
                    </Button>
                    {/* {isAdmin(userProfile) && (
                      <Button
                        type="submit"
                        disabled={isSubmitting || !isValid}
                        onClick={() => {
                          return setIsNewVersion(true);
                        }}
                      >
                        Crea Nuova Versione
                      </Button>
                    )} */}
                  </VStack>
                </ButtonGroup>
              )}
            </Center>
          </GridItem>

          <GridItem colSpan={7}>
            <RadioGroupControl
              name="firstCallTreatments"
              label="Trattamenti alla prima chiamata"
            >
              <Radio value="NN">Non noto</Radio>
              <Radio value="NA">Non applicabile</Radio>
              <Radio value="No esame">Nessun esame effettuato/suggerito</Radio>
            </RadioGroupControl>
          </GridItem>

          <GridItem colSpan={7}>
            <RadioGroupControl
              name="firstCallExams"
              label="Esami alla prima chiamata"
            >
              <Radio value="NN">Non noto</Radio>
              <Radio value="NA">Non applicabile</Radio>
              <Radio value="Nessuna terapia">Nessuna terapia</Radio>
            </RadioGroupControl>
          </GridItem>
        </Grid>
      </form>

      <SimpleGrid
        columns={3}
        spacing="8"
        p="10"
        textAlign="center"
        rounded="lg"
      >
        {LinkItems.map((link) => {
          return (
            <Box key={link.name}>
              <NavItem
                key={link.name}
                location={location}
                icon={link.icon}
                theme={theme}
                route={link.route}
              >
                {link.name}
              </NavItem>
            </Box>
          );
        })}
      </SimpleGrid>
    </>
  );
}

export default SectionSTEComponent;
