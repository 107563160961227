import {
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Stack,
  FormControl,
  FormLabel,
  Radio,
} from "@chakra-ui/react";
import { RadioGroupControl } from "formik-chakra-ui";
import {
  changeStringArrayForMultiselect,
  setMultiOptions,
} from "../../helpers";
import { Select } from "chakra-react-select";
import { FaSave } from "react-icons/fa";

function TagComponent({
  handleSubmit,
  values,
  setFieldValue,
  isSubmitting,
  isValid,
  isCreation,
  setIsCreating,
  setIsUpdating,
  mushroomsTagsList,
  tagsList,
  theme
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid w="100%" templateColumns="repeat(6, 1fr)" columnGap={6} rowGap={6} mt={4}>
        <GridItem colSpan={6}>
          <FormControl>
            <FormLabel >Tag</FormLabel>
            <Select
              name="tagsList"
              className="chakra-multiselect"
              classNamePrefix="react-multiselect"
              isMulti
              placeholder="Seleziona i tag da associare a questa scheda"
              options={changeStringArrayForMultiselect(
                tagsList.map((a) => a.desc)
              )}
              value={changeStringArrayForMultiselect(values.tagsList)}
              onChange={(options) => {
                setFieldValue("tagsList", setMultiOptions(options));
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={6}>
          <FormControl>
            <FormLabel >Flag Funghi</FormLabel>
            <Select
              name="mushroomsTagsList"
              isMulti
              className="chakra-multiselect"
              classNamePrefix="react-multiselect"
              selectedOptionStyle="check"
              placeholder="Seleziona i flag da associare a questa scheda"
              options={changeStringArrayForMultiselect(
                mushroomsTagsList.map((a) => a.desc)
              )}
              value={changeStringArrayForMultiselect(values.mushroomsTagsList)}
              onChange={(options) => {
                setFieldValue("mushroomsTagsList", setMultiOptions(options));
              }}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={6}>
          <Stack spacing={[1, 5]} direction={["column", "row"]}>
            <RadioGroupControl name="complaintADR" label="Denuncia ADR">
              <Radio value="Fatta da CAV">Fatta da CAV</Radio>
              <Radio value="Consigliata">Consigliata</Radio>
            </RadioGroupControl>
          </Stack>
        </GridItem>
      </Grid>

      <Grid w="100%" templateColumns="repeat(6, 1fr)" mt={12} >
        <GridItem colStart={6}>
          {isCreation ? (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsCreating(true);
              }}
            >
              Salva Scheda
            </Button>
          ) : (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsUpdating(true);
              }}
            >
              Modifica Scheda
            </Button>
          )
          }
        </GridItem >
      </Grid >
    </form >
  );
}

export default TagComponent;
