import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { FORBIDDEN } from './routes';
import { AuthContext } from "../context/AuthContextProvider";

const AuthWrapper = (claim) => {
    const { isAuthenticated, userProfile } = useContext(AuthContext);
    const role = userProfile.attributes.role !== undefined ? userProfile.attributes.role[0] : ""
    return (
        (isAuthenticated && claim.includes(role) ? <Outlet /> : <Navigate to={FORBIDDEN} replace />)
    )
};

export default AuthWrapper
