import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";

function AlertDialogComponent({
  title,
  onClose,
  isOpen,
  body,
  onConfirm,
  confirmButtonText,
  confirmButtonColor,
}) {
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {title}
        </AlertDialogHeader>

        <AlertDialogBody>{body}</AlertDialogBody>

        <AlertDialogFooter>
          <Button onClick={onClose}>Annulla</Button>
          <Button colorScheme={confirmButtonColor} onClick={onConfirm} ml={3}>
            {confirmButtonText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default AlertDialogComponent;
