import {
  Button,
  ButtonGroup,
  Highlight,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Radio,
  Stack,
} from "@chakra-ui/react";
import {
  CheckboxSingleControl,
  RadioGroupControl,
  TextareaControl,
} from "formik-chakra-ui";
import { FaSave } from "react-icons/fa";

function EvaluateToxicityComponent({
  handleSubmit,
  // handleChange,
  setFieldValue,
  isSubmitting,
  isValid,
  values,
  isCreation,
  setIsCreating,
  setIsUpdating,
  theme
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid w="100%" templateColumns="repeat(6, 1fr)" columnGap={6} rowGap={6} mt={4}>
        <GridItem colSpan={6}>
          <FormLabel>
            Luogo del TRATTAMENTO
            consigliato alla 1^ chiamata
          </FormLabel>
          <RadioGroupControl name="firstCallSuggestedPlace">
            <Stack direction={['column', 'row']} >
              <Radio value="placeHomeIncident">
                A casa/ sul luogo dell&apos;incidente
              </Radio>
              <Radio value="placeMedicalExamination">Visita medica</Radio>
              <Radio value="placeOspitalObservation">
                Osservazione/trattamento in PS
              </Radio>
              <Radio value="placeHospitalization">Ricovero in ospedale</Radio>
              <Radio value="placeOBI">OBI</Radio>
              <Radio value="placeOther">Altro</Radio>
            </Stack>
          </RadioGroupControl>
        </GridItem>
        <GridItem colSpan={6}>
          <FormLabel>
            Localizzazione ATTUALE del paziente alla 1^ chiamata
          </FormLabel>
          <RadioGroupControl name="firstCallLocation">
            <Stack direction={['column', 'row']} >
              <Radio value="locationHomeIncident">
                A casa/ sul luogo dell&apos;incidente
              </Radio>
              <Radio value="locationMedicalExamination">Visita medica</Radio>
              <Radio value="locationOspitalObservation">
                Osservazione/trattamento in PS
              </Radio>
              <Radio value="locationHospitalization">
                Ricovero in ospedale
              </Radio>
              <Radio value="locationOBI">OBI</Radio>
              <Radio value="locationOther">Altro</Radio>
            </Stack>
          </RadioGroupControl>
        </GridItem>

        {/* <Divider orientation='horizontal' /> */}
        <GridItem colSpan={6}>
          <FormLabel>
            Valutazione tossicità evento al termine della 1^ chiamata
          </FormLabel>
          <RadioGroupControl name="firstCallEvaluateToxicity">
            <Radio value="notToxic">Non tossico</Radio>
            <Radio value="probablyNotToxic">Probabilmente non tossico</Radio>
            <Radio value="possibileIntoxication">
              Intossicazione possibile
            </Radio>
            <Radio value="intoxication">Intossicazione accertata</Radio>
            <Radio value="uncorrelatedSymptoms">
              Eziologa non tossica / sintomi non correlati all&apos;esposizione
            </Radio>
            <Radio value="consulting">Consulenza</Radio>
          </RadioGroupControl>
        </GridItem>


        <GridItem colSpan={6}>
          <FormLabel>
            PSS finale 1^ chiamata
          </FormLabel>
          <RadioGroupControl name="firstCallPSS">
            {/* <Stack> */}
            <Radio value="0">0</Radio>
            <Radio value="1">1</Radio>
            <Radio value="2">2</Radio>
            <Radio value="3">3</Radio>
            <Radio value="4">4</Radio>
            <Radio value="na">NA</Radio>
            {/* </Stack> */}
          </RadioGroupControl>
        </GridItem>

        {/* <Divider orientation='horizontal' /> */}

        <GridItem colSpan={6}>
          <FormLabel>
            Valutazione tossicità evento alla chiusura
          </FormLabel>
          <RadioGroupControl name="closureEvaluateToxicity">
            <Radio value="intoxicationNotManifested">
              Intossicazione non manifestata
            </Radio>
            <Radio value="healing">Guarigione / miglioramento</Radio>
            <Radio value="sequelae">Sequele</Radio>
            <Radio value="death">Decesso</Radio>
            <Radio value="partialFollowUp">Non noto / follow up parziale</Radio>
            <Radio value="nonToxicEtiology">Eziologia non tossica</Radio>
            <Radio value="followUpNotIndicated">Follow up non indicato</Radio>
            <Radio value="followUpNotExecuted">
              Follow up indicato non eseguito
            </Radio>
            <Radio value="followUpNotExecutable">
              Follow up indicato non eseguibile
            </Radio>
          </RadioGroupControl>
        </GridItem>

        <GridItem colSpan={6}>
          <FormLabel>
            PSS complessivo
          </FormLabel>
          <RadioGroupControl name="finalPSS">
            <Radio value="0">0</Radio>
            <Radio value="1">1</Radio>
            <Radio value="2">2</Radio>
            <Radio value="3">3</Radio>
            <Radio value="4">4</Radio>
            <Radio value="na">NA</Radio>
          </RadioGroupControl>
        </GridItem>

        <GridItem colSpan={6}>
          <Stack spacing={[1, 5]} direction={["column", "row"]}>
            <FormLabel>
              Interessante
            </FormLabel>
            <CheckboxSingleControl
              name="interesting"
              onClick={() => {
                if (values.interesting) {
                  // updated of value happens after onclick (so we have old value)
                  setFieldValue("interestingDesc", ""); // disabling text area
                  setFieldValue("interestingDescEditing", false);
                } else {
                  setFieldValue("interestingDescEditing", true);
                }
              }}
            />
          </Stack>
          <TextareaControl
            name="interestingDesc"
            textareaProps={{
              variant: values.interestingDescEditing ? "" : "filled",
              disabled: values.interestingDescEditing ? false : true
            }
            }
          />
        </GridItem>
      </Grid>
      <Grid w="100%" templateColumns="repeat(6, 1fr)" mt={12} >
        <GridItem colStart={6}>
          {isCreation ? (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsCreating(true);
              }}
            >
              Salva Scheda
            </Button>
          ) : (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsUpdating(true);
              }}
            >
              Modifica Scheda
            </Button>
          )
          }
        </GridItem >
      </Grid >
    </form>
  );
}

export default EvaluateToxicityComponent;
