import axios from "axios";

const apiConfig = {
  development: "http://localhost:8080/",
  production: "https://backend.cav.auth.cbim.it/",
};

const env = process.env.REACT_APP_ENV || "development";
const baseURL = apiConfig[env];
const instance = axios.create({ baseURL });

export function setToken(token) {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function getRecordByCode(code) {
  const url = `record/${code}`;
  return instance.get(url);
}

export function createNewHospital(values) {
  const url = "hospitals";
  return instance.post(url, values);
}

export function deleteClinicalRecord(code){
  const url = `record/${code}`;
  return instance.delete(url);
}

export function deleteClinicalRecordVersion(code, version) {
  const url = `record/${code}/${version}`;
  return instance.delete(url);
}

export function getAllClinicalRecords() {
  const url = "record";
  return instance.get(url);
}

export function getAllClinicalRecordsPaginated(page, limit, params = {}) {
  const queryString = Object.keys(params)
    .map((key) => {
      if (
        params[key] !== undefined &&
        params[key] !== "" &&
        params[key] !== null
      ) {
        if (Array.isArray(params[key])) {
          return "&" + key + "=" + params[key].join("|||");
        }
        return "&" + key + "=" + params[key];
      }
    })
    .join("");
  const url = `record?page=${page}&limit=${limit}${queryString}`;
  return instance.get(url);
}

export function getAllAnimals() {
  const url = "animals";
  return instance.get(url);
}

export function getAllReportingMethods() {
  const url = "reportingMethods";
  return instance.get(url);
}

export function getAllApplicants() {
  const url = "applicants";
  return instance.get(url);
}

export function getAllHospitalUnits() {
  const url = "hospitalUnits";
  return instance.get(url);
}

export function getAllHospitals() {
  const url = "hospitals";
  return instance.get(url);
}

export function getAllSchools() {
  const url = "schools";
  return instance.get(url);
}

export function getAllRegions() {
  const url = "https://axqvoqvbfjpaamphztgd.functions.supabase.co/regioni";
  return instance.get(url);
}

export function getProvincesByRegion(region) {
  const url = `https://axqvoqvbfjpaamphztgd.functions.supabase.co/province/${region}?onlyname=true`;
  return instance.get(url);
}

export function getCitiesByProvinces(province) {
  const url = `https://axqvoqvbfjpaamphztgd.functions.supabase.co/comuni/provincia/${province}?onlyname=true`;
  return instance.get(url);
}

/**
 * This function is used to create a new record
 * @param record (object) the record to be created
 * @param version (string) the version of the record
 * @param author  (string) the author of the record
 * @param data (object) the data that needs to be append in the record version
 * @returns {Promise<AxiosResponse<any>>}
 */
export function createRecord(record, code, version, author, data = {}) {
  const url = "record";
  record.code = code;
  record.versions = [
    {
      id: version,
      creationDate: new Date(),
      lastUpdateDate: new Date(),
      author,
      ...data,
    },
  ];

  return instance.post(url, record);
}

/**
 * This function is used to update a record version
 * @param record (object) containing all the record data
 * @param code (string) the record code
 * @param version (string) the record version
 * @param data (object) the record data to update e.g. { recordDetails: {...} }
 * @param key (string) the key to update: e.g. RECORD_DETAILS
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updateRecord(record, code, version, author, data, key) {
  const index = record.versions.findIndex((v) => {
    return v.id === version;
  });
  const ver = record.versions.filter((v) => {
    return v.id === version;
  })[0];
  ver[key] = data;
  record.versions[index] = ver;

  record.versions[index].lastUpdateDate = new Date();
  record.versions[index].author = author;

  const url = `record/${code}`;
  return instance.put(url, record);
}

export function createNewVersion(record, code, version) {
  const url = `record/${code}`;
  const actualVersion = record.versions.filter((v) => {
    return v.id === version;
  })[0];
  const newVersion = { ...actualVersion };
  const newVersionId = record.versions.length + 1;

  newVersion.id = newVersionId.toString();
  newVersion.creationDate = new Date();
  newVersion.lastUpdateDate = new Date();

  record.versions.push(newVersion);
  return { newVersionId: newVersion.id, ...instance.put(url, record) };
}

export function getAllAgentCategories() {
  const url = "agentCategories";
  return instance.get(url);
}

export function getAllTags() {
  const url = "tag";
  return instance.get(url);
}

export function getAllMushroomsTagTags() {
  const url = "mushroomsTag";
  return instance.get(url);
}

export function getAllExposureType() {
  const url = "exposureType";
  return instance.get(url);
}

export function getAllExposureMode() {
  const url = "exposureMode";
  return instance.get(url);
}

export function getAllExposurePlace() {
  const url = "exposurePlace";
  return instance.get(url);
}

export function getAllAnimalAssessment() {
  const url = "animalAssessment";
  return instance.get(url);
}

export function getAllBiteSites() {
  const url = "biteSite";
  return instance.get(url);
}

export function getAllAssumingMode() {
  const url = "assumingMode";
  return instance.get(url);
}

export function getAllEatenParts() {
  const url = "eatenPart";
  return instance.get(url);
}

export function getAllFuelTypes() {
  const url = "fuelTypes";
  return instance.get(url);
}

export function getAllGasTypes() {
  const url = "gasTypes";
  return instance.get(url);
}

export function getAllAgentSubcategory() {
  const url = "agentSubcategory";
  return instance.get(url);
}

export function getAllAssumingType() {
  const url = "assumingType";
  return instance.get(url);
}

// API per gestire elementi in un array

/**
 * This function is used to add a new agent to record
 * @param record (object) containing all the record data
 * @param code (string) the record code
 * @param version (string) the record version
 * @param data (object) the record data to update e.g. { recordDetails: {...} }
 * @param key (string) the key to update: e.g. RECORD_DETAILS
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addArrayElementToRecord(
  record,
  code,
  version,
  author,
  data,
  key
) {
  const index = record.versions.findIndex((v) => {
    return v.id === version;
  });
  const ver = record.versions.filter((v) => {
    return v.id === version;
  })[0];

  if (ver[key] === undefined) {
    ver[key] = [data];
  } else {
    ver[key].push(data);
  }

  record.versions[index] = ver;

  record.versions[index].lastUpdateDate = new Date();
  record.versions[index].author = author;

  const url = `record/${code}`;
  return instance.put(url, record);
}

/**
 * This function is delete an agent from record
 * @param record (object) containing all the record data
 * @param code (string) the record code
 * @param version (string) the record version
 * @param data (object) the record data to update e.g. { recordDetails: {...} }
 * @param key (string) the key to update: e.g. RECORD_DETAILS
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updateArrayElementInRecord(
  record,
  code,
  version,
  author,
  data,
  key
) {
  const index = record.versions.findIndex((v) => {
    return v.id === version;
  });
  const ver = record.versions.filter((v) => {
    return v.id === version;
  })[0];

  // cerco l'agente da modificare nell'array e lo sostituisco

  if (ver[key] === undefined) {
    ver[key] = [data];
  }

  const elementIndex = ver[key].findIndex((element) => {
    return element.ArrayElementID === data.ArrayElementID;
  });
  ver[key][elementIndex] = data;

  record.versions[index] = ver;

  record.versions[index].lastUpdateDate = new Date();
  record.versions[index].author = author;

  const url = `record/${code}`;
  return instance.put(url, record);
}

/**
 * This function is used to add a new agent to record
 * @param record (object) containing all the record data
 * @param code (string) the record code
 * @param version (string) the record version
 * @param selectedData (object) the agent to delete
 * @param key (string) the key to update: e.g. RECORD_DETAILS
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deleteArrayElementFromRecord(
  record,
  code,
  version,
  author,
  selectedData,
  key
) {
  const index = record.versions.findIndex((v) => {
    return v.id === version;
  });
  const ver = record.versions.filter((v) => {
    return v.id === version;
  })[0];

  ver[key] = ver[key].filter((obj) => {
    return obj.ArrayElementID !== selectedData.ArrayElementID;
  });

  if (ver[key].length === 0) {
    delete ver[key];
  }

  record.versions[index] = ver;

  record.versions[index].lastUpdateDate = new Date();
  record.versions[index].author = author;

  const url = `record/${code}`;
  return instance.put(url, record);
}

export function getUserPreferences(email) {
  const url = `userPreferences/array/${email}`;
  return instance.get(url);
}

export function updateUserPreference(payload, email) {
  const presetName = payload.presetName;
  delete payload.presetName;

  const url = `userPreferences/${email}/${presetName}`;
  return instance.put(url, { filters: payload });
}

export function deleteUserPreference(payload, email) {
  const presetName = payload.presetName;
  delete payload.presetName;

  const url = `userPreferences/delete/${email}/${presetName}`;
  return instance.delete(url);
}

// Gestione dei file

export const fileupload = {
  url: baseURL,
  process: {
    url: "upload",
    method: "POST",
  },
};

export const fileDownload = (file) => {
  const url = baseURL + "download/" + file.filename;
  const type = file.mimetype;
  const fileName = file.originalname;

  instance
    .get(url, {
      responseType: "blob", //Force to receive data in a Blob Format
    })
    .then((response) => {
      const file = new Blob([response.data], { type: type });
      var FileSaver = require("file-saver");
      FileSaver.saveAs(file, fileName);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteFileFromStorage = (file) => {
  const url = baseURL + "delete/" + file.filename;

  return instance.post(url);
};

export function deleteAttachmentsFromRecord(
  record,
  code,
  version,
  author,
  selectedData,
  key
) {
  const index = record.versions.findIndex((v) => {
    return v.id === version;
  });
  const ver = record.versions.filter((v) => {
    return v.id === version;
  })[0];

  ver[key] = ver[key].filter((obj) => {
    return obj.filename !== selectedData.filename;
  });

  record.versions[index] = ver;

  record.versions[index].lastUpdateDate = new Date();
  record.versions[index].author = author;

  const url = `record/${code}`;
  return instance.put(url, record);
}

/* Filter Presets ADMIN Console API */
export function getFiltersPresets() {
  const url = `filtersPresets`;
  return instance.get(url);
}

export function getFiltersPresetsById(id) {
  const url = `filtersPreset/${id}`;
  return instance.get(url);
}

export function updateFiltersPresetsById(presetName, payload) {
  const url = `filtersPresets/${presetName}`;
  return instance.put(url, payload);
}

export function deleteFiltersPresetsById(id) {
  const url = `filtersPresets/${id}`;
  return instance.delete(url);
}

export function insertFiltersPresets(payload) {
  const url = `/filtersPresets`;
  return instance.post(url, payload);
}

/*********/
/* Forms */
/*********/
export function getForms() {
  const url = `forms`;
  return instance.get(url);
}

export function getFormsByCode(code) {
  const url = `forms/${code}`;
  return instance.get(url);
}

export function postForm(payload) {
  const url = `forms`;
  return instance.post(url, payload);
}

export function updateForm(code) {
  const url = `forms/${code}`;
  return instance.put(url);
}

export function deleteForm(code) {
  const url = `forms/${code}`;
  return instance.delete(url);
}

/*******************/
/* Forms Entities  */
/*******************/
export function getFormsEntity() {
  const url = `forms-entity/entity`;
  return instance.get(url);
}

export function getFormsEntityByCode(code) {
  const url = `forms-entity/entity/${code}`;
  return instance.get(url);
}

export function postFormEntity(payload) {
  const url = `forms-entity/entity`;
  return instance.post(url, payload);
}

export function updateFormEntity(code) {
  const url = `forms-entity/entity/${code}`;
  return instance.put(url);
}

export function deleteFormEntity(code) {
  const url = `forms-entity/entity/${code}`;
  return instance.delete(url);
}

/*******************/
/*      ICD10      */
/*******************/
export function searchDiagnosis(code, description) {
  const url = `forms-entity/icd10?code=${code}&description=${description}`
  return instance.get(url)
}
