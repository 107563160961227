import { Formik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import SymptomsFormComponent from "./SymptomsForm.component";
import { createInitialValuesSTE, getArrayElementID } from "../../../../helpers";
import { getFormsEntityByCode } from "../../../../api";
import { errors } from "../../../Form";
import Loader from "../../../Loader";
import { AuthContext } from "../../../../context/AuthContextProvider";

const { OB } = errors;

function SymptomsFormContainer({
  isCreation,
  isAdding,
  setIsCreation,
  setIsSelected,
  setIsAdding,
  data,
  record,
  queryClient
}) {
  const [formEntity, setFormEntity] = useState();
  const authContext = useContext(AuthContext);

  const [isLoaded, setIsLoaded] = useState(false);
  const [dynamicInitialValues, setDynamicInitialValues] = useState();

  const initialValues = {
    ArrayElementID:
      data.ArrayElementID === undefined
        ? getArrayElementID()
        : data.ArrayElementID,
    creationDate:
      data.creationDate === undefined
        ? new Date()
        : new Date(data.creationDate),
    modality: data.modality === undefined ? "" : data.modality,
    speaker: data.speaker === undefined ? "" : data.speaker,
    user:
      data.user === undefined
        ? `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`
        : data.user,
  };

  const validationSchema = Yup.object().shape({
    creationDate: Yup.string().required(OB),
    user: Yup.string(),
  });

  useEffect(() => {
    const fetchData = async () => {
      const [respFormEntity] = await Promise.all([
        getFormsEntityByCode("symptoms"),
      ]);
      setFormEntity(respFormEntity.data);
      setDynamicInitialValues(
        createInitialValuesSTE(respFormEntity.data, "symptoms", data)
      );
      setIsLoaded(true);
    };
    fetchData();
  }, []);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Box>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ values, isSubmitting, isValid, dirty }) => {
          return (
            <SymptomsFormComponent
              values={values}
              isSubmitting={isSubmitting}
              isValid={isValid}
              dirty={dirty}
              data={data}
              isCreation={isCreation}
              isAdding={isAdding}
              record={record}
              setIsCreation={setIsCreation}
              setIsSelected={setIsSelected}
              setIsAdding={setIsAdding}
              formEntity={formEntity}
              dynamicInitialValues={dynamicInitialValues}
              queryClient={queryClient}
            />
          );
        }}
      </Formik>
    </Box>
  );
}

export default SymptomsFormContainer;
