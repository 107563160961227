import { Formik } from "formik";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";
import DynamicFormComponent from "./DynamicForm.component";
import {
  addArrayElementToRecord,
  createRecord,
  updateArrayElementInRecord,
} from "../../../../../api";
import { AuthContext } from "../../../../../context/AuthContextProvider";
import * as constants from "../../../../../constants";

function DynamicFormContainer({
  commonData,
  isSubmittingCommonForm,
  isValidCommonForm,
  dirtyCommonForm,
  isCreation,
  isAdding,
  record,
  setIsCreation,
  setIsSelected,
  setIsAdding,
  formEntity,
  initialValues,
  queryClient
}) {
  const toast = useToast();

  /* form statuses */
  const authContext = useContext(AuthContext);
  const [error, setError] = useState();
  const { code, version } = useParams();

  /* button statuses */
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      //validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        const formValues = { ...commonData, ...values };
        try {
          const author = `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`;
          if (isCreating) {
            const resp = await createRecord(record, code, version, author, {
              treatmentsArray: [formValues],
            });
            queryClient.invalidateQueries(['respTreatementsRecord']);
            setError(resp.data.error);
            toast({
              title: "Record Salvato",
              description: "L'operazione è andata a buon fine",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          } else if (isAdding) {
            const resp = await addArrayElementToRecord(
              record,
              code,
              version,
              author,
              formValues,
              constants.TREATMENTS
            );
            queryClient.invalidateQueries(['respTreatementsRecord']);
            setError(resp.data.error);
            toast({
              title: "Scheda trattamento aggiunta con successo",
              description: "L'operazione è andata a buon fine",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          }
          else if (isUpdating) {
            const resp = await updateArrayElementInRecord(
              record,
              code,
              version,
              author,
              formValues,
              constants.TREATMENTS
            );
            queryClient.invalidateQueries(['respTreatementsRecord']);
            setError(resp.data.error);
            toast({
              title: "Scheda trattamento modificata con successo",
              description: "L'operazione è andata a buon fine",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          }
          actions.setSubmitting(false);
          actions.setStatus({
            created: true,
          });

          setIsCreating(false);
          setIsUpdating(false);
        } catch (er) {
          setError({
            ...er,
          });
          toast({
            title: "Errore",
            description: er,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          actions.setSubmitting(false);
        }
        setIsCreation(false);
        setIsSelected(false);
        setIsAdding(false);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        setFieldValue,
        isSubmitting,
        isValid,
        dirty,
      }) => {
        return (
          <DynamicFormComponent
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            values={values}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            isValid={isValid}
            dirty={dirty}
            isSubmittingCommonForm={isSubmittingCommonForm}
            isValidCommonForm={isValidCommonForm}
            dirtyCommonForm={dirtyCommonForm}
            error={error}
            isCreation={isCreation}
            isAdding={isAdding}
            setIsCreating={setIsCreating}
            setIsUpdating={setIsUpdating}
            commonData={commonData}
            formEntity={formEntity}
          />
        );
      }}
    </Formik>
  );
}

export default DynamicFormContainer;
