import { Button, Grid, GridItem, useTheme } from "@chakra-ui/react";
import { renderForm } from "../../../../../helpers";
import { FaSave } from "react-icons/fa";

function DynamicFormComponent({
  handleSubmit,
  isSubmitting,
  isValid,
  values,
  setFieldValue,
  dirty,
  isValidCommonForm,
  dirtyCommonForm,
  isCreation,
  isAdding,
  setIsCreating,
  setIsUpdating,
  formEntity,
}) {
  const theme = useTheme();

  return (
    <form onSubmit={handleSubmit}>
      <Grid templateColumns="repeat(6, 1fr)" columnGap={6} rowGap={6} mt={6}>
        {formEntity && renderForm(formEntity, values, setFieldValue)}
      </Grid>
      {/* Bottoni */}
      <Grid w="100%" templateColumns="repeat(6, 1fr)" mt={12} >
        <GridItem colStart={6}>
          {isCreation ? (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsCreating(true);
              }}
            >
              Salva Scheda
            </Button>
          ) : (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsUpdating(true);
              }}
            >
              Modifica Scheda
            </Button>
          )
          }
        </GridItem >
      </Grid >
    </form>
  );
}

export default DynamicFormComponent;
