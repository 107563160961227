import { Fragment } from "react";
import {
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  useTheme,
} from "@chakra-ui/react";
import { useTable, useSortBy } from "react-table";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";

function AttachmentsTableComponent({ columns, data }) {
  const theme = useTheme();


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy

    );

  return (
    <VStack spacing={8}>

      <Table
        {...getTableProps()}
        size="md"

        align="left"
      >
        <Thead>
          {headerGroups.map((headerGroup) => {
            const { rowKey, ...restHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <Tr
                key={rowKey}
                {...restHeaderGroupProps}
                px={4}
                bg={theme.colors.primary.main}
              >
                {headerGroup.headers.map((column) => {
                  const { colKey, ...restColumn } = column.getHeaderProps(
                    column.getSortByToggleProps()
                  );
                  return (
                    <Th key={colKey} {...restColumn} color="white">
                      {column.render("Header")}
                      <chakra.span pl="4">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : null}
                      </chakra.span>
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { rowKey, ...restRowProps } = row.getRowProps();
            return (
              <Fragment key={rowKey}>
                <Tr key={rowKey} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { cellKey, ...restCellProps } = cell.getCellProps();
                    return (
                      <Td key={cellKey} {...restCellProps}>
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              </Fragment>
            );
          })}
        </Tbody>
      </Table>
    </VStack>
  );
}

export default AttachmentsTableComponent;
