export const MOBILE_SIZE = 768;
export const APPLICATION_FOLDER = "cav";

// Tipi richiedenti
export const HOSPITAL_ID = "1";
export const SCHOOL_ID = "22";
export const RECORD_DETAILS = "recordDetails";
export const APPLICANT_DATA = "applicantData";
export const PATIENT_DATA = "patientData";
export const EVALUATE_TOXICITY = "evaluateToxicity";
export const AGENTS = "agentData";
export const ERRANDS = "errandsData";
export const ATTACHMENTS = "attachmentsList";
export const TAGS = "tags";
export const STE = "STEData";
export const SYMPTOMS = "symptomsArray";
export const TREATMENTS = "treatmentsArray";
export const EXAMS = "examsArray";
export const DIAGNOSIS = "diagnosisData";

// Categorie Agenti
export const DEFAULT_ID = "Seleziona";
export const DRUGS_ID = [
  "Farmaco Italiano", "Farmaco Omeopatico", "Farmaco Straniero", "Farmaco Veterinario",
  "Farmaco Parafarmaceutico", "Erboristeria-Integratori", "Preparazione Galenica"
]
