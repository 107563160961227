import { getAllClinicalRecords } from '../api';

const API = 'records';
const PENDING = `${API}_PENDING`;
const FULFILLED = `${API}_FULFILLED`;
const REJECTED = `${API}_REJECTED`;

const initialState = {
  data: [],
  fetching: false,
  fetched: false,
  errors: [],
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PENDING:
      return { ...state, fetching: true, fetched: false };
    case REJECTED:
      return { ...initialState, errors: payload };
    case FULFILLED:
      return { data: [...payload.data], fetching: false, fetched: true };
    default:
      return state;
  }
};

export const fetchStart = () => {
  return {
    type: API,
    payload: getAllClinicalRecords(),
  };
};
