export const HOME = "/";
export const RECORD_INFO = "/record/info/:code/:version";
export const PATIENT_DATA = "/anagrafica/:code/:version";
export const APPLICANT_DATA = "/richiedente/:code/:version";
export const RECORD_DETAILS = "/dettaglio/:code/:version";
export const EVALUATE_TOXICITY = "/tossicita/:code/:version";
export const AGENTS_DATA = "/agenti/:code/:version";
export const ERRANDS_DATA = "/consegne/:code/:version";
export const ATTACHMENTS_DATA = "/allegati/:code/:version";
export const TAG_DATA = "/tag/:code/:version";
export const STE_DATA = "/ste/:code/:version";
export const SYMPTOMS_DATA = "/sintomi/:code/:version";
export const TREATMENTS_DATA = "/trattamenti/:code/:version";
export const EXAMS_DATA = "/esami/:code/:version";
export const DIAGNOSIS_DATA = "/diagnosi/:code/:version";

export const RECORD_INFO_SIMPLE = "/record/info";
export const PATIENT_DATA_SIMPLE = "/anagrafica";
export const APPLICANT_DATA_SIMPLE = "/richiedente";
export const RECORD_DETAILS_SIMPLE = "/dettaglio";
export const EVALUATE_TOXICITY_SIMPLE = "/tossicita";
export const AGENTS_DATA_SIMPLE = "/agenti";
export const ERRANDS_SIMPLE = "/consegne";
export const ATTACHMENTS_SIMPLE = "/allegati";
export const TAG_SIMPLE = "/tag";
export const STE_SIMPLE = "/ste";
export const SYMPTOMS_SIMPLE = "/sintomi";
export const TREATMENTS_SIMPLE = "/trattamenti";
export const EXAMS_SIMPLE = "/esami";
export const DIAGNOSIS_SIMPLE = "/diagnosi";

export const ADMIN_PRESETS = "/admin/presets";
export const ADMIN_FORMS = "/admin/forms";
export const ADMIN_AGENTI = "/admin/agenti";

export const FORBIDDEN = "/403";
