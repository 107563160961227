import { useDisclosure } from "@chakra-ui/react";
import SidebarComponent from "./Sidebar.component";

function SidebarContainer({ isCreation }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <SidebarComponent
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isCreation={isCreation}
    />
  );
}

export default SidebarContainer;
