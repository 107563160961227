import { useState, } from "react";
import { Textarea, Avatar, Box, Divider, HStack, Table, TableContainer, Thead, Th, Tbody, Td, Text, Tr, VStack, Badge } from "@chakra-ui/react";
import { AiFillFolderOpen } from "react-icons/ai";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { getAllAnimals, getRecordByCode } from "../../api";
import Loader from "../Loader";
import { useQuery } from "react-query";
import { DownloadIcon } from '@chakra-ui/icons'
import {
  fileDownload,
} from "../../api";

function RecordInfoComponent() {
  /* initial state */
  const [record, setRecord] = useState({});
  const [animal, setAnimal] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const { code, version } = useParams();

  const { data: respRecord } = useQuery(
    ["respRecord", code],
    () => {
      return getRecordByCode(code);
    },
    {
      onSuccess: ({ data: respRecord }) => {
        setRecord({
          record_id: respRecord.record_id,
          data: respRecord.versions.filter(value => {
            return value.id === version;
          })[0]
        } || {})
        setIsLoaded(true)
      },
    },
    {
      refetchInterval: 1500,
    }
  );

  const { data: respAnimals } = useQuery(
    ["respAnimals", code],
    () => {
      return getAllAnimals();
    },
    {
      onSuccess: ({ data: respAnimals }) => {
        setAnimal(respAnimals);
      },
    }
  );

  if (!isLoaded) {
    return <Loader />;
  }

  function renderAnimalTable(patientData, animalData) {
    return (
      <Tbody>
        <Tr>
          <Td><b>Tipo Paziente</b></Td>
          <Td>Animale</Td>
        </Tr>
        <Tr>
          <Td><b>Specie</b></Td>
          <Td>{animalData.filter(a => {
            return a.id.toString() === patientData.idSpecies;
          })[0].description}</Td>
        </Tr>
        <Tr>
          <Td><b>Sesso</b></Td>
          <Td>{patientData.sex}</Td>
        </Tr>
        <Tr>
          <Td><b>Età</b></Td>
          <Td>{patientData.age}</Td>
        </Tr>

      </Tbody>
    );
  }

  function renderHumanTable(patientData, applicantData) {
    return (
      <Tbody>
        <Tr>
          <Td><b>Nome</b></Td>
          <Td>{patientData.name}</Td>
        </Tr>
        <Tr>
          <Td><b>Cognome</b></Td>
          <Td>{patientData.surname}</Td>
        </Tr>
        <Tr>
          <Td><b>Data/Anno di Nascita</b></Td>
          <Td>{patientData.birthDate !== "" ? dayjs(patientData.birthDate).format("DD-MM-YYYY") : patientData.yearOfBirth}</Td>
        </Tr>
        <Tr>
          <Td><b>Età</b></Td>
          <Td>{patientData.age.years}</Td>
        </Tr>
        <Tr>
          <Td><b>Peso</b></Td>
          <Td>{patientData.weight} kg</Td>
        </Tr>
        <Tr>
          <Td><b>Altezza</b></Td>
          <Td>{patientData.height} cm</Td>
        </Tr>
        {applicantData && applicantData.city && applicantData.province && (
          < Tr >
            <Td><b>Località</b></Td>
            <Td>{applicantData.city} ({applicantData.province})</Td>
          </Tr>)
        }
      </Tbody >
    );
  }

  function renderAgents(data) {
    return (
      <Table size="sm" variant="simple">
        <Thead>
          <Tr>
            <Th>
              Categoria
            </Th>
            <Th>
              Modalità Esposizione
            </Th>
            <Th>
              Luogo Esposizione
            </Th>
            <Th>
              Data Inserimento
            </Th>
          </Tr>
        </Thead>
        <Tbody>

          {data.map(e => {
            return (
              <Tr key={e.ArrayElementID}>
                <Td>{e.category}</Td>
                <Td>{e.exposureMode}</Td>
                <Td>{e.exposurePlace}</Td>
                <Td>{dayjs(e.creationDate).format("DD-MM-YYYY HH:mm:ss")}</Td>

              </Tr>
            )
          })
          }
        </Tbody >
      </Table>

    )
  }

  function renderAttachments(data) {
    return (
      <Table size="sm" variant="simple">
        <Thead>
          <Tr >
            <Th>
              Nome File
            </Th>
            <Th >
              Tipo
            </Th>
            <Th >
              Data Inserimento
            </Th>
            <Th ></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map(e => {
            return (
              <Tr key={e.ArrayElementID}>
                <Td>{e.originalname}</Td>
                <Td>{e.mimetype}</Td>
                <Td>{e.uploadDate}</Td>
                <Td style={{ cursor: 'pointer' }}
                  onClick={() => fileDownload(e)}>
                  <DownloadIcon />
                </Td>
              </Tr>
            )
          })
          }
        </Tbody>
      </Table>
    )
  }

  function renderSymptoms(data) {
    return (
      <Table size="sm" variant="simple">
        <Thead>
          <Tr >
            <Th>
              Modalità
            </Th>
            <Th >
              Interlocutore
            </Th>
            <Th >
              Data Inserimento
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map(e => {
            return (
              <Tr key={e.ArrayElementID}>
                <Td>{e.modality}</Td>
                <Td>{e.speaker}</Td>
                <Td>{dayjs(e.creationDate).format("DD-MM-YYYY HH:mm:ss")}</Td>
              </Tr>
            )
          })
          }
        </Tbody>
      </Table>
    )
  }

  function renderDiagnosis(data) {
    return (
      <Table size="sm" variant="simple">
        <Thead>
          <Tr>
            <Th >
              Codice
            </Th>
            <Th >
              Descrizione
            </Th>
            <Th >
              Data Inserimento
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map(e => {
            return (
              <Tr key={e.ArrayElementID}>
                <Td>{e.diagnosisInfo.code}</Td>
                <Td>{e.diagnosisInfo.description}</Td>
                <Td>{dayjs(e.creationDate).format("DD-MM-YYYY HH:mm:ss")}</Td>
              </Tr>
            )
          })
          }
        </Tbody>
      </Table>
    )
  }

  return (
    <VStack spacing={8} mt={8} px={8} align="left" justify="left" w="full" h="full">
      <HStack spacing={8} justify="left">
        <Box>
          <Avatar size="xl" icon={<AiFillFolderOpen fontSize="3xl" />} />
        </Box>
        <Box>
          <Text fontWeight="bold" fontSize="lg">  {`Dettagli Scheda: ${record.record_id}`} {record.data.recordDetails && record.data.recordDetails.status && (<Badge >{record.data.recordDetails.status}</Badge>)}</Text>
          <Text fontSize="md" marginTop="1rem"><b>Versione:</b> {version}</Text>
          <Text fontSize="md"><b>Data Creazione:</b> {dayjs(record.data.creationDate).format("DD-MM-YYYY HH:mm:ss")}</Text>
          <Text fontSize="md"><b>Ultimo Aggiornamento:</b> {dayjs(record.data.lastUpdateDate).format("DD-MM-YYYY HH:mm:ss")}</Text>
          {record.data.tags && record.data.tags.tagsList && (<Text fontSize="md"><b>Tags:</b> {record.data.tags.tagsList.map(e => { return `${e};` })}</Text>)}
        </Box>
        <Divider orientation='vertical' />
        <Box>
          <Box>
            <Text fontSize="md"><b>Allegati</b></Text>
          </Box>
          <Box marginTop="1rem">
            {record.data && record.data.attachmentsList && renderAttachments(record.data.attachmentsList)}
          </Box>
        </Box>
      </HStack>
      {record.data && record.data.errandsData && record.data.errandsData.errands && (<Box align="left" w="full">
        <Box>
          <Text fontWeight="bold" fontSize="lg">Consegne</Text>
        </Box>
        <Box marginTop="1rem">
          <Textarea isReadOnly>
            {record.data.errandsData.errands}
          </Textarea>
        </Box>
      </Box>)}
      <Divider />
      {
        record.data.patientData && (
          <Box align="left" w="full">
            <Box>
              <Text fontWeight="bold" fontSize="lg">Dettagli Paziente</Text>
            </Box>
            <TableContainer w="full" mt={2}>
              <Table size="sm" variant="simple">
                {record.data.patientData.idType === "2" ? renderAnimalTable(record.data.patientData, animal) : renderHumanTable(record.data.patientData, record.data.applicantData)}
              </Table>
            </TableContainer>
            <Box>
              <br />
              <Text fontWeight="bold" fontSize="lg">Agenti</Text>
            </Box>
            <TableContainer w="full" mt={2}>
              {record.data && record.data.agentData && renderAgents(record.data.agentData)}
            </TableContainer>
            {/* <Box>
            <br />
            <Text fontWeight="bold" fontSize="lg">Sintomi</Text>
          </Box>
          <TableContainer w="full" mt={2}>
            {record.data && record.data.symptomsArray && renderSymptoms(record.data.symptomsArray)}
          </TableContainer> */}
            <Box>
              <br />
              <Text fontWeight="bold" fontSize="lg">Diagnosi</Text>
            </Box>
            <TableContainer w="full" mt={2}>
              {record.data && record.data.diagnosisData && renderDiagnosis(record.data.diagnosisData)}
            </TableContainer>
          </Box>)
      }
    </VStack >

  );
}

RecordInfoComponent.propTypes = {};

RecordInfoComponent.defaultProps = {};

export default RecordInfoComponent;
