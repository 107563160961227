import {
  Grid,
  GridItem,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ButtonGroup,
  Heading,
} from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";
import { displayDiagnosis } from "../../../helpers";

function DiagnosisSearchComponent({
  handleSubmit,
  isSubmitting,
  isValid,
  dirty,
  resetForm,
  modalIsOpen,
  modalOnClose,
  diagnosisArray,
  setDiagnosisArray,
  setFieldValue,
}) {
  return (
    <Modal
      isOpen={modalIsOpen}
      onClose={modalOnClose}
      size="xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        {/*
        <ModalHeader>Ricerca nell'elenco diagnosi</ModalHeader>
*/}
        <ModalBody pb={6}>
          <Heading as="h4" size="md">
            Ricerca nell'elenco diagnosi
          </Heading>
          <form onSubmit={handleSubmit}>
            <Grid
              templateColumns="repeat(5, 1fr)"
              columnGap={6}
              rowGap={6}
              mt={3}
            >
              <GridItem colSpan={5}>
                <InputControl name="code" label="Codice Diagnosi" />
              </GridItem>
              <GridItem colSpan={5}>
                <InputControl name="description" label="Descrizione Diagnosi" />
              </GridItem>
              <GridItem colSpan={5}>
                <ButtonGroup gap="4">
                  <Button
                    type="submit"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    Cerca
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      resetForm();
                      setDiagnosisArray([]);
                      modalOnClose();
                    }}
                  >
                    Chiudi
                  </Button>
                </ButtonGroup>
              </GridItem>
            </Grid>
          </form>
          {diagnosisArray.length > 0 && (
            <>
              <Heading as="h5" size="sm" mt={6}>
                Risultati ricerca
              </Heading>
              <Grid
                templateColumns="repeat(6, 1fr)"
                columnGap={6}
                rowGap={6}
                mt={6}
              >
                {displayDiagnosis(diagnosisArray, modalOnClose, setFieldValue)}
              </Grid>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DiagnosisSearchComponent;
