import { useColorMode, useDisclosure } from '@chakra-ui/react';
import { useContext } from "react";
import NavbarComponent from './Navbar.component';
import { AuthContext } from "../../context/AuthContextProvider";

function NavbarContainer({ ...props }) {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userProfile } = useContext(AuthContext);

  return (
    <NavbarComponent
      colorMode={colorMode}
      toggleColorMode={toggleColorMode}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      logout={props.logout}
      userProfile={userProfile}
    />
  );
}

export default NavbarContainer;
