import { Button, ButtonGroup, useDisclosure, Box } from "@chakra-ui/react";
import { FiTrash, FiEdit } from "react-icons/fi";
import { AlertDialog } from "../../Dialog";
import FiltersTableComponent from "./FiltersTable.component";

function FiltersTableContainer({
  data,
  deleteFilterPreset,
  selectedItem,
  setSelectedItem,
}) {
  const { onOpen, isOpen, onClose } = useDisclosure();

  const columns = [
    {
      Header: "Nome Query",
      accessor: "presetName",
    },
    {
      Header: "Pubblicato",
      accessor: "isPublic",
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ key, cell }) => {
        return <span key={key}>{cell.value ? "Sì" : "No"}</span>;
      },
    },
    {
      id: "actions",
      Header: "Azioni",
      accessor: "actions",
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ key, cell }) => {
        return (
          <ButtonGroup isAttached key={key}>
            <Button
              size="sm"
              onClick={() => {
                setSelectedItem(cell.row.original);
              }}
            >
              <FiEdit />
            </Button>
            <Button
              key={cell.row.original.filename}
              size="sm"
              onClick={() => {
                setSelectedItem(cell.row.original);
                onOpen();
              }}
            >
              <FiTrash />
            </Button>
          </ButtonGroup>
        );
      },
    },
  ];

  return (
    <Box mt={2}>
      <>
        <FiltersTableComponent columns={columns} data={data} />
        <AlertDialog
          title="Attenzione"
          isOpen={isOpen}
          onClose={onClose}
          body={`Sei sicuro di voler eliminare il preset selezionato?`}
          onConfirm={() => {
            deleteFilterPreset.mutate(selectedItem);
            onClose();
          }}
          confirmButtonText="Elimina"
          confirmButtonColor="red"
        />
      </>
    </Box>
  );
}

export default FiltersTableContainer;
