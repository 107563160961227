import ModalDialogComponent from "./ModalDialog.component";

function ModalDialogContainer({ title, isOpen, onClose, body, action }) {
  return (
    <ModalDialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={body}
      action={action}
    />
  );
}

export default ModalDialogContainer;
