import { Fragment, useMemo } from "react";
import {
  Select,
  Badge,
  Box,
  Button,
  ButtonGroup,
  chakra,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useTheme,
  HStack,
  VStack,
  useColorMode
} from "@chakra-ui/react";
import {
  useExpanded,
  useGlobalFilter,
  useSortBy,
  useTable,
  useFilters,
} from "react-table";
import { matchSorter } from "match-sorter";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  FiFilter,
  FiTrash,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import * as dayjs from "dayjs";
import { Link } from "react-router-dom";
import * as routes from "../../navigation/routes";
import { GlobalFilter, DefaultFilterForColumn } from "./HomeGlobalSearch";
import { AdvancedFilters } from "../AdvancedFilters";

const renderSubComponent = (values, clinicalRecords, mutationVersion) => {
  const item = clinicalRecords.filter((d) => {
    return d.record_id === values.record_id;
  })[0];
  clinicalRecords.findIndex((d) => {
    return d.record_id === values.record_id;
  });
  return (
    <Box>
      <Table>
        <Thead>
          <Th>Numero</Th>
          <Th>Autore</Th>
          <Th>Data Creazione</Th>
          <Th>Data Aggiornamento</Th>
          <Th>Status</Th>
          <Th>Azione</Th>
        </Thead>
        {item.versions &&
          [...item.versions].reverse().map((version) => {
            if (version !== undefined) {
              return (
                <Tr key={version.id}>
                  <Td>
                    <Link
                      to={`${routes.RECORD_DETAILS_SIMPLE}/${item.code}/${version.id}`}
                    >
                      <Button size="sm">Versione {version.id}</Button>
                    </Link>
                  </Td>
                  <Td>
                    <Text fontSize="sm">{version.author}</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      {dayjs(version.creationDate).format(
                        "DD/MM/YYYY - HH:mm:ss"
                      )}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      {dayjs(version.lastUpdateDate).format(
                        "DD/MM/YYYY - HH:mm:ss"
                      )}
                    </Text>
                  </Td>
                  <Td>
                    <Badge variant="subtle" ml="1" colorScheme="yellow">
                      {version.recordDetails && version.recordDetails.status
                        ? version.recordDetails.status
                        : "Aperta"}
                    </Badge>
                  </Td>
                  <Td>
                    <Button
                      size="sm"
                      variant="ghost"
                      isActive={item.versions.length > 1}
                      isDisabled={item.versions.length < 2}
                      onClick={() => {
                        mutationVersion.mutate(item.code, item.version);
                      }}
                    >
                      <FiTrash />
                    </Button>
                  </Td>
                </Tr>
              );
            }
            return null;
          })}
      </Table>
    </Box>
  );
};

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => {
  return !val;
};

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, {
    keys: [
      (row) => {
        return row.values[id];
      },
    ],
  });
}

function HomeComponent({
  columns,
  clinicalRecords,
  pageNumber,
  pageElements,
  prevPage,
  nextPage,
  lastPage,
  setPageNumber,
  setPageElements,
  mutationVersion,
  toggleFilter,
  setToggleFilter,
  mutationFilters,
  deleteFilters,
  filtersArray,
  setSelectedFilter,
  selectedFilter,
  agentCategories,
  tagsArray,
}) {
  const theme = useTheme();
  const isLight = useColorMode().colorMode === "light";

  const filterTypes = useMemo(() => {
    return {
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data: clinicalRecords,
      filterTypes,
      defaultColumn: { Filter: DefaultFilterForColumn },
      initialState: { pageIndex: pageNumber, pageSize: pageElements },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded
  );

  return (
    <Flex w="100%">
      <VStack spacing={8}>
        <Box w="full" align="left" mt={12}>
          <Text fontWeight="bold" fontSize="2xl">
            {" "}
            Record Clinici
          </Text>
        </Box>
        <Box w="full" align="left" mt={12}>
          <AdvancedFilters
            setSelectedFilter={setSelectedFilter}
            mutationFilters={mutationFilters}
            deleteFilters={deleteFilters}
            selectedFilter={selectedFilter}
            clinicalRecords={clinicalRecords}
            filtersArray={filtersArray}
            agentCategories={agentCategories}
            tagsArray={tagsArray}
          />
        </Box>
        <Box w="full" boxShadow="base">
          <HStack
            spacing={4}
            px={12}
            pt={8}
            pb={4}
            bg={theme.colors.primary.main}
            borderLeft
            borderRight
            borderTopRadius={"8px"}
          >
            <Box>
              <Button
                bg="gray.200"
                color="gray.600"
                onClick={() => {
                  setToggleFilter(!toggleFilter);
                }}
              >
                <FiFilter />
              </Button>
            </Box>
            <Box w="full">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                setGlobalFilter={setGlobalFilter}
                globalFilter={state.globalFilter}
              />
            </Box>
          </HStack>

          <Table
            {...getTableProps()}
            size="md"
            variant="simple"
            borderLeft
            borderRight
            bg={isLight ? "white" : "gray.900"}
          >
            <Thead>
              {headerGroups.map((headerGroup) => {
                const { rowKey, ...restHeaderGroupProps } =
                  headerGroup.getHeaderGroupProps();
                return (
                  <Tr
                    key={rowKey}
                    {...restHeaderGroupProps}
                    px={4}
                    bg={theme.colors.primary.main}
                  >
                    {headerGroup.headers.map((column) => {
                      return (
                        <Th key={column.id} color="white" scope="col">
                          <div
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <chakra.span pl="4">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )
                              ) : null}
                            </chakra.span>
                          </div>
                          <chakra.div pd={"1rem"}>
                            {column.canFilter && toggleFilter
                              ? column.render("Filter")
                              : null}
                          </chakra.div>
                        </Th>
                      );
                    })}
                  </Tr>
                );
              })}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                const { rowKey, ...restRowProps } = row.getRowProps();
                return (
                  <Fragment key={rowKey}>
                    <Tr key={rowKey} {...restRowProps}>
                      {row.cells.map((cell) => {
                        const { cellKey, ...restCellProps } =
                          cell.getCellProps();
                        return (
                          <Td key={cellKey} {...restCellProps}>
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                    {row.isExpanded ? (
                      <Tr key={rowKey}>
                        <Td colSpan="9">
                          {renderSubComponent(
                            row.values,
                            clinicalRecords,
                            mutationVersion
                          )}
                        </Td>
                      </Tr>
                    ) : null}
                  </Fragment>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <Box w="full" >
          <HStack>
            <Box align="left" w="15%">
              <Text fontSize="md" mb={2}>
                {" "}
                Elementi per Pagina:{" "}
              </Text>
              <Select
                value={pageElements}
                onChange={(event) => {
                  const elems = parseInt(event.target.value);
                  console.log("Elementi per pagina: ", elems);
                  setPageElements(elems);
                  setPageNumber(1);
                  size = "md";
                }}
                bg={isLight ? "white" : "gray.900"}

              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Select>
            </Box>
            <Box align="right" w="85%">
              <ButtonGroup isAttached bg={isLight ? "white" : "gray.900"} border={"1px solid #356d806c"} borderRadius={"8px"}>
                <Button
                  disabled={pageNumber === 1 || prevPage === undefined}
                  onClick={() => {
                    setPageNumber(1);
                  }}
                >
                  <FiChevronsLeft />
                </Button>
                <Button
                  disabled={pageNumber === 1 || prevPage === undefined}
                  onClick={() => {
                    setPageNumber(pageNumber - 1);
                  }}
                >
                  <FiChevronLeft />
                </Button>
                <Button isActive> {pageNumber} </Button>
                <Button
                  disabled={pageNumber === lastPage || nextPage === undefined}
                  onClick={() => {
                    setPageNumber(pageNumber + 1);
                  }}
                >
                  <FiChevronRight />
                </Button>

                <Button
                  disabled={pageNumber === lastPage || nextPage === undefined}
                  onClick={() => {
                    setPageNumber(lastPage);
                  }}
                >
                  <FiChevronsRight />
                </Button>
              </ButtonGroup>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Flex>
  );
}

export default HomeComponent;
