import { Button, ButtonGroup, Grid, GridItem } from "@chakra-ui/react";
import { TextareaControl } from "formik-chakra-ui";
import { isAdmin } from "../../helpers";
import { FaSave } from "react-icons/fa";

function ErrandsComponent({
  handleSubmit,
  isSubmitting,
  isValid,
  isCreation,
  setIsCreating,
  setIsUpdating,
  setIsNewVersion,
  userProfile,
  theme
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid w="100%" templateColumns="repeat(6, 1fr)" columnGap={6} rowGap={6} mt={4}>        {/* Modalità segnalazione */}
        <GridItem colSpan={6}>
          <TextareaControl name="errands" label="Inserisci una consegna" />
        </GridItem>
      </Grid>
      <Grid w="100%" templateColumns="repeat(6, 1fr)" mt={12} >
        <GridItem colStart={6}>
          {isCreation ? (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsCreating(true);
              }}
            >
              Salva Scheda
            </Button>
          ) : (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsUpdating(true);
              }}
            >
              Modifica Scheda
            </Button>
          )
          }
        </GridItem >
      </Grid >
    </form >
  );
}

export default ErrandsComponent;
