import React, { useState } from "react";
import FiltersPresetsComponent from "./FiltersPresets.component";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/toast";

import {
  getFiltersPresets,
  deleteFiltersPresetsById,
  updateFiltersPresetsById,
  updateUserPreference,
  deleteUserPreference,
  getAllAgentCategories,
  getAllTags,
} from "../../api";
import { SkeletonText } from "@chakra-ui/react";
const FiltersPresetsContainer = () => {
  const toast = useToast();
  const queryClient = useQueryClient({});
  const [selectedItem, setSelectedItem] = useState();
  const [agentCategories, setAgentCategories] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);

  const { isLoading, data } = useQuery(["filtersPresets"], () => {
    return getFiltersPresets();
  });

  const { data: agentCategoryArray } = useQuery(
    ["getAgentCategory"],
    () => {
      return getAllAgentCategories();
    },
    {
      onSuccess: ({ data: agentCategoryArray }) => {
        setAgentCategories(
          agentCategoryArray === undefined ? [] : agentCategoryArray
        );
      },
    }
  );

  const { data: tagArray } = useQuery(
    ["getTagArray"],
    () => {
      return getAllTags();
    },
    {
      onSuccess: ({ data: tagArray }) => {
        setTagsArray(tagArray === undefined ? [] : tagArray);
      },
    }
  );

  const addFilter = useMutation(
    (presetFilter) => {
      return Promise.all[
        (updateFiltersPresetsById(presetFilter.presetName, presetFilter),
        updateUserPreference(presetFilter, presetFilter.email))
      ];
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("filtersPresets");
        toast({
          title: "Filtro Creato",
          description: "Filtro creato con successo",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  const deleteFilterPreset = useMutation(
    (presetFilter) => {
      return Promise.all[
        (deleteFiltersPresetsById(presetFilter._id),
        deleteUserPreference(presetFilter, presetFilter.email))
      ];
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("filtersPresets");
        toast({
          title: "Filtro Eliminato",
          description: "Filtro eliminato con successo",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  return (
    <SkeletonText isLoaded={!isLoading}>
      <FiltersPresetsComponent
        data={data}
        addFilter={addFilter}
        deleteFilterPreset={deleteFilterPreset}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        agentCategories={agentCategories}
        tagsArray={tagsArray}
      />
    </SkeletonText>
  );
};

FiltersPresetsContainer.propTypes = {};

FiltersPresetsContainer.defaultProps = {};

export default FiltersPresetsContainer;
