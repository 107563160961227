import React from 'react';
import {
    Box,
    Flex,
    Text,
    VStack,
} from "@chakra-ui/react";

const ForbiddenComponent = () => {
    return (
        < Flex w="100%" >
            <VStack spacing={8} >
                <Box w="full" align="center" mt={8}>
                    <Text fontSize="xl">
                        Non sei autorizzato ad accedere a questa pagina
                    </Text>
                </Box>
            </VStack>
        </Flex >
    )
}

ForbiddenComponent.propTypes = {};

ForbiddenComponent.defaultProps = {};

export default ForbiddenComponent;