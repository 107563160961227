import { Formik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { Box, useTheme, useColorMode, Text } from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";
import * as constants from "../../constants";
import ErrandsComponent from "./Errands.component";
import {
  createNewVersion,
  createRecord,
  getRecordByCode,
  updateRecord,
} from "../../api";
import { AuthContext } from "../../context/AuthContextProvider";
import Loader from "../Loader";
import { getDataFromRecord } from "../../helpers";

function ErrandsContainer() {
  const toast = useToast();
  const theme = useTheme();

  /* initial state */
  const [record, setRecord] = useState({});
  const [data, setData] = useState({});

  /* form statuses */
  const [isCreation, setIsCreation] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const authContext = useContext(AuthContext);
  const [error, setError] = useState();
  const { code, version } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isLight = useColorMode().colorMode === "light";

  /* button statuses */
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isNewVersion, setIsNewVersion] = useState(false);

  const initialValues = {
    errands: data.errands === undefined ? "" : data.errands,
  };

  const validationSchema = Yup.object().shape({
    errands: Yup.string().max(10000),
  });

  useEffect(() => {
    const fetchData = async () => {
      const [respRecord] = await Promise.all([getRecordByCode(code)]);
      setRecord(respRecord.data || {});
      const extractedData = getDataFromRecord(
        respRecord.data,
        version,
        constants.ERRANDS
      );
      extractedData.record_id = respRecord.data.record_id;
      setData(extractedData);
      setIsCreation(respRecord.data.code === undefined);
      setIsLoaded(true);
    };
    fetchData();
  }, []);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Box boxShadow="base" borderRadius="8px" pt="8" pb="8" px="8"
      w="full" bg={isLight ? "white" : "gray.900"}>
      <Box>
        <Text fontSize='2xl' as="b" >Consegne</Text>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          try {
            const author = `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`;
            if (values.errands === "") {
              delete values.errands;
            }
            if (isCreating) {
              const resp = await createRecord(record, code, version, author, {
                recordDetails: values,
              });
              setError(resp.data.error);
            } else if (isUpdating) {
              const resp = await updateRecord(
                record,
                code,
                version,
                author,
                values,
                constants.ERRANDS
              );
              setError(resp.data.error);
            } else if (isNewVersion) {
              const { newVersionId } = await createNewVersion(
                record,
                code,
                version,
                author
              );
              const resp = await updateRecord(
                record,
                code,
                newVersionId,
                author,
                values,
                constants.ERRANDS
              );
              setError(resp.data.error);
              navigate(
                `/${location.pathname.split("/")[1]}/${code}/${newVersionId}`
              );
            }

            toast({
              title: "Record Salvato",
              description: "L'operazione è andata a buon fine",
              status: "success",
              duration: 9000,
              isClosable: true,
            });

            actions.setSubmitting(false);
            actions.setStatus({
              created: true,
            });

            setIsNewVersion(false);
            setIsCreating(false);
            setIsUpdating(false);
          } catch (er) {
            setError({
              ...er,
            });
            toast({
              title: "Errore",
              description: er,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            actions.setSubmitting(false);
          }
          setIsCreation(false);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          isSubmitting,
          isValid,
        }) => {
          return (
            <ErrandsComponent
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              values={values}
              isSubmitting={isSubmitting}
              isValid={isValid}
              error={error}
              isCreation={isCreation}
              setIsNewVersion={setIsNewVersion}
              setIsCreating={setIsCreating}
              setIsUpdating={setIsUpdating}
              userProfile={authContext.userProfile}
              theme={theme}
            />
          );
        }}
      </Formik>
    </Box>
  );
}

export default ErrandsContainer;
