import {
  Button,
  ButtonGroup,
  useDisclosure,
  Box,
  Flex,
  Spacer,
  HStack,
  Heading,
  Text, useColorMode
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FiEdit, FiTrash } from "react-icons/fi";
import { RiFileAddLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import * as api from "../../../api";
import { AlertDialog } from "../../Dialog";
import TreatmentsComponent from "./Treatments.component";
import { getArrayFromRecord } from "../../../helpers";
import TreatmentsForm from "./TreatmentsForm";
import Loader from "../../Loader";
import * as constants from "../../../constants";
import { AuthContext } from "../../../context/AuthContextProvider";
import dayjs from "dayjs";
import { useQuery, useMutation, useQueryClient } from "react-query";

function TreatmentsContainer() {
  /* initial state */
  const [record, setRecord] = useState({});
  const [dataArray, setDataArray] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const authContext = useContext(AuthContext);
  const queryClient = useQueryClient({});
  const isLight = useColorMode().colorMode === "light";

  /* form statuses */
  const [isCreation, setIsCreation] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { code, version } = useParams();

  /* table statuses */
  const { onOpen, isOpen, onClose } = useDisclosure();

  const author = `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`;

  const { data: respRecord } = useQuery(
    ["respTreatementsRecord", code],
    () => {
      return api.getRecordByCode(code)
    },
    {
      onSuccess: ({ data: respRecord }) => {
        setRecord(respRecord || {});
        const extractedData = getArrayFromRecord(
          respRecord,
          version,
          constants.TREATMENTS
        );
        setDataArray(extractedData);
        setIsCreation(respRecord.code === undefined)
        setIsLoaded(true)
      },
    }
  );

  const columns = [
    {
      Header: "Data Creazione",
      accessor: "creationDate",
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ cell }) => {
        return (
          <span>
            {dayjs(cell.value).locale("it").format("DD/MM/YYYY HH:mm")}
          </span>
        );
      },
    },
    {
      Header: "Tipologia",
      accessor: "type",
    },
    {
      Header: "Utente",
      accessor: "user",
    },
    {
      id: "actions",
      Header: "Azioni",
      accessor: "actions",
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ cell }) => {
        return (
          <ButtonGroup isAttached>
            <Button
              size="sm"
              onClick={() => {
                setSelectedData(cell.row.original);
                setIsSelected(true);
              }}
            >
              <FiEdit />
            </Button>
            <Button
              key={cell.row.original.id}
              size="sm"
              onClick={() => {
                setSelectedData(cell.row.original);
                onOpen();
              }}
            >
              <FiTrash />
            </Button>
          </ButtonGroup>
        );
      },
    },
  ];

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Box boxShadow="base" borderRadius="8px" pt="8" pb="8" px="8"
      w="full" bg={isLight ? "white" : "gray.900"}>
      {isCreation || isSelected || isAdding ? (
        <TreatmentsForm
          isCreation={isCreation}
          isAdding={isAdding}
          setIsCreation={setIsCreation}
          setIsSelected={setIsSelected}
          setIsAdding={setIsAdding}
          data={selectedData}
          record={record}
          queryClient={queryClient}
        />
      ) : (
        <>
          <HStack w="100" spacer={4}>
            <Box>
              <Text fontSize='2xl' as="b" >Lista Trattamenti</Text>
            </Box>
            <Box p="4" >
              <Button
                leftIcon={<RiFileAddLine />}
                onClick={() => {
                  setSelectedData({});
                  setIsCreation(false);
                  setIsAdding(true);
                }}
              >
                Nuova scheda trattamenti
              </Button>
            </Box>
          </HStack>
          <TreatmentsComponent columns={columns} data={dataArray} />
          <AlertDialog
            title="Attenzione"
            isOpen={isOpen}
            onClose={onClose}
            body={`Sei sicuro di voler eliminare la scheda trattamenti selezionata?`}
            onConfirm={() => {
              api.deleteArrayElementFromRecord(
                record,
                code,
                version,
                author,
                selectedData,
                constants.TREATMENTS
              );
              setDataArray(
                dataArray.filter((item) => {
                  return item.ArrayElementID !== selectedData.ArrayElementID;
                })
              );
              onClose();
            }}
            confirmButtonText="Elimina"
            confirmButtonColor="red"
          />
        </>
      )}
    </Box>
  );
}

export default TreatmentsContainer;
