import DatePicker, { registerLocale } from "react-datepicker";
import { useColorMode } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.css";
import { useField, useFormikContext } from "formik";

import it from "date-fns/locale/it";
// the locale you want
registerLocale("it", it); // register it with the name you want

function DatePickerInput({ ...props }) {
  const isLight = useColorMode().colorMode === "light";
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <div className={isLight ? "light-theme-original" : "dark-theme"}>
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        dateFormat="dd/MM/yyyy HH:mm"
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        showYearDropdown
        showMonthDropdown
        showTimeSelect
        dropdownMode="select"
        locale="it"
        showPopperArrow={true}
        timeIntervals={5}
        className="react-datapicker__input-text"
      />
    </div>
  );
}

export default DatePickerInput;
