import {
  FormLabel,
  Grid,
  GridItem,
  Flex,
  Box,
  Heading,
  Spacer,
  Button,
  Input
} from "@chakra-ui/react";
import { SelectControl, NumberInputControl, TextareaControl, InputControl } from "formik-chakra-ui";
import { TiArrowBack } from "react-icons/ti";
import { createInitialValues, showListSaveDesc, showListSaveDescSubgroups } from "../../../helpers";
import { DRUGS_ID, DEFAULT_ID } from "../../../constants";
import Drugs from "./Drugs";
import DatePickerAgent from "../../Form/DatePickerAgent";
import DynamicForm from "./DynamicForm";

function AgentsFormComponent({
  values,
  isSubmitting,
  isValid,
  dirty,
  agentCategories,
  exposureTypes,
  exposurePlaces,
  exposureModes,
  data,
  isCreation,
  isAdding,
  record,
  setIsCreation,
  setIsSelected,
  setIsAdding,
  formEntity,
  dynamicInitialValues,
  createRecord,
  updateArrayElementInRecord,
  addArrayElementToRecord,
  menuNames,
  agents,
  setCategoryId,
  categoryId,
  theme
}) {
  console.log(isCreation, isAdding)
  return (
    <>
      <Flex>
        <Box pt={2}>
          <Heading as="h3" size="lg">
            Aggiungi nuovo agente
          </Heading>
        </Box>
        <Spacer />
        <Box pt={2} pr={4}>
          <Button
            rightIcon={<TiArrowBack />}
            onClick={() => {
              setIsCreation(false);
              setIsSelected(false);
              setIsAdding(false);
            }}
          >
            Torna alla lista
          </Button>
        </Box>
      </Flex>
      <form>
        {/* Categoria */}
        <Grid templateColumns="repeat(10, 1fr)" columnGap={6} rowGap={6} mt={6}>
          <GridItem colSpan={5}>
            <FormLabel htmlFor="category">Categoria</FormLabel>
            <SelectControl name="category" placeholder="Seleziona"
              selectProps={
                !isCreation && !isAdding
                  ? { isDisabled: true, variant: 'filled' }
                  : {}
              }


              onChange={(v) => {
                const retrievedObj = agents.filter(x => { return x.label === v.target.value })
                if (retrievedObj && retrievedObj[0])
                  setCategoryId(retrievedObj[0].id)
              }}>
              <option>
                Seleziona
              </option>
              {menuNames.map(x => { return showListSaveDescSubgroups(x, agents) })}
            </SelectControl>
          </GridItem>

          {/* Data creazione */}
          <GridItem colSpan={3}>
            <FormLabel htmlFor="creationDate">Data Creazione</FormLabel>
            <DatePickerAgent name="creationDate" />
          </GridItem>
          <GridItem colSpan={2}>
            <FormLabel htmlFor="categoryId">ID Codifica</FormLabel>
            <Input value={categoryId} variant="filled" disabled />
          </GridItem>

          <input type="hidden" name="categoryId" />



          {/* Tipo di esposizione */}
          <GridItem colSpan={5}>
            <FormLabel htmlFor="exposureType">Tipo di esposizione</FormLabel>
            <SelectControl name="exposureType">
              {exposureTypes.map(showListSaveDesc)}
            </SelectControl>
          </GridItem>

          {/* Luogo di esposizione */}
          <GridItem colSpan={5}>
            <FormLabel htmlFor="exposurePlace">Luogo di esposizione</FormLabel>
            <SelectControl name="exposurePlace">
              {exposurePlaces.map(showListSaveDesc)}
            </SelectControl>
          </GridItem>

          {/* Modalità di esposizione */}
          <GridItem colSpan={5}>
            <FormLabel htmlFor="exposureMode">
              Modalità di esposizione
            </FormLabel>
            <SelectControl name="exposureMode">
              {exposureModes.map(showListSaveDesc)}
            </SelectControl>
          </GridItem>

          {/* Tempo trascorso */}
          <GridItem colSpan={5}>
            <NumberInputControl numberInputControlProps={{ min: 0 }} name="timePassed" label="Tempo intercorso (h)" />
          </GridItem>

          {/* Note */}
          <GridItem colSpan={10}>
            <TextareaControl name="notes" label="Note" />
          </GridItem>
        </Grid>
      </form>

      {
        values.category !== DEFAULT_ID && !DRUGS_ID.includes(values.category) && (
          <DynamicForm
            addArrayElementToRecord={addArrayElementToRecord}
            updateArrayElementInRecord={updateArrayElementInRecord}
            createRecord={createRecord}
            commonData={values}
            isSubmittingCommonForm={isSubmitting}
            isValidCommonForm={isValid}
            dirtyCommonForm={dirty}
            data={data}
            isCreation={isCreation}
            isAdding={isAdding}
            record={record}
            setIsCreation={setIsCreation}
            setIsSelected={setIsSelected}
            setIsAdding={setIsAdding}
            formEntity={formEntity}
            initialValues={
              data.category !== undefined
                ? dynamicInitialValues
                : createInitialValues(formEntity, "agent", data, values.category)
            }
          />
        )
      }

      {
        DRUGS_ID.includes(values.category) && (
          <Drugs
            addArrayElementToRecord={addArrayElementToRecord}
            updateArrayElementInRecord={updateArrayElementInRecord}
            createRecord={createRecord}
            commonData={values}
            isSubmittingCommonForm={isSubmitting}
            isValidCommonForm={isValid}
            dirtyCommonForm={dirty}
            data={data}
            isCreation={isCreation}
            isAdding={isAdding}
            record={record}
            setIsCreation={setIsCreation}
            setIsSelected={setIsSelected}
            setIsAdding={setIsAdding}
          />
        )
      }
    </>
  );
}

export default AgentsFormComponent;
