import { Box, Flex, Stack, VStack } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import { AppRoutes } from "../../navigation/AppRoutes";
import Sidebar from "../Sidebar";
import * as routes from "../../navigation/routes";
import PatientInfo from "../PatientInfo";

function showSideBar(pathname) {
  return pathname !== routes.HOME && !pathname.includes(routes.RECORD_INFO_SIMPLE) && !pathname.includes(routes.FORBIDDEN)
    && !pathname.includes(routes.ADMIN_PRESETS) && !pathname.includes(routes.ADMIN_FORMS);
}

function AppComponent({ logout, profile }) {

  const location = useLocation();
  return (

    <VStack spacing={16} align="center" w="full">
      {!location.pathname.includes(routes.RECORD_INFO_SIMPLE) && <Navbar logout={logout} profile={profile} />}
      <Flex w="100%" justify={location.pathname !== "/" ? null : "center"}>
        <Stack spacing={8} direction="row" >
          {showSideBar(location.pathname) &&
            <Sidebar />}
          <VStack spacing={2} px={8}>
            {showSideBar(location.pathname) &&
              <Box mt={8} w="full" alignItems="left" justify="left"
                bg="green.200" color="green.800" borderRadius="8px" boxShadow="base">
                <PatientInfo />
              </Box>}
            <AppRoutes />
          </VStack>
        </Stack>
      </Flex>
    </VStack>
  );
}

AppComponent.propTypes = {};
AppComponent.defaultProps = {};

export default AppComponent;
