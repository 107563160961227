import AlertDialogComponent from "./AlertDialog.component";

function AlertDialogContainer({
  title,
  isOpen,
  onClose,
  body,
  action,
  onConfirm,
  confirmButtonText,
  confirmButtonColor,
}) {
  return (
    <AlertDialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={body}
      action={action}
      onConfirm={onConfirm}
      confirmButtonText={confirmButtonText}
      confirmButtonColor={confirmButtonColor}
    />
  );
}

export default AlertDialogContainer;
