import { Modal, ModalOverlay, ModalContent,
  ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button} from "@chakra-ui/react";

function ModalDialogComponent({title, onClose, isOpen, body, action}){
  return(
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {body}
        </ModalBody>

        <ModalFooter>
          <Button variant='ghost' colorScheme='blue' mr={3} onClick={onClose} >
            Annulla
          </Button>
          <Button variant='ghost' colorScheme="green" action={action}>Conferma</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalDialogComponent
