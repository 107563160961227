import {
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  GridItem,
  Stack,
} from "@chakra-ui/react";
import {
  CheckboxSingleControl,
  InputControl,
  SelectControl,
} from "formik-chakra-ui";
import { showList, showListSaveDesc } from "../../helpers";
import { HOSPITAL_ID, SCHOOL_ID } from "../../constants";
import { FaSave } from "react-icons/fa";

function ApplicantDataComponent({
  handleSubmit,
  isSubmitting,
  setFieldValue,
  isValid,
  reportingMethods,
  applicantTypes,
  findProvinces,
  findCities,
  values,
  regions,
  provinces,
  cities,
  hospitalUnits,
  schools,
  hospitalsOptions,
  newHospitalFlag,
  setNewHospitalFlag,
  isCreation,
  setIsCreating,
  setIsUpdating,
  theme }) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid w="100%" templateColumns="repeat(6, 1fr)" columnGap={6} rowGap={6} mt={4}>
        {/* Modalità segnalazione */}
        <GridItem colSpan={3}>
          <FormLabel htmlFor="idReportingMethods">
            Modalità della segnalazione
          </FormLabel>
          <SelectControl name="idReportingMethods">
            {reportingMethods.map(showList)}
          </SelectControl>
        </GridItem>

        {/* Tipo richiedente */}
        <GridItem colSpan={3}>
          <FormLabel htmlFor="idApplicantType">Tipo di richiedente</FormLabel>
          <SelectControl name="idApplicantType">
            {applicantTypes.map(showList)}
          </SelectControl>
        </GridItem>

        {/* Regione */}
        <GridItem colSpan={3}>
          <FormLabel htmlFor="region">Regione</FormLabel>
          <SelectControl
            name="region"
            onChange={async (e) => {
              setFieldValue("province", "");
              setFieldValue("city", "");
              return await findProvinces(e.target.value);
            }}
          >
            {regions.map(showList)}
          </SelectControl>
        </GridItem>

        {/* Provincia */}
        <GridItem colSpan={3}>
          <FormLabel htmlFor="province">Provincia</FormLabel>
          <SelectControl
            name="province"
            onChange={async (e) => {
              setFieldValue("city", "");
              return await findCities(e.target.value);
            }}
            selectProps={
              values.region === "" || values.region === "Seleziona"
                ? { isDisabled: true, variant: 'filled' }
                : {}
            }
          >
            {provinces.map(showList)}
          </SelectControl>
        </GridItem>

        {/* Comune */}
        <GridItem colSpan={6}>
          <FormLabel htmlFor="city">Comune</FormLabel>
          <SelectControl
            name="city"
            selectProps={
              values.province === "" || values.province === "Seleziona"
                ? { isDisabled: true, variant: 'filled' }
                : {}
            }
          >
            {cities.map(showList)}
          </SelectControl>
        </GridItem>

        {/* Ospedale - Reparto - Medico (opzionali in base al tipo di richiedente) */}
        {values.idApplicantType === HOSPITAL_ID && (
          <>
            {newHospitalFlag ? (
              <GridItem colSpan={4}>
                <InputControl name="hospital" label="Ospedale" />
              </GridItem>
            ) : (
              <GridItem colSpan={4}>
                <FormLabel htmlFor="hospital">Ospedale</FormLabel>
                <SelectControl name="hospital">
                  {hospitalsOptions.map(showListSaveDesc)}
                </SelectControl>
              </GridItem>
            )}

            <GridItem colSpan={2} marginTop="32px">
              <Button
                onClick={() => {
                  setNewHospitalFlag(!newHospitalFlag);
                }}
              >
                Nuovo Ospedale
              </Button>
            </GridItem>

            <GridItem colSpan={3}>
              <FormLabel htmlFor="hospitalUnit">Reparto</FormLabel>
              <SelectControl name="hospitalUnit">
                {hospitalUnits.map(showList)}
              </SelectControl>
            </GridItem>

            <GridItem colSpan={3}>
              <InputControl name="doctor" label="Medico" />
            </GridItem>
          </>
        )}

        {/* Tipo Scuola - Nome scuola - Qualifica */}
        {values.idApplicantType === SCHOOL_ID && (
          <>
            <GridItem colSpan={2}>
              <FormLabel htmlFor="idSchoolType">Tipologia Scuola</FormLabel>
              <SelectControl name="idSchoolType">
                {schools.map(showList)}
              </SelectControl>
            </GridItem>

            <GridItem colSpan={2}>
              <InputControl name="schoolName" label="Nome Scuola" />
            </GridItem>

            <GridItem colSpan={2}>
              <InputControl name="rating" label="Qualifica" />
            </GridItem>
          </>
        )}

        {/* Email */}
        <GridItem colSpan={6}>
          <InputControl name="email" label="E-mail" />
        </GridItem>

        {/* Fax */}
        <GridItem colSpan={3}>
          <InputControl name="fax" label="Fax" />
        </GridItem>

        {/* Email */}
        <GridItem colSpan={3}>
          <InputControl name="phone" label="Telefono" />
        </GridItem>

        {/* Checkbox per informazioni richieste */}

        <GridItem colSpan={6}>
          <FormLabel>Informazioni Richieste</FormLabel>
          <Stack spacing={[1, 5]} direction={["column", "row"]}>
            <CheckboxSingleControl name="intossicazioneIR">
              Diagnosi/trattamento intossicazione
            </CheckboxSingleControl>
            <CheckboxSingleControl name="effettiAvversiIR">
              Diagnosi/trattamento effetti avversi da farmaci, alimenti,
              interazioni
            </CheckboxSingleControl>
          </Stack>
        </GridItem>
        <GridItem colSpan={6}>
          <Stack spacing={[1, 5]} direction={["column", "row"]}>
            <CheckboxSingleControl name="effettiGravidanzaIR">
              Diagnosi/trattamento effetti in gravidanza/allattamento
            </CheckboxSingleControl>
            <CheckboxSingleControl name="infoPazienteIR">
              Informazioni per paziente/consulenza terapeutica
            </CheckboxSingleControl>
          </Stack>
        </GridItem>
        <GridItem colSpan={6}>
          <Stack spacing={[1, 5]} direction={["column", "row"]}>
            <CheckboxSingleControl name="richiestaInvioAntidotiIR">
              Richiesta invio antidoti per paziente
            </CheckboxSingleControl>
            <CheckboxSingleControl name="richiestaEsamiIR">
              Richiesta esami tossicologici
            </CheckboxSingleControl>
          </Stack>
        </GridItem>
        <GridItem colSpan={6}>
          <Stack spacing={[1, 5]} direction={["column", "row"]}>
            <CheckboxSingleControl name="richiestaPreventivaIR">
              Richiesta Preventiva
            </CheckboxSingleControl>
            <CheckboxSingleControl name="altroIR">
              Altro/Non specificato
            </CheckboxSingleControl>
          </Stack>
        </GridItem>
      </Grid>
      {/* Bottoni */}
      <Grid w="100%" templateColumns="repeat(6, 1fr)" mt={12} >
        <GridItem colStart={6}>
          {isCreation ? (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsCreating(true);
              }}
            >
              Salva Scheda
            </Button>
          ) : (
            <Button
              type="submit"
              leftIcon={<FaSave />}
              background={theme.colors.primary.main}
              color={'white'}
              disabled={isSubmitting || !isValid}
              onClick={() => {
                return setIsUpdating(true);
              }}
            >
              Modifica Scheda
            </Button>
          )
          }
        </GridItem >
      </Grid >
    </form>
  );
}

export default ApplicantDataComponent;
