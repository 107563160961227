import React from "react";
import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import { Code } from '@chakra-ui/react'

const innerExpand = (obj) => {
  if (Object.keys(obj).includes("props")) {
    if (obj.props.length > 0) {
      return <details key={obj.code}>
        <summary >{obj.label}</summary>
        <ul style={{ marginLeft: '8px' }}>
          {obj.props && obj.props.map(obj => {
            return innerExpand(obj)
          })}
        </ul>
      </details >
    } else {
      return <li key={obj.code} onClick={() => { console.log(obj) }} >
        {obj.label}
      </li >
    }
  } else {
    return (<li key={obj.code} onClick={() => { console.log(obj) }} >
      {obj.label}  <Code>{obj.type}</Code>
    </li>)
  }
};


const renderEntities = (data) => {
  return (data.map(d => {
    return <details key={d.code}>
      <summary><b>{d.label}</b></summary>
      <ul style={{ marginLeft: '8px' }}>
        {d.subCategory && d.subCategory.map(obj => {
          return innerExpand(obj)
        })}
      </ul>
    </details >
  }))
}

const FormsManagementComponent = ({
  data
}) => {
  return (
    <Flex w="100%">
      <VStack spacing={6}>
        <Box w="full" align="left" mt={4}>
          <Text fontWeight="bold" fontSize="2xl">
            Gestione Entità
          </Text>
        </Box>
        <Box w="full" align="left">
          {data && renderEntities(data)}
        </Box>
      </VStack>
    </Flex>
  );
};

FormsManagementComponent.propTypes = {};

FormsManagementComponent.defaultProps = {};

export default FormsManagementComponent;
