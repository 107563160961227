import { Formik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { useToast } from "@chakra-ui/toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AnimalDataComponent from "./AnimalData.component";
import { addDefaultOption, prepareOptions } from "../../../helpers";
import {
  createNewVersion,
  createRecord,
  getAllAnimals,
  getRecordByCode,
  updateRecord,
} from "../../../api";
import { errors } from "../../Form";
import Loader from "../../Loader";
import { AuthContext } from "../../../context/AuthContextProvider";
import * as constants from "../../../constants";
import { useTheme } from "@chakra-ui/react";

const { POSITIVE } = errors;

function AnimalDataContainer({ idType, patientData }) {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  /* initial state */
  const [record, setRecord] = useState({});

  /* form statuses */
  const [species, setSpecies] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isCreation, setIsCreation] = useState(true);
  const [error, setError] = useState();

  /* button statuses */
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isNewVersion, setIsNewVersion] = useState(false);
  const theme = useTheme();

  const authContext = useContext(AuthContext);
  const { code, version } = useParams();

  // valori iniziali del Form
  const initialValues = {
    idSpecies:
      patientData.idSpecies === undefined ? "0" : patientData.idSpecies,
    animalDescription:
      patientData.animalDescription === undefined
        ? ""
        : patientData.animalDescription,
    age: patientData.age === undefined ? "" : patientData.age,
    ageDesc: patientData.ageDesc === undefined ? "" : patientData.ageDesc,
    sex: patientData.sex === undefined ? "" : patientData.sex,
    eventDescription:
      patientData.eventDescription === undefined
        ? ""
        : patientData.eventDescription,
    // numberMedicalRecords: 1,
  };

  // schema di validazione
  const validationSchema = Yup.object().shape({
    idSpecies: Yup.string().test("section", "Seleziona una specie", (val) => {
      return val !== "0";
    }),
    animalDescription: Yup.string(),
    age: Yup.number().typeError("Deve essere un numero").positive(POSITIVE),
    sex: Yup.string(),
    eventDescription: Yup.string(),
    ageDesc: Yup.string(),
    /* numberMedicalRecords: Yup.number()
      .typeError('Deve essere un numero')
      .positive(POSITIVE)
      .min(1, 'Numero maggiore o uguale a 1')
      , */
  });

  useEffect(() => {
    const fetchData = async () => {
      const [respRecord, respAnimals] = await Promise.all([
        getRecordByCode(code),
        getAllAnimals(),
      ]);
      setRecord(respRecord.data || {});
      setSpecies(respAnimals.data);
      setIsCreation(respRecord.data.code === undefined);
      setIsLoaded(true);
    };
    fetchData();
  }, []);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          const author = `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`;
          if (isCreating) {
            const resp = await createRecord(record, code, version, author, {
              patientData: { ...idType, ...values },
            });
            setError(resp.data.error);
          } else if (isUpdating) {
            const resp = await updateRecord(
              record,
              code,
              version,
              author,
              { ...idType, ...values },
              constants.PATIENT_DATA
            );
            setError(resp.data.error);
          } else if (isNewVersion) {
            const { newVersionId } = await createNewVersion(
              record,
              code,
              version,
              author
            );
            const resp = await updateRecord(
              record,
              code,
              newVersionId,
              author,
              { ...idType, ...values },
              constants.PATIENT_DATA
            );
            setError(resp.data.error);
            navigate(
              `/${location.pathname.split("/")[1]}/${code}/${newVersionId}`
            );
          }

          toast({
            title: "Record Salvato",
            description: "L'operazione è andata a buon fine",
            status: "success",
            duration: 9000,
            isClosable: true,
          });

          actions.setSubmitting(false);
          actions.setStatus({
            created: true,
          });

          setIsNewVersion(false);
          setIsCreating(false);
          setIsUpdating(false);
        } catch (er) {
          setError({
            ...er,
          });
          toast({
            title: "Errore",
            description: er,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          actions.setSubmitting(false);
        }
        setIsCreation(false);
      }}
    >
      {({ handleSubmit, handleChange, values, isSubmitting, isValid }) => {
        return (
          <AnimalDataComponent
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            values={values}
            isSubmitting={isSubmitting}
            isValid={isValid}
            species={prepareOptions(addDefaultOption(species))}
            error={error}
            isCreation={isCreation}
            setIsNewVersion={setIsNewVersion}
            setIsCreating={setIsCreating}
            setIsUpdating={setIsUpdating}
            userProfile={authContext.userProfile}
            theme={theme}
          />
        );
      }}
    </Formik>
  );
}

export default AnimalDataContainer;
