import { useContext } from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from "react-router-dom";
import AppComponent from "./App.component";
import Loader from "../Loader";
import { AuthContext } from "../../context/AuthContextProvider";
import { setToken } from "../../api/index";
import { mode } from "@chakra-ui/theme-tools";

import './App.css';
const config = {
  useSystemColorMode: false,
  initialColorMode: "light"
};
const colors = {
  backgroundColor: 'gray.800',
  primary: {
    main: "#356d80",
    darker: "#2A5766"
  },
  secondary: {
    main: "#882A3C"
  },
  accent: {
    main: "#CCB44F",
    darker: "#B29D3A"
  }
};

const styles = {
  global: props => ({
    body: {
      bg: mode('gray.200', 'gray.800')(props),
    }
  })
}

const theme = extendTheme({
  colors, config, styles, fonts: {
    heading: 'Open Sans, sans-serif',
    body: 'Raleway, sans-serif',
  },
});

const queryClient = new QueryClient()

function AppContainer() {
  const authContext = useContext(AuthContext);

  // Show the loading spinner while the user is not authenticated
  if (!authContext.isAuthenticated || authContext.token === undefined || authContext.token === "") {
    return (
      <ChakraProvider theme={theme}>
        <Loader />
      </ChakraProvider>
    );
  }

  setToken(authContext.token);
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AppComponent logout={authContext.logout} profile={authContext.userProfile} />{" "}
        </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
  );


}

AppContainer.propTypes = {};

AppContainer.defaultProps = {};

export default AppContainer;
