import { Formik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";
import DrugsComponent from "./Drugs.component";
import {
  getAllAgentSubcategory,
  getAllAssumingMode,
  getAllAssumingType,
} from "../../../../api";
import { errors } from "../../../Form";
import {
  addDefaultOption,
  filterAssumingModeList,
  prepareOptions,
} from "../../../../helpers";
import { AuthContext } from "../../../../context/AuthContextProvider";
import * as constants from "../../../../constants";
import { DEFAULT_ID } from "../../../../constants";

const { OB } = errors;

function DrugsContainer({
  commonData,
  isSubmittingCommonForm,
  isValidCommonForm,
  dirtyCommonForm,
  data,
  isCreation,
  isAdding,
  record,
  setIsCreation,
  setIsSelected,
  setIsAdding,
  createRecord,
  updateArrayElementInRecord,
  addArrayElementToRecord
}) {
  const toast = useToast();

  const [assumingModeList, setAssumingModeList] = useState([]);
  const [assumingTypes, setAssumingTypes] = useState([]);
  const [agentSubcategories, setAgentSubcategories] = useState([]);

  /* form statuses */
  const authContext = useContext(AuthContext);
  const [error, setError] = useState();
  const { code, version } = useParams();

  /* button statuses */
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // valori iniziali del Form
  const initialValues = {
    commercialName:
      data.commercialName === undefined ? "" : data.commercialName,
    coding: data.coding === undefined ? "" : data.coding,
    maker: data.maker === undefined ? "" : data.maker,
    composition: data.composition === undefined ? "" : data.composition,
    useCategory: data.useCategory === undefined ? "" : data.useCategory,
    isTherapy: data.isTherapy === undefined ? false : data.isTherapy,
    assumingType:
      data.assumingType === undefined ? DEFAULT_ID : data.assumingType,
    quantity: data.quantity === undefined ? "" : data.quantity,
    assumingMode: data.assumingMode === undefined ? [] : data.assumingMode,
  };

  // schema di validazione
  const validationSchema = Yup.object().shape({

  });

  useEffect(() => {
    const fetchData = async () => {
      const [respAssumingMode, respAssumingType, respAgentSubcategory] =
        await Promise.all([
          getAllAssumingMode(),
          getAllAssumingType(),
        ]);
      setAssumingModeList(respAssumingMode.data);
      setAssumingTypes(respAssumingType.data);
    };
    fetchData();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        const formValues = { ...commonData, ...values };
        try {
          const author = `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`;
          if (isCreating) {
            const resp = await createRecord.mutate({
              record, code, version, author, formValues: {
                agentData: [formValues]
              }
            });
            toast({
              title: "Record Salvato",
              description: "L'operazione è andata a buon fine",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          } else if (isAdding) {
            const resp = await addArrayElementToRecord.mutate({
              record,
              code,
              version,
              author,
              formValues,
              agentType: constants.AGENTS
            }
            );
            toast({
              title: "L'agente è stato aggiunto con successo",
              description: "L'operazione è andata a buon fine",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          } else if (isUpdating) {
            const resp = await updateArrayElementInRecord.mutate({
              record,
              code,
              version,
              author,
              formValues,
              agentType: constants.AGENTS
            }
            );
            toast({
              title: "L'agente è stato modificato con successo",
              description: "L'operazione è andata a buon fine",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          }

          actions.setSubmitting(false);
          actions.setStatus({
            created: true,
          });

          setIsCreating(false);
          setIsUpdating(false);
          setIsAdding(false);
        } catch (er) {
          setError({
            ...er,
          });
          toast({
            title: "Errore",
            description: er,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          actions.setSubmitting(false);
        }
        setIsCreation(false);
        setIsSelected(false);
        setIsAdding(false);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        isSubmitting,
        isValid,
        dirty,
      }) => {
        return (
          <DrugsComponent
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={values}
            isSubmitting={isSubmitting}
            isValid={isValid}
            dirty={dirty}
            isSubmittingCommonForm={isSubmittingCommonForm}
            isValidCommonForm={isValidCommonForm}
            dirtyCommonForm={dirtyCommonForm}
            error={error}
            isCreation={isCreation}
            isAdding={isAdding}
            setIsCreating={setIsCreating}
            setIsUpdating={setIsUpdating}
            agentSubcategories={prepareOptions(
              addDefaultOption(agentSubcategories)
            )}
            assumingTypes={prepareOptions(addDefaultOption(assumingTypes))}
            assumingModeListFiltered={filterAssumingModeList(
              assumingModeList,
              commonData.category
            )}
          />
        );
      }}
    </Formik >
  );
}

export default DrugsContainer;
