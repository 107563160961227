import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { IoExit, IoSearchCircle } from "react-icons/io5";
import Logo from "../../assets/logo.png";
import * as routes from "../../navigation/routes";
import { getCode, isAdmin } from "../../helpers";

function NavbarComponent({ colorMode, toggleColorMode, logout, userProfile }) {
  const theme = useTheme();
  return (
    <Flex
      as="header"
      boxShadow="base"
      position="fixed"
      bg={theme.colors.primary.main}
      bgGradient={`linear(to-tl,${theme.colors.primary.darker}, ${theme.colors.primary.main})`}
      px={4}
      w="100%"
      style={{ zIndex: "1" }}
    >
      <Flex
        as="nav"
        h={16}
        wrap="wrap"
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
      >
        <Box>
          <Stack direction="row" spacing={4} alignItems="center">
            <img src={Logo} alt="Maugeri Logo" width="48px" height="48px" />
            <Link to={routes.HOME}>
              <Button
                size="sm"
                color="white"
                variant="ghost"
                _hover={{ color: theme.colors.accent.main }}
              >
                Cartelle Cliniche
              </Button>
            </Link>
            <Link to={`dettaglio/${getCode()}/1`}>
              <Button
                size="sm"
                color="white"
                variant="ghost"
                _hover={{ color: theme.colors.accent.main }}
              >
                Nuova Cartella
              </Button>
            </Link>
          </Stack>
        </Box>
        <Flex alignItems="center">
          <Stack direction="row" spacing={7} px="4">
            <Button onClick={toggleColorMode} variant="link">
              {colorMode === "light" ? (
                <MoonIcon
                  color="white"
                  _hover={{ color: theme.colors.accent.main }}
                />
              ) : (
                <SunIcon _hover={{ color: theme.colors.accent.main }} />
              )}
            </Button>
            <Menu>
              <MenuButton
                as={Button}
                rounded="full"
                variant="link"
                cursor="pointer"
                minW={0}
              >
                <Avatar
                  boxShadow="base"
                  bg="white"
                  color={theme.colors.primary.main}
                  name={`${userProfile.firstName} ${userProfile.lastName}`}
                  size="sm"
                />
              </MenuButton>
              <MenuList>
                <MenuItem px={1}>
                  <Container >
                    <Grid templateColumns="repeat(2, 0.5fr)">
                      <GridItem>
                        <Avatar
                          bg="white"
                          name={`${userProfile.firstName} ${userProfile.lastName}`}
                          size="sm"
                          color={theme.colors.primary.main}
                        />
                      </GridItem>
                      <GridItem>
                        <Text fontSize="md">
                          {`${userProfile.firstName} ${userProfile.lastName}`}{" "}
                        </Text>
                        <Text fontSize="sm">{`${userProfile.email}`} </Text>
                      </GridItem>
                    </Grid>
                  </Container>
                </MenuItem>
                <MenuDivider />
                {isAdmin(userProfile) && (
                  <>
                    <Link to={routes.ADMIN_PRESETS}>
                      <MenuItem  icon={<IoSearchCircle />}>Gestione Filtri</MenuItem>
                    </Link>
                    {/* <Link to={routes.ADMIN_FORMS}>
                      <MenuItem>Gestione Entità</MenuItem>
                    </Link> */}
                  </>
                )}
                <MenuDivider />
                <MenuItem icon={<IoExit />} onClick={logout}>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default NavbarComponent;
