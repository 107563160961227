import {
  Button,
  ButtonGroup,
  useDisclosure,
  Box,
  useTheme,
  HStack,
  Text,
  useColorMode
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FiEdit, FiTrash } from "react-icons/fi";
import { RiFileAddLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import * as api from "../../api";
import { AlertDialog } from "../Dialog";
import AgentsComponent from "./Agents.component";
import { getArrayFromRecord } from "../../helpers";
import AgentsForm from "./AgentsForm";
import Loader from "../Loader";
import * as constants from "../../constants";
import { AuthContext } from "../../context/AuthContextProvider";
import dayjs from "dayjs";
import { useQuery, useMutation, useQueryClient } from "react-query";

function AgentsContainer() {
  /* initial state */
  const [record, setRecord] = useState({});
  const [dataArray, setDataArray] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const authContext = useContext(AuthContext);
  const queryClient = useQueryClient({});
  const isLight = useColorMode().colorMode === "light";
  const theme = useTheme();

  /* form statuses */
  const [isCreation, setIsCreation] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { code, version } = useParams();

  /* table statuses */
  const { onOpen, isOpen, onClose } = useDisclosure();

  const author = `${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`;

  const { data: respRecord } = useQuery(
    ["respAgentRecord", code],
    () => {
      return api.getRecordByCode(code)
    },
    {
      onSuccess: ({ data: respRecord }) => {
        setRecord(respRecord || {});
        const extractedData = getArrayFromRecord(
          respRecord,
          version,
          constants.AGENTS
        );
        setDataArray(extractedData);
        setIsCreation(respRecord.code === undefined)
        setIsLoaded(true)
      },
    }
  );

  const createRecord = useMutation(
    obj => {
      return api.createRecord(obj.record, obj.code, obj.version, obj.author, obj.formValues);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("respAgentRecord");
      },
    }
  );

  const addArrayElementToRecord = useMutation(
    obj => {
      return api.addArrayElementToRecord(obj.record, obj.code, obj.version, obj.author, obj.formValues, obj.agentType);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("respAgentRecord");
      },
    }
  );


  const updateArrayElementInRecord = useMutation(
    obj => {
      return api.updateArrayElementInRecord(obj.record, obj.code, obj.version, obj.author, obj.formValues, obj.agentType);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("respAgentRecord");
      },
    }
  );


  // useEffect(() => {
  //   const fetchData = async () => {
  //     const [respRecord] = await Promise.all([getRecordByCode(code)]);
  //     setRecord(respRecord.data || {});
  //     const extractedData = getArrayFromRecord(
  //       respRecord.data,
  //       version,
  //       constants.AGENTS
  //     );
  //     setDataArray(extractedData);
  //     setIsCreation(respRecord.data.code === undefined);
  //     setIsLoaded(true);
  //   };
  //   fetchData();
  // }, []);

  const columns = [
    {
      Header: "Data Creazione",
      accessor: "creationDate",
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ cell }) => {
        return (
          <span>
            {dayjs(cell.value).locale("it").format("DD/MM/YYYY HH:mm")}
          </span>
        );
      },
    },
    {
      Header: "Categoria",
      accessor: "category",
    },
    {
      Header: "Luogo di esposizione",
      accessor: "exposurePlace",
    },
    {
      Header: "Modalità di esposizione",
      accessor: "exposureMode",
    },
    {
      Header: "Tempo intercorso (Ore)",
      accessor: "timePassed",
    },
    {
      id: "actions",
      Header: "Azioni",
      accessor: "actions",
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ cell }) => {
        return (
          <ButtonGroup isAttached>
            <Button
              size="sm"
              onClick={() => {
                setSelectedData(cell.row.original);
                setIsSelected(true);
              }}
            >
              <FiEdit />
            </Button>
            <Button
              key={cell.row.original.id}
              size="sm"
              onClick={() => {
                setSelectedData(cell.row.original);
                onOpen();
              }}
            >
              <FiTrash />
            </Button>
          </ButtonGroup>
        );
      },
    },
  ];

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Box margin="2" justify="center" >
      {isCreation || isSelected || isAdding ? (
        <AgentsForm
          isCreation={isCreation}
          isAdding={isAdding}
          setIsCreation={setIsCreation}
          setIsSelected={setIsSelected}
          setIsAdding={setIsAdding}
          theme={theme}
          data={selectedData}
          record={record}
          createRecord={createRecord}
          addArrayElementToRecord={addArrayElementToRecord}
          updateArrayElementInRecord={updateArrayElementInRecord}
        />
      ) : (
        <>
          <Box boxShadow="base" borderRadius="8px" pt="8" pb="8" px="8"
            w="full" bg={isLight ? "white" : "gray.900"}>

            <HStack w="100" spacer={4}>
              <Box>
                <Text fontSize='2xl' as="b" >Lista Agenti</Text>
              </Box>
              <Box p="4" >
                <Button
                  leftIcon={<RiFileAddLine />}
                  onClick={() => {
                    setSelectedData({});
                    setIsCreation(false);
                    setIsAdding(true);
                  }}
                >
                  Nuovo agente
                </Button>
              </Box>
            </HStack>
            <AgentsComponent columns={columns} data={dataArray} />
            <AlertDialog
              title="Attenzione"
              isOpen={isOpen}
              onClose={onClose}
              body={`Sei sicuro di voler eliminare l'agente selezionato?`}
              onConfirm={() => {
                api.deleteArrayElementFromRecord(
                  record,
                  code,
                  version,
                  author,
                  selectedData,
                  constants.AGENTS
                );
                setDataArray(
                  dataArray.filter((item) => {
                    return item.ArrayElementID !== selectedData.ArrayElementID;
                  })
                );
                onClose();
              }}
              confirmButtonText="Elimina"
              confirmButtonColor="red"
            />

          </Box>

        </>
      )
      }
    </Box >
  );
}

export default AgentsContainer;
