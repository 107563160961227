import {
  FormLabel,
  Grid,
  GridItem,
  Flex,
  Box,
  Heading,
  Spacer,
  Button,
  Center,
  useTheme
} from "@chakra-ui/react";
import { InputControl, TextareaControl } from "formik-chakra-ui";
import { TiArrowBack } from "react-icons/ti";
import { FaSave } from "react-icons/fa";

import DatePickerAgent from "../../Form/DatePickerAgent";
import DiagnosisSearch from "../DiagnosisSearch";

function DiagnosisFormComponent({
  handleSubmit,
  isSubmitting,
  isValid,
  dirty,
  setFieldValue,
  setIsCreation,
  setIsSelected,
  setIsAdding,
  modalIsOpen,
  modalOnOpen,
  modalOnClose,
  isCreation,
  isAdding,
  setIsUpdating,
  setIsCreating,
}) {
  const theme = useTheme();

  return (
    <>
      <Flex>
        <Box pt={2}>
          <Heading fontSize='2xl' as="b" >
            Scheda Diagnosi
          </Heading>
        </Box>
        <Spacer />
        <Box pt={2} pr={4}>
          <Button
            rightIcon={<TiArrowBack />}
            onClick={() => {
              setIsCreation(false);
              setIsSelected(false);
              setIsAdding(false);
            }}
          >
            Torna alla lista
          </Button>
        </Box>
      </Flex>
      <form onSubmit={handleSubmit}>
        {/* Categoria */}
        <Grid templateColumns="repeat(10, 1fr)" columnGap={6} rowGap={6} mt={6}>
          {/* Data creazione */}
          <GridItem colSpan={7}>
            <FormLabel htmlFor="category">Data Creazione</FormLabel>
            <DatePickerAgent name="creationDate" />
          </GridItem>

          <GridItem colSpan={3} rowSpan={4}>
            <Center h="100%">
              <Button onClick={modalOnOpen}>Cerca Diagnosi</Button>
            </Center>
          </GridItem>

          {/* Utente che inserisce i dati */}
          <GridItem colSpan={7}>
            <InputControl
              name="user"
              label="Operatore"
              inputProps={{ isDisabled: true }}
            />
          </GridItem>

          {/* Codice Diagnosi */}
          <GridItem colSpan={7}>
            <InputControl name="diagnosisInfo.code" label="Codice Diagnosi" />
          </GridItem>

          {/* Descrizione diagnosi */}
          <GridItem colSpan={7}>
            <InputControl
              name="diagnosisInfo.description"
              label="Descrizione Diagnosi"
            />
          </GridItem>

          {/* Note */}
          <GridItem colSpan={10}>
            <TextareaControl name="notes" label="Note diagnosi" />
          </GridItem>
        </Grid>
        {/* Bottoni */}
        <Grid w="100%" templateColumns="repeat(6, 1fr)" mt={12} >
          <GridItem colStart={6}>
            {isCreation ? (
              <Button
                type="submit"
                leftIcon={<FaSave />}
                background={theme.colors.primary.main}
                color={'white'}
                disabled={isSubmitting || !isValid}
                onClick={() => {
                  return setIsCreating(true);
                }}
              >
                Salva Scheda
              </Button>
            ) : (
              <Button
                type="submit"
                leftIcon={<FaSave />}
                background={theme.colors.primary.main}
                color={'white'}
                disabled={isSubmitting || !isValid}
                onClick={() => {
                  return setIsUpdating(true);
                }}
              >
                Modifica Scheda
              </Button>
            )
            }
          </GridItem >
        </Grid >
      </form>

      <DiagnosisSearch
        modalIsOpen={modalIsOpen}
        modalOnOpen={modalOnOpen}
        modalOnClose={modalOnClose}
        setFieldValue={setFieldValue}
      />
    </>
  );
}

export default DiagnosisFormComponent;
