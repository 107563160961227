import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Center,
  Circle,
  Flex,
  SlideFade,
  Stack,
  useColorModeValue,
  useDisclosure,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { IoCallSharp, IoFolder, IoPersonSharp } from "react-icons/io5";
import { RiMedicineBottleFill } from "react-icons/ri";
import { IoMdPricetag } from "react-icons/io";
import { GiPoisonBottle } from "react-icons/gi";
import { GoAlert } from "react-icons/go";
import { FaBookMedical, FaFileMedical } from "react-icons/fa";
import { ImNewTab, ImPencil2, ImDatabase } from "react-icons/im";
import { VscFiles } from "react-icons/vsc";
import { Icon } from "@chakra-ui/icons";
import { Link, useLocation } from "react-router-dom";
import { forwardRef } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import * as PropTypes from "prop-types";
import * as routes from "../../navigation/routes";

const LinkItems = [
  {
    name: "Dettaglio Cartella",
    icon: IoFolder,
    route: routes.RECORD_DETAILS_SIMPLE,
  },
  {
    name: "Tag",
    icon: IoMdPricetag,
    route: routes.TAG_SIMPLE,
  },
  {
    name: "Dati Paziente",
    icon: IoPersonSharp,
    route: routes.PATIENT_DATA_SIMPLE,
  },
  {
    name: "Dati Richiedente",
    icon: IoCallSharp,
    route: routes.APPLICANT_DATA_SIMPLE,
  },
  { name: "Agenti", icon: GoAlert, route: routes.AGENTS_DATA_SIMPLE },
  {
    name: "Valutazione Tossicità",
    icon: GiPoisonBottle,
    route: routes.EVALUATE_TOXICITY_SIMPLE,
  },
  { name: "Consegne", icon: ImPencil2, route: routes.ERRANDS_SIMPLE },
  { name: "Allegati", icon: VscFiles, route: routes.ATTACHMENTS_SIMPLE },
  { name: "Sintomi", icon: FaFileMedical, route: routes.SYMPTOMS_SIMPLE },
  { name: "Esami", icon: ImDatabase, route: routes.EXAMS_SIMPLE },
  {
    name: "Trattamenti",
    icon: RiMedicineBottleFill,
    route: routes.TREATMENTS_SIMPLE,
  },
  { name: "Diagnosi", icon: FaBookMedical, route: routes.DIAGNOSIS_SIMPLE },
];

function NavItem({ location, icon, children, theme, route }) {
  return (
    <LinkButton
      size="lg"
      _hover={{
        bgGradient: `linear(to-r, ${theme.colors.secondary.main}, red.900)`,
        color: "white",
      }}
      variant="ghost"
      style={
        location.split("/")[1] === route.split("/")[1]
          ? { backgroundColor: theme.colors.secondary.main, color: "white" }
          : null
      }
      leftIcon={<Icon mr="4" fontSize="16" as={icon} />}
      to={`${route}/${location.split("/").slice(2).join("/")}`}
    >
      {children}
    </LinkButton>
  );
}

// eslint-disable-next-line react/display-name
const LinkButton = forwardRef((props, ref) => {
  return <Button ref={ref} as={Link} {...props} />;
});

AlertDescription.propTypes = {
  maxWidth: PropTypes.string,
  children: PropTypes.node,
};

function SidebarComponent() {
  const theme = useTheme();
  const location = useLocation().pathname;

  const code = location.split("/")[2];
  const version = location.split("/")[3];

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  return (
    <Box>
      <Flex>
        <SlideFade in={isOpen} offsetX="-2px">
          <Stack
            style={{ display: isOpen ? "block" : "none" }}
            h="full"
            boxShadow="base"
            pd={8}
            bg={useColorModeValue("gray.100", "blackAlpha.700")}
            borderRight="1px"
            borderRightColor={useColorModeValue("gray.200", "gray.700")}
          >
            <Stack position="sticky" top={0}>
              <Alert
                status="info"
                alignItems="left"
                variant="subtle"
                flexDirection="column"
                justifyContent="left"
                textAlign="left"
              >
                <Button
                  size="sm"
                  colorScheme="yellow"
                  rightIcon={<ImNewTab />}
                  onClick={() => {
                    window.open(
                      `${routes.RECORD_INFO_SIMPLE}/${code}/${version}`,
                      "_blank",
                      "height=1024,width=1024"
                    );
                  }}
                >
                  Riassunto Scheda
                </Button>
              </Alert>
              <VStack align="left" px={4}>
                {LinkItems.map((link) => {
                  return (
                    <Box key={link.name}>
                      <NavItem
                        key={link.name}
                        location={location}
                        icon={link.icon}
                        theme={theme}
                        route={link.route}
                        isOpen={isOpen}
                      >
                        {link.name}
                      </NavItem>
                    </Box>
                  );
                })}
              </VStack>
            </Stack>
          </Stack>
        </SlideFade>
        <Center
          h="100vh"
          style={{
            marginLeft: isOpen ? "-1.5rem" : "-0.8rem",
            cursor: "pointer",
          }}
        >
          <Circle
            _hover={{
              bg: theme.colors.primary.darker,
              animationName: "slideInUp",
            }}
            bg={theme.colors.primary.main}
            color="white"
            boxShadow="base"
            size="40px"
            onClick={onToggle}
          >
            {isOpen ? <FiChevronLeft /> : <FiChevronRight />}
          </Circle>
        </Center>
      </Flex>
    </Box>
  );
}

export default SidebarComponent;
