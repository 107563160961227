import RecordInfoComponent from "./RecordInfo.component";

function RecordInfoContainer() {

  return (
    <RecordInfoComponent />
  );
}

RecordInfoContainer.propTypes = {};

RecordInfoContainer.defaultProps = {};

export default RecordInfoContainer;
